const PaginationFooter = ({
  sortedList,
  pageNumbers,
  currentPage,
  totalPages,
  setCurrentPage,
  indexOfFirstEntry,
  indexOfLastEntry
}) => {
  return (
    <div className="flex">
      <div className="flex flex-1 items-center justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">
              {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedList.length)}
            </span>{' '}
            records
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
              disabled={currentPage === 1}
            >
              <span className="">{'<'}</span>
            </button>
            <div>
              {pageNumbers?.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => setCurrentPage(pageNumber)}
                  className={`relative items-center w-10 h-10 text-base text-center ${
                    pageNumber === currentPage
                      ? 'bg-purple1 text-white rounded-xl font-bold'
                      : 'text-black bg-oppty-green-5'
                  }`}
                >
                  {pageNumber}
                </button>
              ))}
            </div>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
              disabled={currentPage === totalPages}
            >
              <span className="">{'>'}</span>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationFooter;
