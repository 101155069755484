import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SidebarLinkGroup from '../SidebarLinkGroup';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as Employees } from '../../assets/icons/employees.svg';
import ArrowIcon from '../../assets/icons/arrowIcon';
import { DocumentPrevious, DollarCircle, Health, LogoutCurve, Timer1 } from 'iconsax-react';
import DefaultProfile from '../../assets/images/defaultCompany.png';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../actions/userAction';

const ManagerSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = location;

  const company = useSelector((state) => state?.company?.company);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  const handleLogout = () => {
    dispatch(userLogout(navigate));
  };

  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('sidebar', !sidebarOpen);
  };

  return (
    <aside
      className={`left-0 top-0 z-[9999] flex h-full ${
        sidebarOpen ? 'w-[310px]' : 'w-[75px]'
      } flex-col overflow-y-hidden bg-black2`}
    >
      <div className="flex items-center px-4 pt-10 gap-x-2 relative flex-shrink-0">
        <div onClick={handleToggle} className="cursor-pointer flex-shrink-0">
          <img
            src={company?.companyLogo || DefaultProfile}
            alt="company logo"
            className="w-10 h-10 rounded-full flex-shrink-0 object-cover"
          />
        </div>
        {sidebarOpen && <div className="text-base text-white font-bold text-left max-w-48">{company?.companyName}</div>}
        {sidebarOpen && (
          <div className="absolute right-5">
            <button onClick={handleToggle} aria-controls="sidebar" aria-expanded={sidebarOpen} className="block">
              <ArrowLeft />
            </button>
          </div>
        )}
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear justify-between h-full">
        <nav className={`py-4 mt-5 ${sidebarOpen ? 'px-2' : 'px-2'}`}>
          <div>
            <ul className="mb-6 flex flex-col gap-3">
              <SidebarLinkGroup
                activeCondition={pathname === '/manager/dashboard' || pathname.includes('/manager/dashboard/')}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5 h-10 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/dashboard' || pathname.includes('/manager/dashboard/')) &&
                          'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/dashboard');
                        }}
                      >
                        <Dashboard />
                        {sidebarOpen && <span>Dashboard</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/dashboard"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Dashboard</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={pathname === '/manager/employees' || pathname.startsWith('/manager/employees/')}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5 h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/employees' || pathname.startsWith('/manager/employees/')) &&
                          'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/employees/leave-applications');
                        }}
                      >
                        <Employees />
                        {sidebarOpen && <ArrowIcon open={open} />}
                        {sidebarOpen && <span>Employees</span>}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/employees/leave-applications"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Leave Applications</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/manager/employees/medical-applications"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Medical Applications</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/manager/employees/claim-applications"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Approve Claims</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={pathname === '/manager/apply-leave' || pathname.startsWith('/manager/apply-leave/')}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5  h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/apply-leave' || pathname.startsWith('/manager/apply-leave/')) &&
                          'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/apply-leave');
                        }}
                      >
                        <DocumentPrevious
                          color="rgba(255, 255, 255, 0.9)"
                          variant="Outline"
                          size={22}
                          className="-ml-0.5"
                        />
                        {sidebarOpen && <span>Apply Leave</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/apply-leave"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Apply Leave</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/manager/apply-medical-leave' || pathname.startsWith('/manager/apply-medical-leave/')
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5  h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/apply-medical-leave' ||
                            pathname.startsWith('/manager/apply-medical-leave/')) &&
                          'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/apply-medical-leave');
                        }}
                      >
                        <Health color="rgba(255, 255, 255, 0.9)" variant="Outline" size={22} className="-ml-0.5" />
                        {sidebarOpen && <span>Apply Medical Leave</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/apply-medical-leave"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Apply Medical Leave</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={pathname === '/manager/submit-claim' || pathname.startsWith('/manager/submit-claim/')}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5  h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/submit-claim' || pathname.startsWith('/manager/submit-claim/')) &&
                          'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/submit-claim');
                        }}
                      >
                        <DollarCircle
                          color="rgba(255, 255, 255, 0.9)"
                          variant="Outline"
                          size={22}
                          className="-ml-0.5"
                        />
                        {sidebarOpen && <span>Submit Claim</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/submit-claim"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Submit Claim</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={pathname === '/manager/timesheets' || pathname.startsWith('/manager/timesheets/')}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5  h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/timesheets' || pathname.startsWith('/manager/timesheets/')) &&
                          'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/timesheets');
                        }}
                      >
                        <Timer1 color="rgba(255, 255, 255, 0.9)" variant="Outline" size={22} className="-ml-0.5" />
                        {sidebarOpen && <span>Timesheets</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/timesheets"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Timesheets</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={pathname === '/manager/payslip' || pathname.startsWith('/manager/payslip/')}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5  h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/manager/payslip' || pathname.startsWith('/manager/payslip/')) && 'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/manager/payslip');
                        }}
                      >
                        <DollarCircle
                          color="rgba(255, 255, 255, 0.9)"
                          variant="Outline"
                          size={22}
                          className="-ml-0.5"
                        />
                        {sidebarOpen && <span>Payslip</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/manager/payslip"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Payslip</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
            </ul>
          </div>
        </nav>
        <div>
          <NavLink
            to="#"
            className={`group relative flex items-center gap-2.5 h-10 rounded-sm pb-12 px-7 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark bg-graydark`}
            onClick={() => handleLogout()}
          >
            <LogoutCurve color="rgba(255, 255, 255, 0.9)" variant="Outline" size={22} className="-ml-1.5" />
            {sidebarOpen && <span>Log out</span>}
          </NavLink>
        </div>
      </div>
    </aside>
  );
};

export default ManagerSidebar;
