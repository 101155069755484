import { useEffect, useState, useMemo } from 'react';
import { searchHourlyEmployees, getEmployee, fetchEmployeeSuccess } from '../../../actions/employeeAction';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { searchHourlyEmployeesByDepartment } from '../../../actions/managerAction';
import { useDispatch, useSelector } from 'react-redux';
import ManagerLayout from '../../../layout/managerLayout';
import Dropdown from '../../../components/dropdown';
import SearchDropdown from '../../../components/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { Edit2, Eye, Trash } from 'iconsax-react';
import empty from '../../../assets/images/empty.png';
import AddTimesheets from './addTimesheet';
import EditTimesheet from './editTimesheet';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';
import TimesheetViewModal from '../../../components/timesheetViewModal';
import { getStatusLabel } from '../../../constant/functions';
import { deleteTimesheet } from '../../../actions/payrollAction';
import { format } from 'date-fns';

const ManagerTimesheets = () => {
  const dispatch = useDispatch();

  const [searchName, setSearchName] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingTimesheet, setEditingTimesheet] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState('');

  const user = useSelector((state) => state?.employee?.user);
  const employees = useSelector((state) => state?.employee?.searchEmployees) || [];
  const company = useSelector((state) => state.company.company);
  const singleEmployee = useSelector((state) => state?.employee?.current);
  const companies = useSelector((state) => state.company?.companies || []);

  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'ascending'
  });

  console.log(singleEmployee);

  useEffect(() => {
    dispatch(fetchEmployeeSuccess([]));
  }, []);

  useEffect(() => {
    setSelectedEmployee({ label: user?.fullName, value: user?._id });
    dispatch(getEmployee(user?._id));
  }, [user]);

  useEffect(() => {
    const inputValue = {
      companyId: company?._id,
      employeeId: user?._id,
      departmentCode: user?.employmentDetails?.departmentCode
    };
    if (company && user) dispatch(searchHourlyEmployeesByDepartment(inputValue));
  }, [company, user, dispatch]);

  // useEffect(() => {
  //   if (selectedBranch !== null) {
  //     dispatch(searchHourlyEmployees(selectedBranch));
  //   }
  // }, [selectedBranch, dispatch]);

  // useEffect(() => {
  //   setSelectedCompany(company?.companyName);
  //   setCompanyId(company?._id);
  // }, [company]);

  const handleInputChange = (inputValue) => {
    setSearchName(inputValue);
  };

  // const handleCompanyChange = (selectedOption) => {
  //   const selectedValue = selectedOption?.value || '';
  //   if (selectedValue) {
  //     setSelectedCompany(selectedValue);
  //     setCompanyId(selectedOption?.companyId);
  //     //dispatch(getAllEmployees({ companyId: selectedOption?.companyId }));
  //     setSelectedEmployee(null);
  //     setSearchName('');
  //     dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
  //     dispatch(fetchEmployeeSuccess([]));
  //   }
  // };

  // const handleCompanyChange = (selectedOption) => {
  //   setSelectedBranch(selectedOption ? selectedOption.value : null);
  //   setSelectedEmployee(null);
  //   setSearchName('');
  // };

  const handleDropdownChange = (selectedOption) => {
    console.log('selectedOption: ', selectedOption);
    if (selectedOption) {
      resetFormState();
      setIsFormVisible(false);
      setSelectedEmployee(selectedOption);
      dispatch(getEmployee(selectedOption.value));
    }
  };

  const resetFormState = () => {
    setEditingTimesheet(null);
    setIsFormVisible(false);
  };

  const toggleFormVisibility = () => {
    resetFormState();
    setIsFormVisible(!isFormVisible);
  };

  const handleEditClick = (timesheet) => {
    resetFormState();
    setEditingTimesheet(timesheet);
    setIsFormVisible(true);
  };

  const handleTimesheetDelete = (employeeId, timesheet, companyId) => {
    console.log('timesheet: ', timesheet);
    dispatch(
      deleteTimesheet(
        {
          employeeId: employeeId,
          timesheetId: timesheet?._id,
          companyId: companyId
        },
        setIsConfirmationModalOpen
      )
    );
  };

  const filteredEmployeeOptions = employees
    .filter((employee) => employee.fullName.toLowerCase().includes(searchName.toLowerCase()))
    .map((employee) => ({
      label: employee.fullName,
      value: employee._id
    }));

  const departmentOptions =
    company?.departments?.map((department) => ({
      label: department?.departmentName,
      value: department?.departmentCode
    })) || [];

  const sortedTimesheetHistory = useMemo(() => {
    //uncomment this later
    if (!singleEmployee || !singleEmployee.timesheetDetails || !singleEmployee.timesheetDetails.timesheetHistory) {
      return [];
    }

    let sortableTimesheets = [...singleEmployee?.timesheetDetails?.timesheetHistory];

    sortableTimesheets.sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'createdDate':
          aValue = new Date(a.createdDate).getTime();
          bValue = new Date(b.createdDate).getTime();
          break;
        case 'status':
          aValue = a.status?.toLowerCase() || '';
          bValue = b.status?.toLowerCase() || '';
          break;
        default:
          aValue = a[sortConfig.key] || '';
          bValue = b[sortConfig.key] || '';
          if (typeof aValue === 'string') {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
          }
          break;
      }
      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    });

    return sortableTimesheets;
  }, [singleEmployee, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const employeeOptionsKey = filteredEmployeeOptions.map((option) => option.value).join('-');

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedTimesheetHistory.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedTimesheetHistory.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ManagerLayout pageTitle={'Timesheets'} page={'Payroll'} subPage={'Timesheets'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          <div className="flex justify-between">
            <div className="flex w-3/4 gap-x-4">
              <Dropdown
                value={departmentOptions.find((option) => option.value === user?.employmentDetails?.departmentCode)}
                //onChange={handleBranchChange}
                options={departmentOptions}
                isClearable={true}
                isSearchable={true}
                placeholder="Select Department"
                name="branch"
                id="branch"
                isDisabled={true}
              />
              {selectedEmployee && (
                <SearchDropdown
                  key={employeeOptionsKey}
                  options={filteredEmployeeOptions}
                  value={selectedEmployee}
                  onChange={handleDropdownChange}
                  onInputChange={handleInputChange}
                  placeholder="Search Employees"
                  name="employee"
                  id="employee"
                  menuPlacement="bottom"
                  isClearable={true}
                />
              )}
            </div>
            <button
              onClick={toggleFormVisibility}
              disabled={!singleEmployee}
              className={
                isFormVisible
                  ? 'h-12 w-28 font-bold rounded-xl border-2 bg-red1 text-white hover:bg-red1/90 disabled:bg-gray-400'
                  : 'h-12 w-28 bg-purple1 text-3xl hover:bg-purple1/90 text-whiten font-bold rounded-xl disabled:bg-gray-400'
              }
            >
              {isFormVisible ? 'Cancel' : '+'}
            </button>
          </div>

          {editingTimesheet && isFormVisible && (
            <EditTimesheet
              timesheetData={editingTimesheet}
              onClose={resetFormState}
              employeeId={singleEmployee?._id}
              employee={singleEmployee}
              companyId={singleEmployee?.company?._id}
            />
          )}

          {singleEmployee && !editingTimesheet && isFormVisible && (
            <AddTimesheets
              toggleFormVisibility={toggleFormVisibility}
              employee={singleEmployee}
              companyId={singleEmployee?.company?._id}
            />
          )}

          {currentEntries.length > 0 ? (
            <div>
              <div className="flex justify-end items-center mb-5 mt-10">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th>Full Name</th>
                    <th>Designation</th>
                    <th>Department</th>
                    <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                      Created Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-e-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((timesheet, index) => {
                    const formattedCreatedDate = !Number.isNaN(new Date(timesheet?.createdDate).getTime())
                      ? format(new Date(timesheet?.createdDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(timesheet?.status);

                    return (
                      <tr className="h-20 text-sm" key={index}>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={singleEmployee?.avatar ? singleEmployee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center">{singleEmployee?.fullName}</td>
                        {/* <td className="h-20 text-center" title={singleEmployee?.fullName}>
                          {singleEmployee?.fullName?.slice(0, 12)}
                          {singleEmployee?.fullName?.length > 12 ? '...' : ''}
                        </td> */}
                        <td className="h-20 text-center">{singleEmployee?.employmentDetails?.designationName}</td>
                        <td className="h-20 text-center">{singleEmployee?.employmentDetails?.departmentName}</td>
                        <td className="h-20 text-center">{formattedCreatedDate}</td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-end gap-x-2 items-center">
                            <div
                              className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                              onClick={() => setIsViewModalOpen(true)}
                              title="View"
                            >
                              <Eye color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                            </div>
                            <div
                              className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-green-400 shadow-md"
                              onClick={() => handleEditClick(timesheet)}
                              title="Edit"
                            >
                              <Edit2 color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                            </div>
                            {timesheet?.status === 'Approved' && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-100 shadow-md"
                                onClick={() => setIsConfirmationModalOpen(true)}
                                title="Delete"
                              >
                                <Trash color="#C84040" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                          </div>
                        </td>
                        <DeleteConfirmationModal
                          isOpen={isConfirmationModalOpen}
                          onClose={() => setIsConfirmationModalOpen(false)}
                          handleDelete={() =>
                            handleTimesheetDelete(singleEmployee?._id, timesheet, singleEmployee?.company?._id)
                          }
                          title={'timesheet'}
                        />
                        <TimesheetViewModal
                          isOpen={isViewModalOpen}
                          onClose={() => setIsViewModalOpen(false)}
                          employee={singleEmployee}
                          timesheet={timesheet}
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            singleEmployee && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Timesheet History</div>
              </div>
            )
          )}
        </div>
        {!company?._id && (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Company Selected</div>
          </div>
        )}
        {company?._id && !singleEmployee && (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Employee Selected</div>
          </div>
        )}
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedTimesheetHistory.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </ManagerLayout>
  );
};

export default ManagerTimesheets;
