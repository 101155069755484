import { useEffect, useState, useMemo } from 'react';
import { getPendingClaims, approveClaim, rejectClaim, fetchPendingClaims } from '../../../actions/claimAction';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../layout/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import empty from '../../../assets/images/empty.png';
import { format } from 'date-fns';
import { ApproveClaimModal } from './approveClaimModal';
import { RejectClaimModal } from './rejectClaimModal';
import { Eye } from 'iconsax-react';
import { ViewDocumentModal } from './viewDocumentModal';
import Dropdown from '../../../components/dropdown';
import defaultPic from '../../../assets/images/Default_profilepic.png';

const ClaimSubmissions = () => {
  const dispatch = useDispatch();

  const pendingClaims = useSelector((state) => state?.claim?.pendingClaims) || [];
  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);

  const [sortConfig, setSortConfig] = useState({
    key: 'employee.fullName',
    direction: 'ascending'
  });
  const [selectedClaims, setselectedClaims] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToView, setFileToView] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(fetchPendingClaims([]));
  }, [dispatch]);

  useEffect(() => {
    if (company?._id) {
      dispatch(getPendingClaims(company?._id));
    }
  }, [company]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      // setSelectedCompany(selectedValue);
      // setCompanyId(selectedOption?.companyId);
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleView = (file) => {
    setFileToView(file);
    setIsModalOpen(true);
  };

  const closeViewModal = () => {
    setIsModalOpen(false);
    setFileToView(null);
  };

  const sortedpendingClaims = useMemo(() => {
    if (!pendingClaims.length) {
      return [];
    }

    let sortableClaims = pendingClaims.flatMap((claim) =>
      claim.pendingClaims.map((pendingClaim) => ({
        ...pendingClaim,
        employee: claim.employee
      }))
    );

    sortableClaims.sort((a, b) => {
      const fieldA = sortConfig.key.split('.').reduce((obj, key) => obj[key], a);
      const fieldB = sortConfig.key.split('.').reduce((obj, key) => obj[key], b);

      const valueA = typeof fieldA === 'string' ? fieldA.toLowerCase() : String(fieldA || '');
      const valueB = typeof fieldB === 'string' ? fieldB.toLowerCase() : String(fieldB || '');

      if (sortConfig.direction === 'ascending') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    return sortableClaims;
  }, [pendingClaims, sortConfig]);

  useEffect(() => {
    if (selectedClaims.length === sortedpendingClaims.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedClaims, sortedpendingClaims]);

  const handleCheckboxChange = (claim) => {
    setselectedClaims((prevSelected) =>
      prevSelected.includes(claim) ? prevSelected.filter((selected) => selected !== claim) : [...prevSelected, claim]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setselectedClaims([]);
    } else {
      setselectedClaims(sortedpendingClaims);
    }
    setSelectAll(!selectAll);
  };

  const handleApproveClaim = () => {
    setShowApproveModal(true);
  };

  const handleRejectClaim = () => {
    setShowRejectModal(true);
  };

  const confirmApproveClaim = () => {
    console.log(selectedClaims);
    dispatch(approveClaim(selectedClaims, company?._id));
    setShowApproveModal(false);
    setselectedClaims([]);
    setSelectAll(false);
  };

  const confirmRejectClaim = () => {
    console.log(selectedClaims);
    dispatch(rejectClaim(selectedClaims, company?._id));
    setShowRejectModal(false);
    setselectedClaims([]);
    setSelectAll(false);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'Approved':
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 'Processing':
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 'Pending':
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 'Cancelled':
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedpendingClaims.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedpendingClaims.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Layout pageTitle={'Pending Claims'} page={'Employees'} subPage={'Pending Claims'}>
      {/* <h3 className="text-4xl font-semibold text-black pb-5">Pending Leave Applications</h3> */}
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          <div className="flex flex-col gap-4 lg:flex-row justify-between items-center gap-x-4 w-full">
            <div className="w-full lg:w-1/2">
              {companyOptions.length > 0 && (
                <Dropdown
                  value={companyOptions?.find((option) => option.value === company?.companyName)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select Company"
                  name="company"
                  id="company"
                />
              )}
            </div>
            <div className="w-full lg:w-1/2 flex flex-row items-center lg:justify-end gap-x-3">
              <button
                type="button"
                onClick={handleApproveClaim}
                className="h-12 w-36 bg-primary hover:bg-primary/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
                disabled={selectedClaims.length === 0 || sortedpendingClaims.length < 0}
              >
                Approve Claim
              </button>
              <button
                type="button"
                onClick={handleRejectClaim}
                className="h-12 w-36 bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
                disabled={selectedClaims.length === 0 || sortedpendingClaims.length < 0}
              >
                Reject Claim
              </button>
            </div>
          </div>
          {sortedpendingClaims.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th>
                    <th className="h-20 text-center w-16">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimType')} className="cursor-pointer">
                      Claim Type
                      <FontAwesomeIcon icon={getSortIcon('claimType')} size="sm" className="ml-1" />
                    </th>
                    {/* <th onClick={() => handleSort('claimCode')} className="cursor-pointer">
                      Claim Code
                      <FontAwesomeIcon icon={getSortIcon('claimCode')} size="sm" className="ml-1" />
                    </th> */}
                    <th onClick={() => handleSort('claimDate')} className="cursor-pointer">
                      Claim Date <FontAwesomeIcon icon={getSortIcon('claimDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimAmount')} className="cursor-pointer">
                      Claim Amount <FontAwesomeIcon icon={getSortIcon('claimAmount')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                      Created Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-r-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((claim, index) => {
                    const formattedClaimDate = !Number.isNaN(new Date(claim?.claimDate).getTime())
                      ? format(new Date(claim?.claimDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedCreatedDate = !Number.isNaN(new Date(claim?.createdDate).getTime())
                      ? format(new Date(claim?.createdDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(claim?.status);

                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedClaims.includes(claim)}
                            onChange={() => handleCheckboxChange(claim)}
                          />
                        </td>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={claim?.employee?.avatar ? claim?.employee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center" title={claim?.employee?.fullName}>
                          {/* {claim?.employee?.fullName?.slice(0, 12)}
                          {claim?.employee?.fullName?.length > 12 ? '...' : ''} */}
                          {claim?.employee?.fullName}
                        </td>
                        <td className="h-20 text-center">{claim?.claimType}</td>
                        {/* <td className="h-20 text-center">{claim?.claimCode}</td> */}
                        <td className="h-20 text-center">{formattedClaimDate}</td>
                        <td className="h-20 text-center">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(claim?.claimAmount)}
                        </td>
                        <td className="h-20 text-center">{formattedCreatedDate}</td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-20 text-center flex justify-center items-center">
                          <div
                            className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                            onClick={() => handleView(claim?.claimFile)}
                            title="View"
                          >
                            <Eye color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Pending Claims</div>
            </div>
          )}
        </div>
        <div className="flex">
          {currentEntries.length > 0 && (
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedpendingClaims.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          )}
        </div>

        {showApproveModal && (
          <ApproveClaimModal onCancel={() => setShowApproveModal(false)} onConfirm={confirmApproveClaim} />
        )}
        {showRejectModal && (
          <RejectClaimModal onCancel={() => setShowRejectModal(false)} onConfirm={confirmRejectClaim} />
        )}
      </div>
      {isModalOpen && <ViewDocumentModal fileToView={fileToView} closeViewModal={closeViewModal} />}
    </Layout>
  );
};

export default ClaimSubmissions;
