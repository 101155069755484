import { Calendar1, DollarCircle, User } from 'iconsax-react';

const PayrollTableHeader = ({ currentPayroll }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap gap-x-6 gap-y-2 w-full mb-5">
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 w-72">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <Calendar1 color="#3D54DD" variant="Broken" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Salary Period</div>
            <div className="font-bold text-lg text-black">
              {currentPayroll?.month} {currentPayroll?.year}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 w-72">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <User color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Payable Employees</div>
            <div className="font-bold text-lg text-black">{currentPayroll?.totalEmployees}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-6 gap-y-2 w-full mb-5">
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 flex-grow basis-full sm:basis-1/2 md:basis-1/5">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <DollarCircle color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Total Payroll Cost</div>
            <div className="font-bold text-lg text-black">
              {new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(currentPayroll?.totalPayrollCost)}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 flex-grow basis-full sm:basis-1/2 md:basis-1/5">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <DollarCircle color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Total CPF Contribution</div>
            <div className="font-bold text-lg text-black">
              {new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(currentPayroll?.totalCPFContribution
              )}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 flex-grow basis-full sm:basis-1/2 md:basis-1/5">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <DollarCircle color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Total Net Salary</div>
            <div className="font-bold text-lg text-black">
              {new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(currentPayroll?.totalNetSalary)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollTableHeader;
