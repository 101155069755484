export const salutationOptions = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Mdm.', label: 'Mdm.' },
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'A/P', label: 'A/P' }
];

export const identificationType = [
  { label: 'NRIC', value: 'NRIC' },
  { label: 'FIN', value: 'FIN' },
  {
    label: 'Immigration File Reference Number',
    value: 'Immigration File Reference Number'
  },
  { label: 'Work Permit Number', value: 'Work Permit Number' },
  {
    label: 'Malaysian I/C (for non-resident director & seaman only)',
    value: 'Malaysian I/C (for non-resident director & seaman only)'
  },
  {
    label: 'Passport Number (for non-resident director & seaman only)',
    value: 'Passport Number (for non-resident director & seaman only)'
  },
  {
    label: 'Passport No. (for non-resident employee)',
    value: 'Passport No. (for non-resident employee)'
  }
];

export const raceOptions = [
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Indian', value: 'Indian' },
  { label: 'Eurasian', value: 'Eurasian' },
  { label: 'Other', value: 'Other' }
];

export const maritalStatusOptions = [
  { label: 'Married', value: 'Married' },
  { label: 'Single', value: 'Single' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Widoweb', value: 'Widoweb' }
];

export const religionOptions = [
  { label: 'Hindu', value: 'Hindu' },
  { label: 'Muslim', value: 'Muslim' },
  { label: 'Shikh', value: 'Shikh' },
  { label: 'Isai', value: 'Isai' },
  { label: 'Christian', value: 'Christian' },
  { label: 'Buddhist', value: 'Buddhist' },
  { label: 'Free-Thinker', value: 'Free-Thinker' },
  { label: 'Other', value: 'Other' }
];

export const citizenshipTypeOptions = [
  { label: 'Singaporean', value: 'Singaporean' },
  { label: 'PR', value: 'PR' },
  { label: 'Foreigner', value: 'Foreigner' }
];

export const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' }
];

export const workPassOptions = [
  { label: 'Employment Pass', value: 'Employment Pass' },
  { label: 'S Pass', value: 'S Pass' },
  { label: 'Work Permit', value: 'Work Permit' },
  { label: 'LTVP/LTVP+', value: 'LTVP/LTVP+' }
];

export const employmentStatusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Retired', value: 'Retired' }
];

export const workingHourTypeOptions = [
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Hourly', value: 'Hourly' }
];

export const designationOptions = [
  { label: 'Remisier', value: '1' },
  { label: 'Real Estate Agent', value: '2' },
  { label: 'Insurance Agent', value: '3' },
  { label: 'Distributor', value: '4' },
  { label: 'Financial Advisor', value: '5' },
  { label: 'Instructor', value: '6' },
  { label: 'Therapist', value: '7' },
  { label: 'Singapore Pools Retailers', value: '8' },
  { label: 'Others', value: '9' }
];

export const employmentType = [
  { label: 'Full-Time', value: 'Full-Time' },
  { label: 'Part-Time', value: 'Part-Time' },
  { label: 'Internship', value: 'Internship' },
  { label: 'Contract', value: 'Contract' },
  { label: 'Freelance', value: 'Freelance' },
  { label: 'Probation', value: 'Probation' }
];

export const paymentMethodOptions = [
  { label: 'Cash', value: 'Cash' },
  { label: 'Net Banking', value: 'Net Banking' }
];

export const currencyOptions = [
  { label: 'SGD', value: 'SGD' },
  { label: 'USD', value: 'USD' }
];

export const bankOptions = [
  { label: 'DBS/POSB', value: 'DBS/POSB' },
  { label: 'OCBC', value: 'OCBC' },
  { label: 'UOB', value: 'UOB' }
];

export const roleOptions = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Employee', value: 'Employee' }
];

export const frequencyOptions = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Yearly', value: 'Yearly' }
];

export const dayOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
  { label: '29', value: 29 },
  { label: '30', value: 30 },
  { label: '31', value: 31 }
];

export const monthOptions = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' }
];

export const citizenshipOptions = [
  { label: 'AFGHAN', value: '351' },
  { label: 'ALBANIAN', value: '201' },
  { label: 'ALGERIAN', value: '401' },
  { label: 'AMERICAN', value: '503' },
  { label: 'AMERICAN SAMOA', value: '721' },
  { label: 'ANDORRAN', value: '153' },
  { label: 'ANGOLAN', value: '451' },
  { label: 'ANGUILLA', value: '670' },
  { label: 'ANTARCTICA', value: '673' },
  { label: 'ANTIGUA', value: '641' },
  { label: 'ARGENTINIAN', value: '601' },
  { label: 'ARMENIAN', value: '217' },
  { label: 'ARUBA', value: '625' },
  { label: 'AUSTRALIAN', value: '701' },
  { label: 'AUSTRIAN', value: '131' },
  { label: 'AZERBAIJANI', value: '218' },
  { label: 'BAHAMAS', value: '642' },
  { label: 'BAHRAINI', value: '371' },
  { label: 'BANGLADESHI', value: '352' },
  { label: 'BARBADOS', value: '643' },
  { label: 'BELARUSSIAN', value: '211' },
  { label: 'BELGIAN', value: '101' },
  { label: 'BELIZE', value: '644' },
  { label: 'BENIN', value: '452' },
  { label: 'BERMUDAN', value: '645' },
  { label: 'BHUTAN', value: '353' },
  { label: 'BOLIVIAN', value: '646' },
  { label: 'BOSNIAN', value: '994' },
  { label: 'BOTSWANA', value: '453' },
  { label: 'BOUVET ISLAND', value: '139' },
  { label: 'BR NAT. OVERSEAS', value: '738' },
  { label: 'BR OVERSEAS CIT.', value: '995' },
  { label: 'BR PROTECTED PERS.', value: '996' },
  { label: 'BRAZILIAN', value: '602' },
  { label: 'BRITISH', value: '110' },
  { label: 'BRITISH DEPENDENT TERR CITIZEN', value: '675' },
  { label: 'BRITISH INDIAN OCEAN', value: '708' },
  { label: 'BRITISH SUBJECT', value: '886' },
  { label: 'BRITISH VIRGIN ISLND', value: '671' },
  { label: 'BRUNEIAN', value: '302' },
  { label: 'BULGARIAN', value: '202' },
  { label: 'BURKINA FASO', value: '454' },
  { label: 'BURUNDI', value: '455' },
  { label: 'CAMBODIAN', value: '312' },
  { label: 'CAMEROON', value: '456' },
  { label: 'CANADIAN', value: '501' },
  { label: 'CAPE VERDE', value: '457' },
  { label: 'CAYMANESE', value: '647' },
  { label: 'CENTRAL AFRICAN REP', value: '458' },
  { label: 'CHADIAN', value: '459' },
  { label: 'CHILEAN', value: '603' },
  { label: 'CHINESE', value: '336' },
  { label: 'CHRISTMAS ISLANDS', value: '709' },
  { label: 'COCOS KEELING ISLAND', value: '710' },
  { label: 'COLOMBIAN', value: '604' },
  { label: 'COMOROS', value: '460' },
  { label: 'CONGO', value: '461' },
  { label: 'COOK ISLANDS', value: '722' },
  { label: 'COSTA RICAN', value: '648' },
  { label: 'CROATIAN', value: '232' },
  { label: 'CUBAN', value: '621' },
  { label: 'CYPRIOT', value: '372' },
  { label: 'CZECH', value: '234' },
  { label: 'CZECHOSLOVAK', value: '203' },
  { label: 'DANISH', value: '102' },
  { label: 'DEMOCRATIC REP OF THE CONGO', value: '674' },
  { label: 'DJIBOUTI', value: '407' },
  { label: 'DOMINICA', value: '649' },
  { label: 'DOMINICAN REPUBLIC', value: '622' },
  { label: 'DUTCH', value: '991' },
  { label: 'EAST TIMORESE', value: '887' },
  { label: 'ECUADORIAN', value: '605' },
  { label: 'EGYPTIAN', value: '402' },
  { label: 'EQUATORIAL GUINEA', value: '462' },
  { label: 'ERITREA', value: '410' },
  { label: 'ESTONIAN', value: '213' },
  { label: 'ETHIOPIAN', value: '408' },
  { label: 'FAEROE ISLANDS', value: '141' },
  { label: 'FALKLAND IS', value: '651' },
  { label: 'FIJIAN', value: '702' },
  { label: 'FILIPINO', value: '305' },
  { label: 'FINNISH', value: '132' },
  { label: 'FRENCH', value: '103' },
  { label: 'FRENCH GUIANA', value: '652' },
  { label: 'FRENCH POLYNESIA', value: '723' },
  { label: 'FRENCH SOUTHERN TERR', value: '711' },
  { label: 'GABON', value: '463' },
  { label: 'GAMBIAN', value: '464' },
  { label: 'GEORGIA', value: '216' },
  { label: 'GERMAN', value: '104' },
  { label: 'GHANAIAN', value: '421' },
  { label: 'GIBRALTAR', value: '154' },
  { label: 'GREEK', value: '105' },
  { label: 'GREENLAND', value: '142' },
  { label: 'GRENADIAN', value: '653' },
  { label: 'GUADELOUPE', value: '654' },
  { label: 'GUAM', value: '724' },
  { label: 'GUATEMALA', value: '655' },
  { label: 'GUINEA', value: '465' },
  { label: 'GUINES BISSAU', value: '466' },
  { label: 'GUYANA', value: '656' },
  { label: 'HAITIAN', value: '657' },
  { label: 'HEARD MCDONALD ISLND', value: '712' },
  { label: 'HONDURAN', value: '658' },
  { label: 'HONG KONG', value: '332' },
  { label: 'HUNGARIAN', value: '205' },
  { label: 'ICELAND', value: '133' },
  { label: 'INDIAN', value: '354' },
  { label: 'INDONESIAN', value: '303' },
  { label: 'IRANIAN', value: '373' },
  { label: 'IRAQI', value: '374' },
  { label: 'IRISH', value: '106' },
  { label: 'ISLE OF MAN', value: '672' },
  { label: 'ISRAELI', value: '375' },
  { label: 'ITALIAN', value: '107' },
  { label: 'IVORY COAST', value: '422' },
  { label: 'JAMAICAN', value: '659' },
  { label: 'JAPANESE', value: '331' },
  { label: 'JORDANIAN', value: '376' },
  { label: 'KAMPUCHEAN', value: '676' },
  { label: 'KAZAKH', value: '221' },
  { label: 'KENYAN', value: '423' },
  { label: 'KIRGHIZ', value: '219' },
  { label: 'KIRIBATI', value: '725' },
  { label: 'KOREAN, NORTH', value: '337' },
  { label: 'KOREAN, SOUTH', value: '333' },
  { label: 'KOSOVAR', value: '677' },
  { label: 'KUWAITI', value: '377' },
  { label: 'KYRGHIS', value: '990' },
  { label: 'KYRGYZSTAN', value: '894' },
  { label: 'LAOTIAN', value: '313' },
  { label: 'LATVIAN', value: '214' },
  { label: 'LEBANESE', value: '378' },
  { label: 'LESOTHO', value: '467' },
  { label: 'LIBERIAN', value: '424' },
  { label: 'LIBYAN', value: '403' },
  { label: 'LIECHTENSTEIN', value: '138' },
  { label: 'LITHUANIA', value: '215' },
  { label: 'LUXEMBOURG', value: '108' },
  { label: 'MACAO', value: '335' },
  { label: 'MACEDONIA', value: '210' },
  { label: 'MADAGASCAR', value: '425' },
  { label: 'MALAWI', value: '468' },
  { label: 'MALAYSIAN', value: '304' },
  { label: 'MALDIVIAN', value: '355' },
  { label: 'MALI', value: '469' },
  { label: 'MALTESE', value: '155' },
  { label: 'MARSHELLES', value: '735' },
  { label: 'MARTINIQUE', value: '661' },
  { label: 'MAURITANEAN', value: '470' },
  { label: 'MAURITIAN', value: '426' },
  { label: 'MEXICAN', value: '606' },
  { label: 'MICRONESIAN', value: '736' },
  { label: 'MOLDOVIAN', value: '222' },
  { label: 'MONACO', value: '143' },
  { label: 'MONGOLIAN', value: '338' },
  { label: 'MONTENEGRIN', value: '678' },
  { label: 'MONTSERRAT', value: '662' },
  { label: 'MOROCCAN', value: '404' },
  { label: 'MOZAMBIQUE', value: '427' },
  { label: 'MYANMAR', value: '311' },
  { label: 'NAMIBIA', value: '471' },
  { label: 'NAURUAN', value: '703' },
  { label: 'NEPALESE', value: '356' },
  { label: 'NETHERLANDS', value: '109' },
  { label: 'NETHERLANDS ANTIL.', value: '623' },
  { label: 'NEW CALEDONIA', value: '704' },
  { label: 'NEW ZEALANDER', value: '705' },
  { label: 'NICARAGUAN', value: '660' },
  { label: 'NIGER', value: '472' },
  { label: 'NIGERIAN', value: '428' },
  { label: 'NIUE ISLAND', value: '726' },
  { label: 'NORFOLK ISLAND', value: '713' },
  { label: 'NORTHERN MARIANA ISL', value: '734' },
  { label: 'NORWEGIAN', value: '134' },
  { label: 'OC CTRL STH AMERICA', value: '699' },
  { label: 'OC IN OTHER AFRICA', value: '499' },
  { label: 'OC IN S E ASIA', value: '319' },
  { label: 'OC NORTH AMERICA', value: '509' },
  { label: 'OC OCEANIA', value: '799' },
  { label: 'OMAN', value: '379' },
  { label: 'OTHERS', value: '999' },
  { label: 'PACIFIC IS TRUST T', value: '357' },
  { label: 'PAKISTANI', value: '737' },
  { label: 'PALAU', value: '386' },
  { label: 'PALESTINIAN', value: '624' },
  { label: 'PANAMANIAN', value: '706' },
  { label: 'PAPUA NEW GUINEA', value: '607' },
  { label: 'PARAGUAY', value: '336' },
  { label: 'PERUVIAN', value: '608' },
  { label: 'PITCAIRN', value: '305' },
  { label: '727', value: '727' },
  { label: 'POLISH', value: '206' },
  { label: 'PORTUGUESE', value: '111' },
  { label: 'PUERTO RICAN', value: '502' },
  { label: 'QATAR', value: '380' },
  { label: 'REUNION', value: '429' },
  { label: 'ROMANIAN', value: '207' },
  { label: 'RUSSIAN', value: '223' },
  { label: 'RWANDA', value: '473' },
  { label: 'SAINT KITTS NEVIS', value: '663' },
  { label: 'SALVADORAN', value: '650' },
  { label: 'SAMOAN', value: '707' },
  { label: 'SAN MARINO', value: '144' },
  { label: 'SAO TOME PRINCI', value: '474' },
  { label: 'SAUDI ARABIAN', value: '381' },
  { label: 'SENEGALESE', value: '475' },
  { label: 'SERBIAN', value: '679' },
  { label: 'SEYCHELLES', value: '476' },
  { label: 'SIERRA LEONE', value: '477' },
  { label: 'SINGAPORE CITIZEN', value: '301' },
  { label: 'SLOVAK', value: '235' },
  { label: 'SLOVENIAN', value: '233' },
  { label: 'SOLOMON ISLANDS', value: '728' },
  { label: 'SOMALI', value: '409' },
  { label: 'SOUTH AFRICAN', value: '478' },
  { label: 'SPANISH', value: '112' },
  { label: 'SRI LANKAN', value: '358' },
  { label: 'ST HELENA', value: '484' },
  { label: 'ST LUCIA', value: '664' },
  { label: 'ST PIERRE MIQUELON', value: '505' },
  { label: 'ST VINCENT', value: '665' },
  { label: 'SUDANESE', value: '405' },
  { label: 'SURINAME', value: '666' },
  { label: 'SVALBARD JAN MAYEN', value: '135' },
  { label: 'SWAZI', value: '480' },
  { label: 'SWEDISH', value: '136' },
  { label: 'SWISS', value: '137' },
  { label: 'SYRIAN', value: '382' },
  { label: 'TADZHIK', value: '224' },
  { label: 'TAIWANESE', value: '334' },
  { label: 'TAJIKISTANI', value: '992' },
  { label: 'TANZANIAN', value: '430' },
  { label: 'THAI', value: '306' },
  { label: 'TIMORENSE', value: '307' },
  { label: 'TOGO', value: '481' },
  { label: 'TOKELAU ISLANDS', value: '729' },
  { label: 'TONGA', value: '730' },
  { label: 'TRINIDAD AND TOBAGO', value: '667' },
  { label: 'TUNISIA', value: '406' },
  { label: 'TURK', value: '152' },
  { label: 'TURKMEN', value: '225' },
  { label: 'TURKS AND CAICOS IS', value: '668' },
  { label: 'TUVALU', value: '731' },
  { label: 'U S MINOR ISLANDS', value: '504' },
  { label: 'UGANDIAN', value: '431' },
  { label: 'UKRAINIAN', value: '212' },
  { label: 'UNITED ARAB EM.', value: '383' },
  { label: 'UNKNOWN', value: '889' },
  { label: 'UPPER VOLTA', value: '993' },
  { label: 'URUGUAY', value: '609' },
  { label: 'UZBEK', value: '226' },
  { label: 'VANUATU', value: '732' },
  { label: 'VATICAN CITY STATE', value: '145' },
  { label: 'VENEZUELAN', value: '610' },
  { label: 'VIETNAMESE', value: '314' },
  { label: 'VIRGIN ISLANDS US', value: '669' },
  { label: 'WALLIS AND FUTUNA', value: '733' },
  { label: 'WESTERN SAHARA', value: '479' },
  { label: 'YEMEN ARAB REP', value: '388' },
  { label: 'YEMEN, SOUTH', value: '387' },
  { label: 'YEMENI', value: '384' },
  { label: 'YUGOSLAV', value: '209' },
  { label: 'ZAIRAN', value: '482' },
  { label: 'ZAMBIAN', value: '432' },
  { label: 'ZIMBABWEAN', value: '483' }
];
