import { Eye } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import empty from '../../../assets/images/empty.png';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useEffect, useState } from 'react';
import DateDropdown from '../../../components/dateDropdown';
import { useNavigate } from 'react-router-dom';
import { getUserPayslips } from '../../../actions/userAction';

const UserPayslipInitial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const payslips = useSelector((state) => state?.user?.payslips);

  const currentDate = new Date();
  const [year, setYear] = useState(currentDate.getFullYear());
  const [loader, setLoader] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    setYearOptions(generateYearOptions());

    handleGeneratePayslip(year);
  }, []);

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption.value);

    handleGeneratePayslip(selectedOption.value);
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 4; i++) {
      const year = currentYear - i;
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  const handleGeneratePayslip = (selectedYear) => {
    const inputValue = {
      year: selectedYear
    };
    if (selectedYear) {
      setLoader(true);
      dispatch(getUserPayslips(inputValue, setLoader));
    }
  };

  return !loader ? (
    <div className="bg-white p-6 rounded-2xl">
      <div className="flex flex-wrap justify-between gap-3">
        <div className="text-2xl font-bold text-black">Pay Slips - {year}</div>
        <div className="text-black">
          <div>
            {yearOptions.length > 0 && (
              <DateDropdown
                value={yearOptions.find((option) => option.value === year)}
                onChange={handleYearChange}
                options={yearOptions}
                isClearable={false}
                placeholder="Select Year"
                name="year"
                id="year"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-4">
        {payslips?.map((payslip, index) => {
          return (
            <div className="bg-grayLight shadow-sm flex justify-between items-center h-20 p-5 rounded-2xl" key={index}>
              <div className="text-base font-bold text-black">{payslip?.month}</div>
              <button
                className="hover:bg-gray-300 w-8 h-8 rounded-md flex justify-center items-center shadow-md text-xs"
                onClick={() =>
                  navigate(`/user/payslip-view`, {
                    state: { employee: payslip?.payrollDetails[0], selectedPayslip: payslip }
                  })
                }
                title="View"
              >
                <Eye color="#000000" variant="Bold" size={20} />
              </button>
            </div>
          );
        })}
      </div>
      {!payslips?.length > 0 && (
        <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
          <img src={empty} alt="empty data" />
          <div className="mt-5 text-purple1 font-bold text-xl">No Payslip Records</div>
        </div>
      )}
    </div>
  ) : (
    <SkeletonLoader length={8} />
  );
};

export default UserPayslipInitial;
