import { useDispatch, useSelector } from 'react-redux';
import ManagerLayoutWM from '../../../layout/managerLayoutWM';
import { useNavigate } from 'react-router-dom';
import empty from '../../../assets/images/empty.png';
import { useEffect, useState } from 'react';
import { getManagerDashboard } from '../../../actions/managerAction';
import OrganizationChart from '../../../components/organizationChart';
import UpcomingLeavesTable from './upcomingLeaves';
import NoLeavesTakenTable from './noLeavesTaken';

const ManagerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company?.company);
  const dashboardDetails = useSelector((state) => state.company?.dashboardDetails);
  const user = useSelector((state) => state?.employee?.user);

  useEffect(() => {
    dispatch(getManagerDashboard({ companyId: company?._id }));
  }, [company]);

  const allEmployees = dashboardDetails?.employees
    ?.filter((employee) => employee?._id !== user?._id)
    .map((employee) => ({
      label: employee?.fullName || '',
      imageSrc: employee?.avatar || ''
    }));

  return (
    <ManagerLayoutWM pageTitle={'Dashboard'} page={'Dashboard'} subPage={'Dashboard'}>
      <div className="min-h-96  h-full w-full flex flex-col gap-y-2 relative">
        {company && Object.keys(company).length > 0 ? (
          <div>
            <div className="bg-white p-6 rounded-2xl">
              <OrganizationChart nodes={allEmployees} manager={user} />
            </div>
            <div className="flex flex-wrap gap-11 mt-4 bg-white p-6 rounded-2xl">
              <div className="flex flex-grow flex-col justify-center items-center w-fit">
                <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                  {dashboardDetails?.totalEmployees}
                </div>
                <div className="text-black font-semibold">Total Employees</div>
              </div>
              <div
                className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
                onClick={() => navigate('/manager/employees/leave-applications')}
              >
                <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                  {dashboardDetails?.pendingLeavesCount}
                </div>
                <div className="text-black font-semibold group-hover:underline">Pending Leaves</div>
              </div>
              <div
                className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
                onClick={() => navigate('/manager/employees/medical-applications')}
              >
                <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                  {dashboardDetails?.pendingMedicalLeavesCount}
                </div>
                <div className="text-black font-semibold group-hover:underline">Pending Medical Leaves</div>
              </div>
              <div
                className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
                onClick={() => navigate('/manager/employees/claim-applications')}
              >
                <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                  {dashboardDetails?.pendingClaimsCount}
                </div>
                <div className="text-black font-semibold group-hover:underline">Pending Claims Count</div>
              </div>
              <div
                className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
                // onClick={() => navigate('/employees')}
              >
                <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                  {dashboardDetails?.pendingTimesheetsCount}
                </div>
                <div className="text-black font-semibold group-hover:underline">Pending Timesheets</div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 mt-4">
              <div className="bg-white p-6 rounded-2xl w-full lg:w-2/3">
                <UpcomingLeavesTable upcomingLeaves={dashboardDetails?.upcomingLeaves} />
              </div>
              <div className="bg-white p-6 rounded-2xl w-full lg:w-1/3">
                <NoLeavesTakenTable noLeavesTaken={dashboardDetails?.noLeavesTaken} />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Company Selected</div>
          </div>
        )}
      </div>
    </ManagerLayoutWM>
  );
};

export default ManagerDashboard;
