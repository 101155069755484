import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { editCredentialDetails } from '../../../actions/employeeAction';
import { credentialDetailsSchema } from '../../../validations/validationSchemas';
import { Edit2 } from 'iconsax-react';

const CredentialDetails = ({ employee }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: employee?.email || '',
      password: employee?.password || '',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      role: employee?.role,
    },
    validationSchema: credentialDetailsSchema,
    onSubmit: async (values) => {
      console.log('edit credentials: ', values);
      dispatch(editCredentialDetails(values, setEdit));
    }
  });

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-start">
          <div className="font-bold text-black text-2xl">Credential Details</div>
        </div>
        <div className="p-6 relative">
        <div className="w-full lg:w-1/2">
            <div className="text-sm text-black/40 font-bold">Email</div>
            <div className="text-base text-black font-normal mb-3">{employee?.email}</div>
            <div className="text-sm text-black/40 font-bold">Password</div>
            <div className="text-base text-black font-normal mb-3">**********</div>
          </div>
          {/* <div className="text-sm font-normal text-black italic">
            <span className="font-semibold">Email:</span> {employee?.email}
          </div>
          <div className="text-sm font-normal text-black italic">
            <span className="font-semibold">password:</span> **********
          </div>
          <div className="text-sm font-normal text-black italic">
            <span className="font-semibold">Role:</span> {employee?.role}
          </div> */}
          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-4 top-6 hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#000000" variant="Bold" size={20} />
            </button>
          )}
        </div>
      </div>
      {edit && (
        <div className="rounded-xl bg-white shadow-lg w-full">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Email</label>
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    disabled
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Password</label>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500 text-sm">{formik.errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end w-full gap-x-2">
                <button
                  type="button"
                  onClick={() => setEdit(false)}
                  className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CredentialDetails;
