import { useFormik } from 'formik';
import { employeeMedicalSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateMedicalLeave } from '../../../actions/employeeAction';

const EditMedicalLeave = ({ medical, medicalOptions, onClose, employee }) => {
  console.log('medical: ', medical)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      _id: medical?._id,
      medicalId: medical?.medicalId || '',
      medicalType: medical?.medicalType || '',
      medicalCode: medical?.medicalCode || '',
      totalDays: medical?.totalDays || '',
      balanceDays: medical?.balanceDays || '',
      takenDays: medical?.takenDays || '',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
    },
    validationSchema: employeeMedicalSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateMedicalLeave(values, onClose));
    }
  });

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Medical Leave Type</label>
              <Dropdown
                value={medicalOptions.find((option) => option.medicalType === formik.values.medicalType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      medicalType: selectedValue.value,
                      medicalCode: selectedValue.medicalCode,
                      medicalId: selectedValue.medicalId
                    });
                  }
                }}
                options={medicalOptions}
                isClearable={false}
                placeholder="Select Medical Leave"
                name="medicalType"
                id="medicalType"
              />
              {formik.touched.medicalType && formik.errors.medicalType ? (
                <div className="text-red-500 text-sm">{formik.errors.medicalType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Total Days</label>
              <input
                type="number"
                placeholder="Total Days"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="totalDays"
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    totalDays: e.target.value,
                    balanceDays: e.target.value,
                    takenDays: 0
                  })
                }
                value={formik.values.totalDays}
              />
              {formik.touched.totalDays && formik.errors.totalDays ? (
                <div className="text-red-500 text-sm">{formik.errors.totalDays}</div>
              ) : null}
            </div>
          </div>
          
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditMedicalLeave;
