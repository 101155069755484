import { COUNT_ADD_ONE } from '../actions/generalAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  count: 0
};

export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNT_ADD_ONE:
      return {
        ...state,
        count: state.count + 1
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default generalReducer;
