import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { getEmployee } from './employeeAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_PENDING_CLAIMS = 'FETCH_PENDING_CLAIMS';

export const fetchPendingClaims = (data) => ({
  type: FETCH_PENDING_CLAIMS,
  payload: data
});

export const submitClaim = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);
  console.log(employeeId)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/submit-claim`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editClaim = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/edit-claim`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelClaim = (inputValue) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/cancel-claim`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const getPendingClaims = (companyId) => {
  console.log('companyId: ', companyId);

  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/claim/get-pending-claims`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingclaims res: ', response.data);
        dispatch(fetchPendingClaims(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveClaim = (claims, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/approve-claim`, claims, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingClaims(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectClaim = (claims, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/reject-claim`, claims, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingClaims(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};


export const getPendingClaimsByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/claim/get-pending-claims-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingclaims res: ', response.data);
        dispatch(fetchPendingClaims(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveClaimByApprover = (claims) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/approve-claim`, claims, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingClaimsByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectClaimByApprover = (claims) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/reject-claim`, claims, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingClaimsByApprover());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

