import React, { useState, useEffect } from 'react';

const TimePicker = ({ time, onTimeChange }) => {
  const [hours, setHours] = useState(time ? time.hours : '12');
  const [minutes, setMinutes] = useState(time ? time.minutes : '00');
  const [period, setPeriod] = useState(time ? time.period : 'AM');

  useEffect(() => {
    if (onTimeChange) {
      onTimeChange(hours, minutes, period);
    }
  }, [hours, minutes, period]);

  const handleHoursChange = (e) => setHours(e.target.value);
  const handleMinutesChange = (e) => setMinutes(e.target.value);
  const handlePeriodChange = (e) => setPeriod(e.target.value);

  return (
    <div className="flex flex-row flex-wrap gap-2 items-center h-12 bg-grayLight rounded-xl px-5 py-2 font-semibold">
      <div className="flex flex-row gap-x-3 h-full">
        <select value={hours} onChange={handleHoursChange} className="rounded-sm h-full">
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
              {i + 1 < 10 ? `0${i + 1}` : i + 1}
            </option>
          ))}
        </select>
        <span>:</span>
        <select value={minutes} onChange={handleMinutesChange} className="rounded-sm">
          {Array.from({ length: 60 }, (_, i) => (
            <option key={i} value={i < 10 ? `0${i}` : i}>
              {i < 10 ? `0${i}` : i}
            </option>
          ))}
        </select>
        <select value={period} onChange={handlePeriodChange} className="rounded-sm">
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
};

export default TimePicker;
