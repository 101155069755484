import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import logo from '../../../assets/logo/hrLogoBlue.png';
import { createNewPasswordSchema } from '../../../validations/validationSchemas';
import { createNewPassword } from '../../../actions/accountAction';

const CreateNewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const { state } = location;

  const email = state?.email;

  console.log('email: ', email);

  const formik = useFormik({
    initialValues: {
      password: '',
      verifyPassword: '',
      email: email
    },
    validationSchema: createNewPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const { password, email } = values;
      dispatch(createNewPassword({ password, email }, navigate, setLoading));
    }
  });

  return (
    <div className="h-screen relative">
      <div
        className="absolute left-0 top-0 flex-col justify-center items-center p-10 cursor-pointer"
        onClick={() => navigate('/login')}
      >
        <img src={logo} alt="Logo" className="w-16 lg:w-24" />
      </div>
      <div className="flex flex-col h-full justify-center bg-gray-50 py-12 text-center space-y-12">
        <div className="bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl flex flex-col gap-y-10">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-bold text-2xl lg:text-3xl text-purple1">Create New Password</div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>Email: {email}</p>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-y-12">
              <div className="flex flex-col items-lef justify-between w-full gap-y-3">
                <div>
                  <label className="block text-white lg:text-black font-medium text-sm text-left">Password</label>
                  <input
                    name="password"
                    className="mb-2 w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="New Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500 text-sm w-full flex justify-start">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block text-white lg:text-black font-medium text-sm text-left">
                    Verify Password
                  </label>
                  <input
                    name="verifyPassword"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.verifyPassword}
                    placeholder="Verify Password"
                  />
                  {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
                    <div className="text-red-500 text-sm w-full flex justify-start">{formik.errors.verifyPassword}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col">
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full py-5 text-center disabled:bg-gray-400 text-sm font-semibold text-white bg-purple1 hover:bg-purple1/90 rounded-xl border-none shadow-sm"
                >
                  Create New Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
