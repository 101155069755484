import { Calendar1, DollarCircle, User } from 'iconsax-react';
import { monthOptions } from '../../../constant/dropdownConstOptions';
import { useEffect, useState } from 'react';
import DateDropdown from '../../../components/dateDropdown';
import DateDropdown2 from '../../../components/dateDropdown2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistoryPayroll, generatePayrollHistory } from '../../../actions/payrollAction';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import CompanyDropdown from '../../../components/companyDropdown';

const HistoryPayrollTableHeader = ({ historyPayroll }) => {
  const dispatch = useDispatch();
  console.log('historyPayroll: ', historyPayroll);

  const currentDate = new Date();
  const [yearOptions, setYearOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [month, setMonth] = useState(historyPayroll?.month || currentDate.toLocaleString('default', { month: 'long' }));
  const [year, setYear] = useState(historyPayroll?.year || currentDate.getFullYear());

  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  useEffect(() => {
    setYearOptions(generateYearOptions());
    fetchHistoryPayroll([])
  }, []);

  // useEffect(() => {
  //   setMonth(historyPayroll?.month || currentDate.toLocaleString('default', { month: 'long' }));
  //   setYear(historyPayroll?.year || currentDate.getFullYear());
  // }, [historyPayroll]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
    fetchHistoryPayroll([])
  };

  const handleMonthChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    setMonth(selectedValue);

    if (year && company?._id) {
      dispatch(generatePayrollHistory({ month: selectedOption?.value, year: year, companyId: company._id }));
    }
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption?.value);

    if (month && company?._id) {
      dispatch(generatePayrollHistory({ month: month, year: selectedOption.value, companyId: company._id }));
    }
  };

  useEffect(() => {
    if (month && year && company?._id)
      dispatch(generatePayrollHistory({ month: month, year: year, companyId: company._id }));
  }, [month, year, company, dispatch]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 4; i++) {
      const year = currentYear - i;
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  return (
    <div className="flex flex-col">
      <div className="w-full lg:w-1/3 mb-6">
        <label className="mb-2.5 block text-black font-bold">Select Company</label>
        {companyOptions.length > 0 && (
          <CompanyDropdown
            value={companyOptions.find((option) => option.value === company?.companyName)}
            onChange={handleCompanyChange}
            options={companyOptions}
            isClearable={false}
            placeholder="Select Company"
            name="allowanceType"
            id="allowanceType"
          />
        )}
      </div>
      <div className="flex flex-wrap gap-x-6 gap-y-2 w-full mb-5">
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 w-96">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <Calendar1 color="#3D54DD" variant="Broken" size={25} />
          </div>
          <div className="flex flex-col w-full">
            <div className="font-medium text-black/40 text-sm">Salary Period</div>
            <div className="font-bold text-lg text-black flex">
              <DateDropdown
                value={monthOptions.find((option) => option.value === month)}
                onChange={handleMonthChange}
                options={monthOptions}
                isClearable={false}
                placeholder="Month"
                name="month"
                id="month"
              />
              {yearOptions.length > 0 && (
                <DateDropdown2
                  value={yearOptions.find((option) => option.value === year)}
                  onChange={handleYearChange}
                  options={yearOptions}
                  isClearable={false}
                  placeholder="Select Year"
                  name="year"
                  id="year"
                />
              )}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 w-72">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <User color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Payable Employees</div>
            <div className="font-bold text-lg text-black">
              {historyPayroll?.totalEmployees ? historyPayroll?.totalEmployees : 0}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-6 gap-y-2 w-full mb-5">
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 flex-grow basis-full sm:basis-1/2 md:basis-1/5">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <DollarCircle color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Total Payroll Cost</div>
            <div className="font-bold text-lg text-black">
              {historyPayroll?.totalEmployees
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(historyPayroll?.totalPayrollCost)
                : '0.00'}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 flex-grow basis-full sm:basis-1/2 md:basis-1/5">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <DollarCircle color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Total CPF Contribution</div>
            <div className="font-bold text-lg text-black">
              {historyPayroll?.totalCPFContribution
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(historyPayroll?.totalCPFContribution)
                : '0.00'}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl h-20 py-3 px-2 flex flex-row items-center gap-x-2 flex-grow basis-full sm:basis-1/2 md:basis-1/5">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <DollarCircle color="#3D54DD" variant="Outline" size={25} />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-black/40 text-sm">Total Net Salary</div>
            <div className="font-bold text-lg text-black">
              {historyPayroll?.totalNetSalary
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(historyPayroll?.totalNetSalary)
                : '0.00'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryPayrollTableHeader;
