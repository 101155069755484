import { Link } from 'react-router-dom';
import { ArrowRight2, Notification } from 'iconsax-react';

import defaultPic from '../assets/images/Default_profilepic.png';
import { useSelector } from 'react-redux';

const Header = ({ page, subPage, pageTitle }) => {
  const user = useSelector((state) => state?.employee?.user);

  return (
    <header className="sticky top-0 z-[40] flex w-full bg-grayLight drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex flex-col items-start justify-start">
          {page && (
            <div className="text-sm text-black/40">
              {page} / {subPage}
            </div>
          )}
          <div className="text-2xl sm:text-[32px] font-[900] mt-2 text-black">{pageTitle}</div>
        </div>

        <div className="flex items-center gap-3">
          {/* <Link to="#" className="relative flex h-11 w-11 items-center justify-center rounded-full bg-white shadow-lg">
            <div className="relative">
              <Notification color="#000000" variant="Bold" size={20} />
              <span className={`absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-red-500`}>
                <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-75"></span>
              </span>
            </div>
          </Link> */}
          <div className="flex items-center justify-start gap-x-2 bg-white h-11 pl-2 pr-3 rounded-full shadow-lg" to="#">
            <span className="h-8 w-8 rounded-full">
              <img src={user?.avatar ? user?.avatar : defaultPic} alt="User" className='w-full h-full rounded-full object-cover'/>
            </span>

            <span className="hidden text-right lg:block">
              <span className="block text-sm font-medium text-black/40">{user?.fullName}</span>
            </span>

            {/* <ArrowRight2 color="rgba(0, 0, 0, 0.4)" variant='TwoTone' size={14} /> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
