import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SidebarLinkGroup from '../SidebarLinkGroup';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as Employees } from '../../assets/icons/employees.svg';
import ArrowIcon from '../../assets/icons/arrowIcon';
import { DollarCircle, LogoutCurve, ShieldTick } from 'iconsax-react';
import DefaultProfile from '../../assets/images/defaultCompany.png';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../actions/userAction';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = location;

  const company = useSelector((state) => state?.company?.company);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  const handleLogout = () => {
    dispatch(userLogout(navigate));
  };

  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen)
    localStorage.setItem('sidebar', !sidebarOpen);
  }

  return (
    <aside
      className={`left-0 top-0 z-[9999] flex h-full ${
        sidebarOpen ? 'w-[310px]' : 'w-[75px]'
      } flex-col overflow-y-hidden bg-black2`}
    >
      <div className="flex items-center px-4 pt-10 gap-x-2 relative">
        <div onClick={handleToggle} className="cursor-pointer flex-shrink-0">
          <img
            src={company?.companyLogo || DefaultProfile}
            alt="company logo"
            className="w-10 h-10 rounded-full flex-shrink-0 object-cover"
          />
        </div>

        {sidebarOpen && (
          <div className="text-base text-white font-bold text-left max-w-48">
            {company?.companyName}
            {/* {company?.companyName?.slice(0, 13)}
            {company?.companyName?.length > 13 ? '...' : ''} */}
          </div>
        )}
        {sidebarOpen && (
          <div className="absolute right-5">
            <button
              onClick={handleToggle}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              className="block"
            >
              <ArrowLeft />
            </button>
          </div>
        )}
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear justify-between h-full">
        <nav className={`py-4 mt-5 ${sidebarOpen ? 'px-2' : 'px-2'}`}>
          <div>
            <ul className="mb-6 flex flex-col gap-3">
              <SidebarLinkGroup activeCondition={pathname === '/' || pathname.includes('dashboard')}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5 h-10 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/' || pathname.includes('dashboard')) && 'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/dashboard');
                        }}
                      >
                        <Dashboard />
                        {sidebarOpen && <span>Dashboard</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/dashboard"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Dashboard</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              <SidebarLinkGroup activeCondition={pathname === '/settings' || pathname.includes('settings')}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5 h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/settings' || pathname.includes('settings')) && 'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/settings/companies');
                        }}
                      >
                        <Settings />
                        {sidebarOpen && <ArrowIcon open={open} />}
                        {sidebarOpen && <span>Settings</span>}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/settings/companies"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Companies</span>}
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink
                              to="/settings/company"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Company</span>}
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink
                              to="/settings/miscellaneous"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Miscellaneous</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              <SidebarLinkGroup activeCondition={pathname === '/employees' || pathname.startsWith('/employees/')}>
                {(handleClick, open) => {
                  const isEmployees = pathname === '/employees';

                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5 h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/employees' || pathname.startsWith('/employees/')) && 'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/employees');
                        }}
                      >
                        <Employees />
                        {sidebarOpen && <ArrowIcon open={open} />}
                        {sidebarOpen && <span>Employees</span>}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/employees"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isEmployees && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Employees</span>}
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink
                              to="/employees/add-employee"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Add Employee</span>}
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink
                              to="/employees/apply-leave"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Apply Leave</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/employees/leave-applications"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Leave Applications</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/employees/apply-medical-leave"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Apply Medical Leave</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/employees/medical-leave-applications"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Medical Applications</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/employees/submit-claim"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Submit Claims</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/employees/claim-submissions"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isActive && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Approve Claims</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              <SidebarLinkGroup activeCondition={pathname === '/payroll' || pathname.startsWith('/payroll/')}>
                {(handleClick, open) => {
                  const isEmployeesPath = pathname === '/payroll';
                  const isPayrollDetailsPath = pathname === '/payroll/generate-payroll';
                  const isPayrollEditEmployee = pathname === '/payroll/edit-payroll-employee';
                  const isHistoryPayroll = pathname === '/payroll/history-payroll';
                  const isPayslip = pathname === '/payroll/payslip';
                  const isPayslipView = pathname === '/payroll/payslip-view';
                  const isTimesheets = pathname === '/payroll/timesheets';

                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5  h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/payroll' || pathname.startsWith('/payroll/')) && 'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/payroll/timesheets');
                        }}
                      >
                        <DollarCircle
                          color="rgba(255, 255, 255, 0.9)"
                          variant="Outline"
                          size={22}
                          className="-ml-0.5"
                        />
                        {sidebarOpen && <span>Payroll</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/payroll/timesheets"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isTimesheets && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Timesheets</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/payroll/generate-payroll"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                ((isPayrollEditEmployee || isPayrollDetailsPath) && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Generate Payroll</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/payroll/history-payroll"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isHistoryPayroll && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Payroll History</span>}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/payroll/payslip"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                ((isPayslip || isPayslipView) && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Payslip</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={pathname === '/access-control' || pathname.startsWith('/access-control/')}
              >
                {(handleClick, open) => {
                  const isAccessControl = pathname === '/access-control';

                  return (
                    <React.Fragment>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-2.5 h-10 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark ${
                          (pathname === '/access-control' || pathname.startsWith('/access-control')) && 'bg-graydark'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                          navigate('/access-control')
                        }}
                      >
                        <ShieldTick color="rgba(255, 255, 255, 0.9)" variant="Outline" size={22} className="-ml-0.5" />
                        {sidebarOpen && <span>Access Control</span>}
                        {sidebarOpen && <ArrowIcon open={open} />}
                      </NavLink>
                      <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                        <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                          <li>
                            <NavLink
                              to="/access-control"
                              className={({ isActive }) =>
                                'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                (isAccessControl && '!text-white')
                              }
                            >
                              {sidebarOpen && <span>Access Control</span>}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
            </ul>
          </div>
        </nav>
        <div>
          <NavLink
            to="#"
            className={`group relative flex items-center gap-2.5 h-10 rounded-sm pb-12 px-7 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark bg-graydark`}
            onClick={() => handleLogout()}
          >
            <LogoutCurve color="rgba(255, 255, 255, 0.9)" variant="Outline" size={22} className="-ml-1.5" />
            {sidebarOpen && <span>Log out</span>}
          </NavLink>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
