import { FETCH_GET_OTP_DATA_SUCCESS } from '../actions/accountAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  otpData: ''
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_OTP_DATA_SUCCESS:
      return {
        ...state,
        otpData: action.payload?.otpData
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default accountReducer;
