import { useFormik } from 'formik';
import countryOptions from '../../../constant/countries.json';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import CountryCodeDropdown from '../../../components/countryCodeDropdown';
import Layout from '../../../layout/layout';
import DatePicker from '../../../components/DatePicker';
import { addEmployee } from '../../../actions/employeeAction';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  citizenshipTypeOptions,
  genderOptions,
  identificationType,
  maritalStatusOptions,
  raceOptions,
  religionOptions,
  salutationOptions,
  workPassOptions
} from '../../../constant/dropdownConstOptions';
import { addEmployeeSchema } from '../../../validations/validationSchemas';

const AddEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { company } = location.state || {}; // Access the company object or ID
  console.log("company in add employee: ", company);

  const formik = useFormik({
    initialValues: {
      salutation: '',
      fullName: '',
      idType: '',
      idNum: '',
      personalEmail: '',
      email: '',
      dob: '',
      maritalStatus: '',
      areaCode: '',
      mobile: '',
      areaCodeContact: '',
      contact: '',
      religion: '',
      race: '',
      nationality: '',
      gender: '',
      citizenship: '',
      prObtained: '',
      reEntryExpiration: '',
      workPassType: '',
      workPassObtained: '',
      workPassExpiration: '',
      address: '',
      country: '',
      postal: '',
      companyId: company?._id,
    },
    validationSchema: addEmployeeSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addEmployee(values, navigate, resetForm));
    }
  });

  return (
    <Layout pageTitle={'Add Employee'} page={'Employees'} subPage={'Add Employee'}>
      <h3 className="text-2xl font-bold text-black pb-5">{company?.companyName}</h3>
      <div className="rounded-sm border border-transparent bg-white shadow-default w-full">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-6">
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="flex w-full lg:w-1/2">
                <div className="w-32">
                  <label className="mb-2.5 block text-black font-bold">Salutation</label>
                  <Dropdown
                    value={salutationOptions.find((option) => option.value === formik.values.salutation)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('salutation', selectedValue.value);
                    }}
                    options={salutationOptions}
                    isClearable={false}
                    placeholder="Select"
                    name="salutation"
                    id="salutation"
                  />
                  {formik.touched.salutation && formik.errors.salutation ? (
                    <div className="text-red-500 text-sm">{formik.errors.salutation}</div>
                  ) : null}
                </div>
                <div className="w-full">
                  <label className="mb-2.5 block text-black font-bold">Full name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full rounded-e-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="fullName"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Email</label>
                <input
                  type="email"
                  placeholder="Company Email"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500 text-sm">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Identification Type</label>
                <Dropdown
                  value={identificationType.find((option) => option.value === formik.values.idType)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('idType', selectedValue.value);
                  }}
                  options={identificationType}
                  isClearable={false}
                  isSearchable={false}
                  placeholder="Select Identification Type"
                  name="idType"
                  id="idType"
                />
                {formik.touched.idType && formik.errors.idType ? (
                  <div className="text-red-500 text-sm">{formik.errors.idType}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Identification Number</label>
                <input
                  type="text"
                  placeholder="ID Number"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                  name="idNum"
                  onChange={formik.handleChange}
                  value={formik.values.idNum}
                />
                {formik.touched.idNum && formik.errors.idNum ? (
                  <div className="text-red-500 text-sm">{formik.errors.idNum}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Date of Birth</label>
                <DatePicker value={formik.values.dob} onChange={(date) => formik.setFieldValue('dob', date)} />
                {formik.touched.dob && formik.errors.dob ? (
                  <div className="text-red-500 text-sm">{formik.errors.dob}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Personal Email</label>
                <input
                  type="text"
                  placeholder="Personal Email"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                  name="personalEmail"
                  onChange={formik.handleChange}
                  value={formik.values.personalEmail}
                />
                {formik.touched.personalEmail && formik.errors.personalEmail ? (
                  <div className="text-red-500 text-sm">{formik.errors.personalEmail}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Mobile</label>
                <div className="flex flex-row">
                  <CountryCodeDropdown
                    value={formik.values.areaCode}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('areaCode', selectedValue.value);
                    }}
                    isClearable={false}
                    name="areaCode"
                    id="areaCode"
                  />
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    className="w-full rounded-e-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="mobile"
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                  />
                </div>
                {formik.touched.areaCode && formik.errors.areaCode ? (
                  <div className="text-red-500 text-sm">{formik.errors.areaCode}</div>
                ) : null}
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Home</label>
                <div className="flex flex-row">
                  <CountryCodeDropdown
                    value={formik.values.areaCodeContact}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('areaCodeContact', selectedValue.value);
                    }}
                    isClearable={false}
                    name="areaCodeContact"
                    id="areaCodeContact"
                  />
                  <input
                    type="text"
                    placeholder="Home Number"
                    className="w-full rounded-e-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="contact"
                    onChange={formik.handleChange}
                    value={formik.values.contact}
                  />
                </div>
                {formik.touched.areaCodeContact && formik.errors.areaCodeContact ? (
                  <div className="text-red-500 text-sm">{formik.errors.areaCodeContact}</div>
                ) : null}
                {formik.touched.contact && formik.errors.contact ? (
                  <div className="text-red-500 text-sm">{formik.errors.contact}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Religion</label>
                <Dropdown
                  value={religionOptions.find((option) => option.value === formik.values.religion)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('religion', selectedValue.value);
                  }}
                  options={religionOptions}
                  isClearable={false}
                  placeholder="Select Religion"
                  name="religion"
                  id="religion"
                />
                {formik.touched.religion && formik.errors.religion ? (
                  <div className="text-red-500 text-sm">{formik.errors.religion}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Race</label>
                <Dropdown
                  value={raceOptions.find((option) => option.value === formik.values.race)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('race', selectedValue.value);
                  }}
                  options={raceOptions}
                  isClearable={false}
                  placeholder="Select Race"
                  name="race"
                  id="race"
                />
                {formik.touched.race && formik.errors.race ? (
                  <div className="text-red-500 text-sm">{formik.errors.race}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Nationality</label>
                <Dropdown
                  value={countryOptions.find((option) => option.value === formik.values.nationality)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('nationality', selectedValue.value);
                  }}
                  options={countryOptions}
                  isClearable={false}
                  placeholder="Select Nationality"
                  name="nationality"
                  id="nationality"
                />
                {formik.touched.nationality && formik.errors.nationality ? (
                  <div className="text-red-500 text-sm">{formik.errors.nationality}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Gender</label>
                <Dropdown
                  value={genderOptions.find((option) => option.value === formik.values.gender)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('gender', selectedValue.value);
                  }}
                  options={genderOptions}
                  isClearable={false}
                  placeholder="Select Gender"
                  name="gender"
                  id="gender"
                />
                {formik.touched.gender && formik.errors.gender ? (
                  <div className="text-red-500 text-sm">{formik.errors.gender}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Marital Status</label>
                <Dropdown
                  value={maritalStatusOptions.find((option) => option.value === formik.values.maritalStatus)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('maritalStatus', selectedValue.value);
                  }}
                  options={maritalStatusOptions}
                  isClearable={false}
                  isSearchable={false}
                  placeholder="Select Marital Status"
                  name="maritalStatus"
                  id="maritalStatus"
                />
                {formik.touched.maritalStatus && formik.errors.maritalStatus ? (
                  <div className="text-red-500 text-sm">{formik.errors.maritalStatus}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Citizenship</label>
                <Dropdown
                  value={citizenshipTypeOptions.find((option) => option.value === formik.values.citizenship)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) {
                      formik.setFieldValue('citizenship', selectedValue.value);
                      formik.setFieldValue('prObtained', '');
                      formik.setFieldValue('reEntryExpiration', '');
                      formik.setFieldValue('workPassObtained', '');
                      formik.setFieldValue('workPassExpiration', '');
                    }
                  }}
                  options={citizenshipTypeOptions}
                  isClearable={false}
                  placeholder="Select Citizenship"
                  name="citizenship"
                  id="citizenship"
                />
                {formik.touched.citizenship && formik.errors.citizenship ? (
                  <div className="text-red-500 text-sm">{formik.errors.citizenship}</div>
                ) : null}
              </div>
            </div>
            {formik.values.citizenship === 'PR' && (
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">PR Obtain Date</label>
                  <DatePicker
                    value={formik.values.prObtained}
                    onChange={(date) => formik.setFieldValue('prObtained', date)}
                  />
                  {formik.touched.prObtained && formik.errors.prObtained ? (
                    <div className="text-red-500 text-sm">{formik.errors.prObtained}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Re-Entry Pass Expiration</label>
                  <DatePicker
                    value={formik.values.reEntryExpiration}
                    onChange={(date) => formik.setFieldValue('reEntryExpiration', date)}
                  />
                  {formik.touched.reEntryExpiration && formik.errors.reEntryExpiration ? (
                    <div className="text-red-500 text-sm">{formik.errors.reEntryExpiration}</div>
                  ) : null}
                </div>
              </div>
            )}
            {formik.values.citizenship === 'Foreigner' && (
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Work Pass Type</label>
                  <Dropdown
                    value={workPassOptions.find((option) => option.value === formik.values.workPassType)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('workPassType', selectedValue.value);
                    }}
                    options={workPassOptions}
                    isClearable={false}
                    placeholder="Select Work Pass Type"
                    name="workPassType"
                    id="workPassType"
                  />
                  {formik.touched.workPassType && formik.errors.workPassType ? (
                    <div className="text-red-500 text-sm">{formik.errors.workPassType}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2"></div>
              </div>
            )}
            {formik.values.citizenship === 'Foreigner' && (
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Work Pass Obtained Date</label>
                  <DatePicker
                    value={formik.values.workPassObtained}
                    onChange={(date) => formik.setFieldValue('workPassObtained', date)}
                  />
                  {formik.touched.workPassObtained && formik.errors.workPassObtained ? (
                    <div className="text-red-500 text-sm">{formik.errors.workPassObtained}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Work Pass Expiration Date</label>
                  <DatePicker
                    value={formik.values.workPassExpiration}
                    onChange={(date) => formik.setFieldValue('workPassExpiration', date)}
                  />
                  {formik.touched.workPassExpiration && formik.errors.workPassExpiration ? (
                    <div className="text-red-500 text-sm">{formik.errors.workPassExpiration}</div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="mb-4">
              <div className="w-full">
                <label className="mb-2.5 block text-black font-bold">Address</label>
                <input
                  type="text"
                  placeholder="Address"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className="text-red-500 text-sm">{formik.errors.address}</div>
                ) : null}
              </div>
            </div>

            <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Country</label>
                <Dropdown
                  value={countryOptions.find((option) => option.value === formik.values.country)}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    if (selectedValue) formik.setFieldValue('country', selectedValue.value);
                  }}
                  options={countryOptions}
                  isClearable={false}
                  placeholder="Select Country"
                  name="country"
                  id="country"
                  menuPlacement="top"
                />
                {formik.touched.country && formik.errors.country ? (
                  <div className="text-red-500 text-sm">{formik.errors.country}</div>
                ) : null}
              </div>
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Postal Code</label>
                <input
                  type="text"
                  placeholder="Postal Code"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                  name="postal"
                  onChange={formik.handleChange}
                  value={formik.values.postal}
                />
                {formik.touched.postal && formik.errors.postal ? (
                  <div className="text-red-500 text-sm">{formik.errors.postal}</div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end w-full gap-x-2">
              <button
                type="button"
                onClick={() => navigate('/employees')}
                className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex w-28 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddEmployee;
