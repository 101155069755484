export const employeeDetailsMapping = {
  "Salutation": "salutation",
  "Full Name": "fullName",
  "Email": "email",
  "Identification Type": "idType",
  "Identification Number": "idNum",
  "Date of Birth": "dob",
  "Personal Email": "personalEmail",
  "Mobile Area Code": "areaCode",
  "Mobile": "mobile",
  "Home Area Code": "areaCodeContact",
  "Home ": "contact",
  "Religion": "religion",
  "Race": "race",
  "Nationality": "nationality",
  "Gender": "gender",
  "Marital Status": "maritalStatus",
  "Citizenship": "citizenship",
  "Address": "address",
  "Country": "country",
  "Postal Code": "postal",
  "Work Pass Type": "workPassType",
  "Work Pass Obtained Date": "workPassObtained",
  "Work Pass Expiration Date": "workPassExpiration",
  "PR Obtain Date": "prObtained",
  "Re-Entry Pass Expiration": "reEntryExpiration",
  "Account Number": "bankAccountNum",
  "Bank": "bank",
  "Basic Salary($)": "basicSalary",
  "Currency": "currency",
  "Employee CPF($}": "employeeCPF",
  "Employee CPF(%)": "employeeCPFPercentage",
  "Employer CPF($}": "employerCPF",
  "Employer CPF(%)": "employerCPFPercentage",
  "Gross Salary($)": "grossSalary",
  "Hourly Rate($)": "hourlyRate",
  "Payment Method": "payMethod",
  "Working Hours Type": "workHoursType",
  "is CPF Submission?": "cpf",
  "is OT (Overtime) Applicable?": "ot",
};