import axios from 'axios';
import { ToastBar } from '../components/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_GET_OTP_DATA_SUCCESS = 'FETCH_GET_OTP_DATA_SUCCESS';

export const getOtpData = (data) => ({
  type: FETCH_GET_OTP_DATA_SUCCESS,
  payload: data
});

export const emailCheck = (email) => {
  return async () => {
    try {
      const response = await axios.get(`${apiUrl}/user/check-email-exist`, {
        params: { email: email }
      });

      if (response.status === 200) {
        console.log('check res: ', response.data);
        return response.data?.exist;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        return false;
      } else {
        console.log(error.message);
        return error.response?.data?.exist;
      }
    }
  };
};

export const getForgetPasswordOTP = (values, navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/create-forgot-password-otp`, values);

      if (response.status === 200) {
        console.log('res: ', response);
        console.log('check res: ', response.data);
        dispatch(getOtpData(response.data));
        if (navigate) navigate('/forgot-password-verify', { state: { email: values?.email } });
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const verifyForgetPasswordOTP = (values, navigate, setLoading) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/verify-employee-otp`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        setLoading(false);
        navigate('/create-new-password', { state: { email: values?.email } });
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0])
        setLoading(false);
      } else {
        // Handle other errors
        console.log(error.message);
        setLoading(false);
      }
      return { error: error.message };
    }
  };
};

export const createNewPassword = (values, navigate, setLoading) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/update-password`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        setLoading(false)
        navigate('/login');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};
