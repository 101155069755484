import { useEffect, useState, useMemo } from 'react';
import {
  getPendingLeavesByApprover,
  approveLeaveByApprover,
  rejectLeaveByApprover
} from '../../../../actions/employeeAction';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import empty from '../../../../assets/images/empty.png';
import { format } from 'date-fns';
import { ApproveLeaveModal } from './approveLeaveModal';
import { RejectLeaveModal } from './rejectLeaveModal';
import ManagerLayout from '../../../../layout/managerLayout';
import defaultPic from '../../../../assets/images/Default_profilepic.png';

const ManagerLeaveApplications = () => {
  const dispatch = useDispatch();
  const pendingLeaves = useSelector((state) => state?.employee?.pendingLeaves) || [];

  const [sortConfig, setSortConfig] = useState({
    key: 'employee.fullName',
    direction: 'ascending'
  });
  const [selectedLeaves, setSelectedLeaves] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  useEffect(() => {
    dispatch(getPendingLeavesByApprover()); //userId can get from token, no need input.
  }, [dispatch]);

  const sortedPendingLeaves = useMemo(() => {
    if (!pendingLeaves.length) {
      return [];
    }

    let sortableLeaves = pendingLeaves.flatMap((leave) =>
      leave.pendingLeaves.map((pendingLeave) => ({
        ...pendingLeave,
        employee: leave.employee
      }))
    );

    sortableLeaves.sort((a, b) => {
      const fieldA = sortConfig.key.split('.').reduce((obj, key) => obj[key], a);
      const fieldB = sortConfig.key.split('.').reduce((obj, key) => obj[key], b);

      const valueA = typeof fieldA === 'string' ? fieldA.toLowerCase() : String(fieldA || '');
      const valueB = typeof fieldB === 'string' ? fieldB.toLowerCase() : String(fieldB || '');

      if (sortConfig.direction === 'ascending') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    return sortableLeaves;
  }, [pendingLeaves, sortConfig]);

  useEffect(() => {
    if (selectedLeaves.length === sortedPendingLeaves.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedLeaves, sortedPendingLeaves]);

  const handleCheckboxChange = (leave) => {
    setSelectedLeaves((prevSelected) =>
      prevSelected.includes(leave) ? prevSelected.filter((selected) => selected !== leave) : [...prevSelected, leave]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedLeaves([]);
    } else {
      setSelectedLeaves(sortedPendingLeaves);
    }
    setSelectAll(!selectAll);
  };

  const handleApproveLeave = () => {
    setShowApproveModal(true);
  };

  const handleRejectLeave = () => {
    setShowRejectModal(true);
  };

  const confirmApproveLeave = () => {
    console.log(selectedLeaves);
    dispatch(approveLeaveByApprover(selectedLeaves));
    setShowApproveModal(false);
    setSelectedLeaves([]);
    setSelectAll(false);
  };

  const confirmRejectLeave = () => {
    console.log(selectedLeaves);
    dispatch(rejectLeaveByApprover(selectedLeaves));
    setShowRejectModal(false);
    setSelectedLeaves([]);
    setSelectAll(false);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'Approved':
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 'Pending':
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 'Cancelled':
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedPendingLeaves.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedPendingLeaves.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  console.log('currentEntries: ', currentEntries)

  return (
    <ManagerLayout pageTitle={'Pending Leaves'} page={'Employees'} subPage={'Pending Leaves'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          <div className="flex flex-row justify-end items-end gap-x-3 pb-5">
            <button
              type="button"
              onClick={handleApproveLeave}
              className="h-12 w-36 bg-primary hover:bg-primary/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
              disabled={selectedLeaves.length === 0 || sortedPendingLeaves.length < 0}
            >
              Approve Leave
            </button>
            <button
              type="button"
              onClick={handleRejectLeave}
              className="h-12 w-36 bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
              disabled={selectedLeaves.length === 0 || sortedPendingLeaves.length < 0}
            >
              Reject Leave
            </button>
          </div>

          {sortedPendingLeaves.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th>
                    <th className="h-20 text-center w-16">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name
                      <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('leaveType')} className="cursor-pointer">
                      Leave Type
                      <FontAwesomeIcon icon={getSortIcon('leaveType')} size="sm" className="ml-1" />
                    </th>
                    {/* <th onClick={() => handleSort('leaveCode')} className="cursor-pointer">
                      Leave Code
                      <FontAwesomeIcon icon={getSortIcon('leaveCode')} size="sm" className="ml-1" />
                    </th> */}
                    <th onClick={() => handleSort('fromDate')} className="cursor-pointer">
                      From Date
                      <FontAwesomeIcon icon={getSortIcon('fromDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('toDate')} className="cursor-pointer">
                      To Date
                      <FontAwesomeIcon icon={getSortIcon('toDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('numberOfDays')} className="cursor-pointer">
                      Num of Days
                      <FontAwesomeIcon icon={getSortIcon('numberOfDays')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('appliedDate')} className="cursor-pointer">
                      Applied Date
                      <FontAwesomeIcon icon={getSortIcon('appliedDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4 rounded-e-xl">
                      Status
                      <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1 " />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((leave, index) => {
                    const formattedFromDate = !Number.isNaN(new Date(leave.fromDate).getTime())
                      ? format(new Date(leave.fromDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedToDate = !Number.isNaN(new Date(leave.toDate).getTime())
                      ? format(new Date(leave.toDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedAppliedDate = !Number.isNaN(new Date(leave.appliedDate).getTime())
                      ? format(new Date(leave.appliedDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date';

                    const { label, bgColor, textColor } = getStatusLabel(leave.status);

                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedLeaves.includes(leave)}
                            onChange={() => handleCheckboxChange(leave)}
                          />
                        </td>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={leave?.employee?.avatar ? leave?.employee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center" title={leave?.employee.fullName}>
                          {leave?.employee?.fullName}
                          {/* {leave?.employee?.fullName?.slice(0, 12)}
                          {leave?.employee?.fullName?.length > 12 ? '...' : ''} */}
                        </td>
                        <td className="h-20 text-center">{leave?.leaveType}</td>
                        {/* <td className="h-20 text-center">{leave.leaveCode}</td> */}
                        <td className="h-20 text-center">{formattedFromDate}</td>
                        <td className="h-20 text-center">{formattedToDate}</td>
                        <td className="h-20 text-center">{leave.numberOfDays}</td>
                        <td className="h-20 text-center">{formattedAppliedDate}</td>
                        <td className="h-20 w-24">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Pending Leaves</div>
            </div>
          )}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedPendingLeaves.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}

        {showApproveModal && (
          <ApproveLeaveModal onCancel={() => setShowApproveModal(false)} onConfirm={confirmApproveLeave} />
        )}
        {showRejectModal && (
          <RejectLeaveModal onCancel={() => setShowRejectModal(false)} onConfirm={confirmRejectLeave} />
        )}
      </div>
    </ManagerLayout>
  );
};

export default ManagerLeaveApplications;
