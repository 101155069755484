import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateDesignation } from '../../../actions/companyAction';
import { designationSchema } from '../../../validations/validationSchemas';

const EditDesignation = ({ company, designation, onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      _id: designation?._id,
      designationName: designation?.designationName || '',
      designationCode: designation?.designationCode || ''
    },
    validationSchema: designationSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(updateDesignation(values, onClose));
    }
  });

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Designation name</label>
              <input
                type="text"
                placeholder="Designation Name"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="designationName"
                onChange={formik.handleChange}
                value={formik.values.designationName}
              />
              {formik.touched.designationName && formik.errors.designationName ? (
                <div className="text-red-500 text-sm">{formik.errors.designationName}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Designation Code</label>
              <input
                type="text"
                placeholder="Designation Code"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="designationCode"
                onChange={formik.handleChange}
                value={formik.values.designationCode}
              />
              {formik.touched.designationCode && formik.errors.designationCode ? (
                <div className="text-red-500 text-sm">{formik.errors.designationCode}</div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditDesignation;
