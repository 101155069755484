import { Calendar1, Document } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import DateDropdown from '../../../components/dateDropdown';
import { useEffect, useState } from 'react';
import { getPayslips } from '../../../actions/payrollAction';
import { useNavigate } from 'react-router-dom';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import CompanyDropdown from '../../../components/companyDropdown';

const InitialPayslip = ({ setClickPayslips, year, setYear, setLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentDate = new Date();
  const [yearOptions, setYearOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  // const [year, setYear] = useState(currentDate.getFullYear());

  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  useEffect(() => {
    setYearOptions(generateYearOptions());
  }, []);

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption.value);
  };

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 4; i++) {
      const year = currentYear - i;
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  const handleGeneratePayslip = () => {
    const inputValue = {
      year: year,
      companyId: company?._id
    };
    if (year) {
      setLoader(true);
      dispatch(getPayslips(inputValue, setLoader));
      setClickPayslips(true);
    }
  };
  return (
    <div>
      <div className="w-full lg:w-1/3 mb-4">
        <label className="mb-2.5 block text-black font-bold">Select Company</label>
        {companyOptions.length > 0 && (
          <CompanyDropdown
            value={companyOptions.find((option) => option.value === company?.companyName)}
            onChange={handleCompanyChange}
            options={companyOptions}
            isClearable={false}
            placeholder="Select Company"
            name="allowanceType"
            id="allowanceType"
          />
        )}
      </div>
      <div className="bg-white p-6 rounded-2xl">
        <div className="flex flex-wrap justify-between gap-3">
          <div className="flex gap-x-5 items-center">
            <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
              <Calendar1 color="#3D54DD" variant="Broken" size={25} />
            </div>
            <div>
              <div className="text-black font-medium text-xs">Salary period</div>
              <div className="flex flex-wrap gap-x-5 text-sm font-bold">
                <div>
                  {yearOptions.length > 0 && (
                    <DateDropdown
                      value={yearOptions.find((option) => option.value === year)}
                      onChange={handleYearChange}
                      options={yearOptions}
                      isClearable={false}
                      placeholder="Select Year"
                      name="year"
                      id="year"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div></div> */}
          <button
            type="button"
            disabled={!company?._id}
            onClick={() => handleGeneratePayslip()}
            className="bg-purple1 disabled:bg-gray-500 hover:bg-purple1/90 text-white font-bold rounded-xl px-6 h-14 text-right flex items-center gap-x-3"
          >
            <Document color="#ffffff" variant="Outline" size={25} />
            <span>Payslips</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialPayslip;
