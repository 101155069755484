import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Compressor from 'compressorjs';
import ImageCropper from './imageCrop/imageCropper';
import { editCompanyProfilePic } from '../actions/companyAction';
import { editEmployeeProfilePic } from '../actions/employeeAction';
import { ToastBar } from './toastbar';

const ProfileImageUploadModal = ({ closeModal, userRole, employeeId, companyId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const employer = useSelector((state) => state.employer);

  console.log('user: ', user);
  console.log('employer: ', employer);

  const handleCropComplete = async (imageData) => {
    try {
      console.log('imageData: ', imageData);
      const response = await fetch(imageData);
      const blob = await response.blob();

      console.log('Original blob size:', blob.size);

      // Compress the image using CompressorJS
      new Compressor(blob, {
        quality: 0.6, // Adjust this value for more or less compression
        maxWidth: 1920, // Adjust this if you want to limit the max width
        success(compressedBlob) {
          console.log('Compressed blob size:', compressedBlob.size);

          // Check if file size exceeds 2MB (2 * 1024 * 1024 bytes)
          const fileSizeMB = compressedBlob.size / (1024 * 1024);
          if (fileSizeMB > 2) {
            ToastBar.warning("File Size Exceeds 2MB");
            return;
          }

          const formData = new FormData();
          formData.append('file', compressedBlob, `profilePic_${user?.id}`);

          if (userRole === 'company') {
            dispatch(editCompanyProfilePic(formData, closeModal));
          } else if (userRole === 'employee') {
            formData.append('employeeId', employeeId);
            dispatch(editEmployeeProfilePic(formData, closeModal, companyId, employeeId));
          }
        },
        error(err) {
          console.error('Compression error:', err);
          ToastBar.error('Failed to compress the image.');
        },
      });
    } catch (error) {
      console.error('Error in fetching or compressing image:', error);
      ToastBar.error('Failed to upload the image.');
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999] w-full">
        <div className="bg-white w-2/3 px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold mb-2 text-purple1">Edit Picture</h2>
            <button
              type="button"
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <ImageCropper croppedPicDataHandling={handleCropComplete} />
        </div>
      </div>
    </>
  );
};

export default ProfileImageUploadModal;
