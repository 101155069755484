import React, { useState, useEffect } from 'react';

const CustomTimePicker = ({ startTime, endTime, onTimeChange }) => {
  const [startHours, setStartHours] = useState(startTime ? startTime.hours : '12');
  const [startMinutes, setStartMinutes] = useState(startTime ? startTime.minutes : '00');
  const [startPeriod, setStartPeriod] = useState(startTime ? startTime.period : 'AM');

  const [endHours, setEndHours] = useState(endTime ? endTime.hours : '12');
  const [endMinutes, setEndMinutes] = useState(endTime ? endTime.minutes : '00');
  const [endPeriod, setEndPeriod] = useState(endTime ? endTime.period : 'AM');

  const [duration, setDuration] = useState(null);

  // Effect to notify parent of time changes and calculate duration
  useEffect(() => {
    if (onTimeChange) {
      const startInMinutes = convertToMinutes(startHours, startMinutes, startPeriod);
      const endInMinutes = convertToMinutes(endHours, endMinutes, endPeriod);
      const timeDifference = calculateDuration(startInMinutes, endInMinutes);

      setDuration(timeDifference);
      onTimeChange(
        { hours: startHours, minutes: startMinutes, period: startPeriod },
        { hours: endHours, minutes: endMinutes, period: endPeriod },
        timeDifference
      );
    }
  }, [startHours, startMinutes, startPeriod, endHours, endMinutes, endPeriod]);

  // Handlers for start time
  const handleStartHoursChange = (e) => setStartHours(e.target.value);
  const handleStartMinutesChange = (e) => setStartMinutes(e.target.value);
  const handleStartPeriodChange = (e) => setStartPeriod(e.target.value);

  // Handlers for end time
  const handleEndHoursChange = (e) => setEndHours(e.target.value);
  const handleEndMinutesChange = (e) => setEndMinutes(e.target.value);
  const handleEndPeriodChange = (e) => setEndPeriod(e.target.value);

  // Function to convert hours, minutes, and period to total minutes from midnight
  const convertToMinutes = (hours, minutes, period) => {
    let totalMinutes = (parseInt(hours) % 12) * 60 + parseInt(minutes);
    if (period === 'PM') {
      totalMinutes += 12 * 60; // Add 12 hours for PM times
    }
    return totalMinutes;
  };

  // Function to calculate duration in hours and minutes
  const calculateDuration = (startMinutes, endMinutes) => {
    let difference = endMinutes - startMinutes;
    if (difference < 0) {
      difference += 24 * 60; // Adjust for overnight time spans
    }

    const hours = Math.floor(difference / 60);
    const minutes = difference % 60;

    return { hours, minutes };
  };

  return (
    <div className="flex flex-row flex-wrap gap-2 items-center">
      {/* Start Time Picker */}
      <div className="flex flex-col gap-x-1 text-xs text-black bg-grayDark p-1 rounded-lg">
        <span className="text-xs font-semibold">Start Time</span>
        <div className="flex flex-row gap-x-1">
          <select value={startHours} onChange={handleStartHoursChange} className="rounded-sm">
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
                {i + 1 < 10 ? `0${i + 1}` : i + 1}
              </option>
            ))}
          </select>
          <span>:</span>
          <select value={startMinutes} onChange={handleStartMinutesChange} className="rounded-sm">
            {Array.from({ length: 60 }, (_, i) => (
              <option key={i} value={i < 10 ? `0${i}` : i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </select>
          <select value={startPeriod} onChange={handleStartPeriodChange} className="rounded-sm">
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
      <span className="font-bold">-</span>
      {/* End Time Picker */}
      <div className="flex flex-col gap-x-1 text-xs text-black bg-grayDark p-1 rounded-lg">
        <span className="text-xs font-semibold">End Time</span>
        <div className="flex flex-row gap-x-1">
          <select value={endHours} onChange={handleEndHoursChange} className="rounded-sm">
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
                {i + 1 < 10 ? `0${i + 1}` : i + 1}
              </option>
            ))}
          </select>
          <span>:</span>
          <select value={endMinutes} onChange={handleEndMinutesChange} className="rounded-sm">
            {Array.from({ length: 60 }, (_, i) => (
              <option key={i} value={i < 10 ? `0${i}` : i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </select>
          <select value={endPeriod} onChange={handleEndPeriodChange} className="rounded-sm">
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
      {/* 
      {duration && (
        <div className="text-sm font-semibold text-gray-700">
          Duration: {duration.hours} hours and {duration.minutes} minutes
        </div>
      )} */}
    </div>
  );
};

export default CustomTimePicker;

// import React, { useState } from 'react';

// const CustomTimePicker = () => {
//   const [hours, setHours] = useState('12');
//   const [minutes, setMinutes] = useState('00');
//   const [period, setPeriod] = useState('AM');

//   const handleHoursChange = (e) => {
//     setHours(e.target.value);
//   };

//   const handleMinutesChange = (e) => {
//     setMinutes(e.target.value);
//   };

//   const handlePeriodChange = (e) => {
//     setPeriod(e.target.value);
//   };

//   return (
//     <div className='flex gap-x-1 text-sm text-black'>
//       <select value={hours} onChange={handleHoursChange} className='rounded'>
//         {Array.from({ length: 12 }, (_, i) => (
//           <option key={i} value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
//             {i + 1 < 10 ? `0${i + 1}` : i + 1}
//           </option>
//         ))}
//       </select>

//       <span>:</span>

//       <select value={minutes} onChange={handleMinutesChange} className='rounded'>
//         {Array.from({ length: 60 }, (_, i) => (
//           <option key={i} value={i < 10 ? `0${i}` : i}>
//             {i < 10 ? `0${i}` : i}
//           </option>
//         ))}
//       </select>

//       <select value={period} onChange={handlePeriodChange} className='rounded'>
//         <option value="AM">AM</option>
//         <option value="PM">PM</option>
//       </select>
//     </div>
//   );
// };

// export default CustomTimePicker;
