import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Dropdown from '../../../components/dropdown';
import { bankOptions, currencyOptions, paymentMethodOptions } from '../../../constant/dropdownConstOptions';
import { editSalaryDetails } from '../../../actions/employeeAction';
import { employeePayDetailSchema } from '../../../validations/validationSchemas';
import { Edit2 } from 'iconsax-react';

const PaySchemeDetails = ({ employee }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      _id: employee?._id,
      companyId: employee?.company?._id,
      payMethod: employee?.salaryDetails?.payMethod || 'Net Banking',
      currency: employee?.salaryDetails?.currency || 'SGD',
      basicSalary: employee?.salaryDetails?.basicSalary?.toFixed(2) || 0,
      grossSalary: employee?.salaryDetails?.grossSalary?.toFixed(2) || 0,
      hourlyRate: employee?.salaryDetails?.grossSalary?.toFixed(2) || 0,
      employeeCPFPercentage: employee?.salaryDetails?.employeeCPFPercentage || 20,
      employerCPFPercentage: employee?.salaryDetails?.employerCPFPercentage || 17,
      employeeCPF: employee?.salaryDetails?.employeeCPF || 0,
      employerCPF: employee?.salaryDetails?.employerCPF || 0,
      totalCPFContribution: employee?.totalCPFContribution || 0,
      bank: employee?.salaryDetails?.bank || '',
      bankAccountNum: employee?.salaryDetails?.bankAccountNum || '',
      ot: employee?.salaryDetails?.ot || 0,
      cpf: employee?.salaryDetails?.cpf || 1
    },
    validationSchema: employeePayDetailSchema,
    onSubmit: async (values) => {
      console.log('edit employment: ', values);
      dispatch(editSalaryDetails(values, setEdit));
    }
  });

  useEffect(() => {
    if (employee?.workingHourDetails?.workHoursType === 'Fixed') {
      const employeeCPF = ((formik.values.basicSalary * formik.values.employeeCPFPercentage) / 100).toFixed(2);
      formik.setFieldValue('employeeCPF', employeeCPF);

      const employerCPF = ((formik.values.basicSalary * formik.values.employerCPFPercentage) / 100).toFixed(2);
      formik.setFieldValue('employerCPF', employerCPF);

      const totalCPFContribution = (parseFloat(employerCPF) + parseFloat(employeeCPF)).toFixed(2);
      formik.setFieldValue('totalCPFContribution', totalCPFContribution);

      const grossSalary = (parseFloat(formik.values.basicSalary) - parseFloat(employeeCPF)).toFixed(2);
      formik.setFieldValue('grossSalary', grossSalary);
      formik.setFieldValue('hourlyRate', null);
    } else {
      const employeeCPF = ((formik.values.hourlyRate * formik.values.employeeCPFPercentage) / 100).toFixed(2);
      formik.setFieldValue('employeeCPF', employeeCPF);

      const employerCPF = ((formik.values.hourlyRate * formik.values.employerCPFPercentage) / 100).toFixed(2);
      formik.setFieldValue('employerCPF', employerCPF);

      const totalCPFContribution = (parseFloat(employerCPF) + parseFloat(employeeCPF)).toFixed(2);
      formik.setFieldValue('totalCPFContribution', totalCPFContribution);

      const grossSalary = (parseFloat(formik.values.hourlyRate) - parseFloat(employeeCPF)).toFixed(2);
      formik.setFieldValue('grossSalary', grossSalary);
      formik.setFieldValue('basicSalary', null);
    }
  }, [
    formik.values.basicSalary,
    formik.values.employeeCPFPercentage,
    formik.values.employerCPFPercentage,
    formik.values.hourlyRate
  ]);

  useEffect(() => {
    if (formik.values.cpf === 0) {
      formik.setFieldValue('employeeCPFPercentage', null);
      formik.setFieldValue('employerCPFPercentage', null);
      formik.setFieldValue('employeeCPF', null);
      formik.setFieldValue('employerCPF', null);
    } else if (formik.values.cpf === 1) {
      formik.setFieldValue('employeeCPFPercentage', 20);
      formik.setFieldValue('employerCPFPercentage', 17);
      formik.setFieldValue('employeeCPF', 0);
      formik.setFieldValue('employerCPF', 0);
    }
  }, [formik.values.cpf]);

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  // console.log('formik: ', formik.values);
  // console.log('formik errors: ', formik.errors);

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-start">
          <div className="font-bold text-black text-2xl">Salary Details</div>
        </div>
        <div className="p-6 relative">
          <div className="w-full lg:w-1/2">
            <div className="text-sm text-black/40 font-bold">Payment Method</div>
            <div className="text-base text-black font-normal mb-3">{employee?.salaryDetails?.payMethod}</div>
            <div className="text-sm text-black/40 font-bold">Currency</div>
            <div className="text-base text-black font-normal mb-3">{employee?.salaryDetails?.currency} </div>
            <div className="text-sm text-black/40 font-bold">Salary</div>
            <div className="text-base text-black font-normal mb-3">
              {employee?.workingHourDetails?.workHoursType === 'Fixed' &&
                new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(employee?.salaryDetails?.basicSalary)}
              {employee?.workingHourDetails?.workHoursType === 'Hourly' &&
                new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(employee?.salaryDetails?.hourlyRate)}
            </div>
            <div className="text-sm text-black/40 font-bold">Bank</div>
            <div className="text-base text-black font-normal mb-3">{employee?.salaryDetails?.bank} </div>
            <div className="text-sm text-black/40 font-bold">Bank Account Number</div>
            <div className="text-base text-black font-normal mb-3">{employee?.salaryDetails?.bankAccountNum} </div>
          </div>
          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-4 top-6 hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#000000" variant="Bold" size={20} />
            </button>
          )}
        </div>
      </div>
      {edit && (
        <div className="rounded-xl bg-white shadow-lg w-full">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Payment Method</label>
                  <Dropdown
                    value={paymentMethodOptions.find((option) => option.value === formik.values.payMethod)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) {
                        formik.setFieldValue('payMethod', selectedValue.value);
                        formik.setFieldValue('bank', '');
                        formik.setFieldValue('bankAccountNum', '');
                      }
                    }}
                    options={paymentMethodOptions}
                    isClearable={false}
                    placeholder="Select Payment Method"
                    name="payMethod"
                    id="payMethod"
                  />
                  {formik.touched.payMethod && formik.errors.payMethod ? (
                    <div className="text-red-500 text-sm">{formik.errors.payMethod}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Currency</label>
                  <Dropdown
                    value={currencyOptions.find((option) => option.value === formik.values.currency)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('currency', selectedValue.value);
                    }}
                    options={currencyOptions}
                    isClearable={false}
                    placeholder="Select Currency"
                    name="currency"
                    id="currency"
                  />
                  {formik.touched.currency && formik.errors.currency ? (
                    <div className="text-red-500 text-sm">{formik.errors.currency}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row items-center">
                {employee?.workingHourDetails?.workHoursType === 'Fixed' ? (
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Basic Salary ($)</label>
                    <input
                      type="number"
                      placeholder="Basic Salary"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="basicSalary"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleFloatChange(e.target.value, 'basicSalary')}
                      value={formik.values.basicSalary}
                    />
                    {formik.touched.basicSalary && formik.errors.basicSalary ? (
                      <div className="text-red-500 text-sm">{formik.errors.basicSalary}</div>
                    ) : null}
                  </div>
                ) : (
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Hourly Rate ($)</label>
                    <input
                      type="number"
                      placeholder="Hourly Rate"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="hourlyRate"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleFloatChange(e.target.value, 'hourlyRate')}
                      value={formik.values.hourlyRate}
                    />
                    {formik.touched.hourlyRate && formik.errors.hourlyRate ? (
                      <div className="text-red-500 text-sm">{formik.errors.hourlyRate}</div>
                    ) : null}
                  </div>
                )}
                <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                  <input
                    type="checkbox"
                    name="cpf"
                    className="form-checkbox lg:mt-8 h-4 w-4"
                    value={formik.values.cpf}
                    checked={formik.values.cpf === 1}
                    onChange={(e) => {
                      formik.setFieldValue('cpf', e.target.checked ? 1 : 0);
                    }}
                  />
                  <div className="lg:mt-8 font-xs text-black font-semibold">is CPF Submission?</div>
                </div>
              </div>
              {formik?.values?.cpf === 1 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employee CPF Percentage (%)</label>
                    <input
                      type="number"
                      placeholder="Employee CPF Percentage"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="employeeCPFPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.employeeCPFPercentage}
                    />
                    {formik.touched.employeeCPFPercentage && formik.errors.employeeCPFPercentage ? (
                      <div className="text-red-500 text-sm">{formik.errors.employeeCPFPercentage}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employee CPF ($)</label>
                    <input
                      type="number"
                      placeholder="Employee CPF"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="employeeCPF"
                      onChange={formik.handleChange}
                      value={formik.values.employeeCPF}
                      disabled
                    />
                    {formik.touched.employeeCPF && formik.errors.employeeCPF ? (
                      <div className="text-red-500 text-sm">{formik.errors.employeeCPF}</div>
                    ) : null}
                  </div>
                </div>
              )}
              {formik?.values?.cpf === 1 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employer CPF Percentage (%)</label>
                    <input
                      type="number"
                      placeholder="Employer CPF Percentage"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="employerCPFPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.employerCPFPercentage}
                    />
                    {formik.touched.employerCPFPercentage && formik.errors.employerCPFPercentage ? (
                      <div className="text-red-500 text-sm">{formik.errors.employerCPFPercentage}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employer CPF ($)</label>
                    <input
                      type="number"
                      placeholder="Employer CPF"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="employerCPF"
                      onChange={formik.handleChange}
                      value={formik.values.employerCPF}
                      disabled
                    />
                    {formik.touched.employerCPF && formik.errors.employerCPF ? (
                      <div className="text-red-500 text-sm">{formik.errors.employerCPF}</div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Gross Salary ($)</label>
                  <input
                    type="number"
                    placeholder="Gross Salary"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="grossSalary"
                    onChange={formik.handleChange}
                    value={formik.values.grossSalary}
                    disabled
                  />
                  {formik.touched.grossSalary && formik.errors.grossSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.grossSalary}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                  <input
                    type="checkbox"
                    name="ot"
                    className="form-checkbox lg:mt-8 h-4 w-4"
                    value={formik.values.ot}
                    checked={formik.values.ot === 1}
                    onChange={(e) => {
                      formik.setFieldValue('ot', e.target.checked ? 1 : 0);
                    }}
                  />
                  <div className="lg:mt-8 font-xs text-black font-semibold">is OT (Overtime) Applicable?</div>
                </div>
              </div>
              {formik.values.payMethod === 'Net Banking' && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Bank</label>
                    <Dropdown
                      value={bankOptions.find((option) => option.value === formik.values.bank)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('bank', selectedValue.value);
                      }}
                      options={bankOptions}
                      isClearable={false}
                      isSearchable={false}
                      menuPlacement="top"
                      placeholder="Select Bank"
                      name="bank"
                      id="bank"
                    />
                    {formik.touched.bank && formik.errors.bank ? (
                      <div className="text-red-500 text-sm">{formik.errors.bank}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Account Number</label>
                    <input
                      type="text"
                      placeholder="Enter Account Number"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="bankAccountNum"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountNum}
                    />
                    {formik.touched.bankAccountNum && formik.errors.bankAccountNum ? (
                      <div className="text-red-500 text-sm">{formik.errors.bankAccountNum}</div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="flex justify-end w-full gap-x-2">
                <button
                  type="button"
                  onClick={() => setEdit(false)}
                  className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PaySchemeDetails;
