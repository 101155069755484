import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { employeeDeductionSchema } from '../../../validations/validationSchemas';
import Dropdown from '../../../components/dropdown';
import { frequencyOptions } from '../../../constant/dropdownConstOptions';
import { addDeduction, deleteDeduction } from '../../../actions/employeeAction';
import EditDeduction from './editDeduction';
import { Add, Edit2, Trash } from 'iconsax-react';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';

const DeductionDetails = ({ employee, company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const deductionOptions =
    company?.deductions?.map((deduction) => ({
      label: `${deduction?.deductionType} - ${deduction?.deductionCode}`,
      value: deduction?.deductionType,
      deductionType: deduction?.deductionType,
      deductionCode: deduction?.deductionCode,
      deductionId: deduction?._id
    })) || [];

  const formik = useFormik({
    initialValues: {
      companyId: employee?.company?._id,
      _id: employee?._id,
      deductionId: '',
      deductionType: '',
      deductionCode: '',
      amount: '',
      frequency: 'Monthly'
    },
    validationSchema: employeeDeductionSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addDeduction(values, setEdit, resetForm));
    }
  });

  const handleModalDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleDelete = (deduction) => {
    dispatch(
      deleteDeduction(
        {
          _id: deduction?._id,
          employeeId: employee?._id,
          companyId: employee?.company?._id,
        },
        setIsConfirmationModalOpen
      )
    );
  };

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Deduction Details</h3>
          {!edit && (
            <button
              className="w-16 h-8 rounded-lg flex items-center justify-center text-2xl font-bold bg-grayLight shadow-md"
              onClick={() => setEdit(true)}
            >
              <Add color="#3D54DD" variant="Linear" size={20} />
            </button>
          )}
        </div>
        {edit && (
          <div className="rounded-xl bg-white shadow-lg w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Deduction Type</label>
                    <Dropdown
                      value={deductionOptions.find((option) => option.deductionType === formik.values.deductionType)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) {
                          formik.setValues({
                            ...formik.values,
                            deductionType: selectedValue.value,
                            deductionCode: selectedValue.deductionCode,
                            deductionId: selectedValue.deductionId
                          });
                        }
                      }}
                      options={deductionOptions}
                      isClearable={false}
                      placeholder="Select Deduction"
                      name="deductionType"
                      id="deductionType"
                    />
                    {formik.touched.deductionType && formik.errors.deductionType ? (
                      <div className="text-red-500 text-sm">{formik.errors.deductionType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Amount ({employee?.salaryDetails?.currency})
                    </label>
                    <input
                      type="number"
                      placeholder="Amount"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="amount"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleFloatChange(e.target.value, 'amount')}
                      value={formik.values.amount}
                    />
                    {formik.touched.amount && formik.errors.amount ? (
                      <div className="text-red-500 text-sm">{formik.errors.amount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Deduction Frequency</label>
                    <Dropdown
                      value={frequencyOptions.find((option) => option.value === formik.values.frequency)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('frequency', selectedValue.value);
                      }}
                      options={frequencyOptions}
                      isClearable={false}
                      placeholder="Select Frequency"
                      name="frequency"
                      id="frequency"
                      menuPlacement="top"
                    />
                    {formik.touched.frequency && formik.errors.frequency ? (
                      <div className="text-red-500 text-sm">{formik.errors.frequency}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
                <div className="flex justify-end w-full gap-x-2">
                  <button
                    type="button"
                    onClick={() => setEdit(false)}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-28 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex flex-wrap gap-3 mt-5">
          {employee?.deductionDetails?.map((deduction, index) => {
            return (
              <div
                className={`p-6 relative bg-white shadow-lg flex-grow ${
                  editIndex === index ? 'w-full' : 'basis-[450px]'
                } rounded-2xl`}
                key={index}
              >
                {editIndex !== index && (
                  <div>
                    <div className="text-base text-black font-bold">
                      {deduction?.deductionType} - {deduction?.deductionCode}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Amount:</span> {deduction?.amount}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Frequency:</span> {deduction?.frequency}
                    </div>
                  </div>
                )}

                {editIndex !== index && (
                  <div className="flex flex-row gap-x-1 absolute right-4 top-6">
                    <button
                      onClick={() => setEditIndex(index)}
                      type="button"
                      className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
                    >
                      <Edit2 color="#000000" variant="Bold" size={20} />
                    </button>
                    <button
                      onClick={() => handleModalDelete()}
                      type="button"
                      className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-600"
                    >
                      <Trash color="#C84040" variant="Bold" size={20} />
                    </button>
                    <DeleteConfirmationModal
                      isOpen={isConfirmationModalOpen}
                      onClose={() => setIsConfirmationModalOpen(false)}
                      handleDelete={() => handleDelete(deduction)}
                      title={'deduction'}
                    />
                  </div>
                )}
                {editIndex === index && (
                  <EditDeduction
                    deduction={deduction}
                    onClose={() => setEditIndex(null)}
                    employee={employee}
                    deductionOptions={deductionOptions}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DeductionDetails;
