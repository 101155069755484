import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from './canvasUtils';
import { styles } from './styles';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
};

const ImageCrop = ({ classes, croppedPicDataHandling }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
        img.onerror = reject;
      });
    };

    if (imageSrc) {
      loadImage(imageSrc)
        .then(({ width, height }) => {
          setAspectRatio(width / height);
        })
        .catch(console.error);
    }
  }, [imageSrc]);

  const showCroppedImage = useCallback(async () => {
    try {
      const newCroppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      croppedPicDataHandling(newCroppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onFileChange = useCallback(async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      try {
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation] || 0;
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn('Failed to set image orientation.');
      }

      setImageSrc(imageDataUrl);
    }
  }, []);

  return (
    <div>
      {imageSrc ? (
        <>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <ZoomSlider zoom={zoom} setZoom={setZoom} classes={classes} />
            <RotationSlider rotation={rotation} setRotation={setRotation} classes={classes}/>
            <Button onClick={showCroppedImage} variant="contained" color="primary" className={classes.cropButton}>
              Upload
            </Button>
          </div>
        </>
      ) : (
        <div className="w-full h-56 flex justify-center items-center">
          <input type="file" id="file-input" onChange={onFileChange} accept="image/*" style={{ display: 'none' }} />
          <label htmlFor="file-input" className="bg-gray-700 hover:bg-gray-500 px-5 py-2 text-white font-semibold rounded-lg cursor-pointer">
          Choose Image
          </label>
        </div>
      )}
    </div>
  );
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const ZoomSlider = ({ zoom, setZoom, classes }) => (
  <div className={classes.sliderContainer}>
    <Typography variant="overline" className={`${classes.sliderLabel} w-16`}>
      Zoom
    </Typography>
    <Slider
      value={zoom}
      min={1}
      max={3}
      step={0.1}
      aria-labelledby="Zoom"
      className={classes.slider}
      onChange={(e, newZoom) => setZoom(newZoom)}
    />
  </div>
);

const RotationSlider = ({ rotation, setRotation, classes }) => (
  <div className={classes.sliderContainer}>
    <Typography variant="overline" className={`${classes.sliderLabel} w-20`}>
      Rotation
    </Typography>
    <Slider
      value={rotation}
      min={0}
      max={360}
      step={1}
      aria-labelledby="Rotation"
      className={classes.slider}
      onChange={(e, newRotation) => setRotation(newRotation)}
    />
  </div>
);

const ImageCropper = withStyles(styles)(ImageCrop);

export default ImageCropper;
