import { useFormik } from 'formik';
import { addEmployeeSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import {
  citizenshipTypeOptions,
  genderOptions,
  identificationType,
  maritalStatusOptions,
  raceOptions,
  religionOptions,
  salutationOptions,
  workPassOptions
} from '../../../constant/dropdownConstOptions';
import Dropdown from '../../../components/dropdown';
import DatePicker from '../../../components/DatePicker';
import CountryCodeDropdown from '../../../components/countryCodeDropdown';
import countryOptions from '../../../constant/countries.json';
import { useState } from 'react';
import { editPersonalDetails } from '../../../actions/employeeAction';
import { CallCalling, Camera, Copy, Edit2, Location, Sms } from 'iconsax-react';
import DefaultProfile from '../../../assets/images/Default_profilepic.png';
import ProfileImageUploadModal from '../../../components/profileImageUploadModal';
import { copyToClipboard } from '../../../constant/functions';

const PersonalDetails = ({ employee, company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editProfilePicModal, setEditProfilePicModal] = useState(false);
  const [isMasked, setIsMasked] = useState(true);

  const formik = useFormik({
    initialValues: {
      _id: employee?._id,
      companyId: employee?.company?._id,
      salutation: employee?.salutation || '',
      fullName: employee?.fullName || '',
      idType: employee?.idType || '',
      idNum: employee?.idNum || '',
      personalEmail: employee?.personalEmail || '',
      email: employee?.email || '',
      dob: employee?.dob || '',
      maritalStatus: employee?.maritalStatus || '',
      areaCode: employee?.areaCode || '',
      mobile: employee?.mobile || '',
      areaCodeContact: employee?.areaCodeContact || '',
      contact: employee?.contact || '',
      religion: employee?.religion || '',
      race: employee?.race || '',
      nationality: employee?.nationality || '',
      gender: employee?.gender || '',
      citizenship: employee?.citizenship || '',
      prObtained: employee?.prObtained || '',
      reEntryExpiration: employee?.reEntryExpiration || '',
      workPassType: employee?.workPassType || '',
      workPassObtained: employee?.workPassObtained || '',
      workPassExpiration: employee?.workPassExpiration || '',
      address: employee?.address || '',
      country: employee?.country || '',
      postal: employee?.postal || ''
    },
    validationSchema: addEmployeeSchema,
    onSubmit: async (values) => {
      console.log('edit value: ', values);
      dispatch(editPersonalDetails(values, setEdit));
    }
  });

  const handleMaskToggle = () => {
    setIsMasked(false);
  };

  const handleMaskReapply = () => {
    setIsMasked(true);
  };

  const maskValue = (value) => {
    if (value.length <= 5) return value;
    return value.slice(0, -4).replace(/./g, '*') + value.slice(-4);
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-center">
          <div className="mb-1 relative">
            <img
              src={employee?.avatar || DefaultProfile}
              alt="avatar"
              className="w-20 h-20 rounded-full object-cover"
            />
            <button
              onClick={() => setEditProfilePicModal(true)}
              className="absolute right-0 bottom-0 z-50 w-7 h-7 bg-white hover:bg-grayDark shadow-lg rounded-xl flex justify-center items-center"
            >
              <Camera color="#3D54DD" variant="Outline" size={18} className="cursor-pointer " />
            </button>
          </div>
          <div className="font-bold text-black text-xl">
            {employee?.salutation} {employee?.fullName}
          </div>
          <div className="text-sm font-normal text-black mb-3">
            <span className="font-bold">Citizenship:</span> {employee?.citizenship}
          </div>
          <div className="flex flex-wrap w-full gap-x-10 items-center justify-center">
            <div className="flex flex-row text-sm md:text-lg font-normal text-black items-center gap-x-2">
              <CallCalling color="#000000" variant="Bold" size={20} />
              <div>
                {employee?.areaCode} {employee?.mobile}
              </div>
              <Copy
                onClick={() => copyToClipboard(`${employee?.areaCode}${employee?.mobile}`)}
                color="#000000"
                variant="Outline"
                size={20}
                className="cursor-pointer"
              />
            </div>

            <div className="flex flex-row text-sm md:text-lg font-normal text-black items-center gap-x-2">
              <Sms color="#000000" variant="Bold" size={20} />
              <a href={`mailto:${employee?.email}`} className="hover:underline hover:text-primary">
                {employee?.email}
              </a>
              <Copy
                onClick={() => copyToClipboard(employee?.email)}
                color="#000000"
                variant="Outline"
                size={20}
                className="cursor-pointer"
              />
            </div>

            <div className="flex items-start gap-x-2 text-sm md:text-lg font-normal text-black">
              <Location color="#000000" variant="Bold" size={20} className="flex-shrink-0 mt-1" />
              <div className="flex items-center">
                <span className="break-words">
                  {employee?.address}, {employee?.country}, {employee?.postal}
                </span>
                <Copy
                  onClick={() => copyToClipboard(`${employee?.address}, ${employee?.country}, ${employee?.postal}`)}
                  color="#000000"
                  variant="Outline"
                  size={20}
                  className="cursor-pointer flex-shrink-0 ml-2"
                />
              </div>
            </div>
          </div>
          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-0 top-0 hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#000000" variant="Bold" size={20} />
            </button>
          )}
        </div>
      </div>
      {edit && <div className="w-full border-[4px] rounded-lg border-grayMedium mt-5"></div>}
      {edit && (
        <div className="rounded-xl bg-white shadow-lg w-full">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="flex w-full lg:w-1/2">
                  <div className="w-32">
                    <label className="mb-2.5 block text-black font-bold">Salutation</label>
                    <Dropdown
                      value={salutationOptions.find((option) => option.value === formik.values.salutation)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('salutation', selectedValue.value);
                      }}
                      options={salutationOptions}
                      isClearable={false}
                      placeholder="Select"
                      name="salutation"
                      id="salutation"
                    />
                    {formik.touched.salutation && formik.errors.salutation ? (
                      <div className="text-red-500 text-sm">{formik.errors.salutation}</div>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <label className="mb-2.5 block text-black font-bold">Full name</label>
                    <input
                      type="text"
                      placeholder="Enter your Full Name"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="fullName"
                      onChange={formik.handleChange}
                      value={formik.values.fullName}
                    />
                    {formik.touched.fullName && formik.errors.fullName ? (
                      <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Email</label>
                  <input
                    type="email"
                    placeholder="Enter your Email"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Identification Type</label>
                  <Dropdown
                    value={identificationType.find((option) => option.value === formik.values.idType)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('idType', selectedValue.value);
                    }}
                    options={identificationType}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Identification Type"
                    name="idType"
                    id="idType"
                  />
                  {formik.touched.idType && formik.errors.idType ? (
                    <div className="text-red-500 text-sm">{formik.errors.idType}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Identification Number</label>
                  <input
                    type="text"
                    placeholder="Enter your ID Number"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="idNum"
                    onChange={formik.handleChange}
                    value={isMasked ? maskValue(formik.values.idNum) : formik.values.idNum}
                    onFocus={handleMaskToggle}
                    onBlur={handleMaskReapply}
                  />
                  {formik.touched.idNum && formik.errors.idNum ? (
                    <div className="text-red-500 text-sm">{formik.errors.idNum}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Date of Birth</label>
                  <DatePicker value={formik.values.dob} onChange={(date) => formik.setFieldValue('dob', date)} />
                  {formik.touched.dob && formik.errors.dob ? (
                    <div className="text-red-500 text-sm">{formik.errors.dob}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Personal Email</label>
                  <input
                    type="text"
                    placeholder="Enter your Personal Email"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="personalEmail"
                    onChange={formik.handleChange}
                    value={formik.values.personalEmail}
                  />
                  {formik.touched.personalEmail && formik.errors.personalEmail ? (
                    <div className="text-red-500 text-sm">{formik.errors.personalEmail}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Mobile</label>
                  <div className="flex flex-row">
                    <CountryCodeDropdown
                      value={formik.values.areaCode}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('areaCode', selectedValue.value);
                      }}
                      isClearable={false}
                      name="areaCode"
                      id="areaCode"
                    />
                    <input
                      type="text"
                      placeholder="Enter your Office Telephone"
                      className="w-full rounded-e-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="mobile"
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                    />
                  </div>
                  {formik.touched.areaCode && formik.errors.areaCode ? (
                    <div className="text-red-500 text-sm">{formik.errors.areaCode}</div>
                  ) : null}
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Home Telephone</label>
                  <div className="flex flex-row">
                    <CountryCodeDropdown
                      value={formik.values.areaCodeContact}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('areaCodeContact', selectedValue.value);
                      }}
                      isClearable={false}
                      name="areaCodeContact"
                      id="areaCodeContact"
                    />
                    <input
                      type="text"
                      placeholder="Enter your Home Telephone"
                      className="w-full rounded-e-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="contact"
                      onChange={formik.handleChange}
                      value={formik.values.contact}
                    />
                  </div>
                  {formik.touched.areaCodeContact && formik.errors.areaCodeContact ? (
                    <div className="text-red-500 text-sm">{formik.errors.areaCodeContact}</div>
                  ) : null}
                  {formik.touched.contact && formik.errors.contact ? (
                    <div className="text-red-500 text-sm">{formik.errors.contact}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Religion</label>
                  <Dropdown
                    value={religionOptions.find((option) => option.value === formik.values.religion)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('religion', selectedValue.value);
                    }}
                    options={religionOptions}
                    isClearable={false}
                    placeholder="Select Religion"
                    name="religion"
                    id="religion"
                  />
                  {formik.touched.religion && formik.errors.religion ? (
                    <div className="text-red-500 text-sm">{formik.errors.religion}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Race</label>
                  <Dropdown
                    value={raceOptions.find((option) => option.value === formik.values.race)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('race', selectedValue.value);
                    }}
                    options={raceOptions}
                    isClearable={false}
                    placeholder="Select Race"
                    name="race"
                    id="race"
                  />
                  {formik.touched.race && formik.errors.race ? (
                    <div className="text-red-500 text-sm">{formik.errors.race}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Nationality</label>
                  <Dropdown
                    value={countryOptions.find((option) => option.value === formik.values.nationality)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('nationality', selectedValue.value);
                    }}
                    options={countryOptions}
                    isClearable={false}
                    placeholder="Select Nationality"
                    name="nationality"
                    id="nationality"
                  />
                  {formik.touched.nationality && formik.errors.nationality ? (
                    <div className="text-red-500 text-sm">{formik.errors.nationality}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Gender</label>
                  <Dropdown
                    value={genderOptions.find((option) => option.value === formik.values.gender)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('gender', selectedValue.value);
                    }}
                    options={genderOptions}
                    isClearable={false}
                    placeholder="Select Gender"
                    name="gender"
                    id="gender"
                  />
                  {formik.touched.gender && formik.errors.gender ? (
                    <div className="text-red-500 text-sm">{formik.errors.gender}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Marital Status</label>
                  <Dropdown
                    value={maritalStatusOptions.find((option) => option.value === formik.values.maritalStatus)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('maritalStatus', selectedValue.value);
                    }}
                    options={maritalStatusOptions}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Marital Status"
                    name="maritalStatus"
                    id="maritalStatus"
                  />
                  {formik.touched.maritalStatus && formik.errors.maritalStatus ? (
                    <div className="text-red-500 text-sm">{formik.errors.maritalStatus}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Citizenship</label>
                  <Dropdown
                    value={citizenshipTypeOptions.find((option) => option.value === formik.values.citizenship)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) {
                        formik.setFieldValue('citizenship', selectedValue.value);
                        formik.setFieldValue('prObtained', '');
                        formik.setFieldValue('reEntryExpiration', '');
                        formik.setFieldValue('workPassObtained', '');
                        formik.setFieldValue('workPassExpiration', '');
                      }
                    }}
                    options={citizenshipTypeOptions}
                    isClearable={false}
                    placeholder="Select Citizenship"
                    name="citizenship"
                    id="citizenship"
                  />
                  {formik.touched.citizenship && formik.errors.citizenship ? (
                    <div className="text-red-500 text-sm">{formik.errors.citizenship}</div>
                  ) : null}
                </div>
              </div>
              {formik.values.citizenship === 'PR' && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">PR Obtain Date</label>
                    <DatePicker
                      value={formik.values.prObtained}
                      onChange={(date) => formik.setFieldValue('prObtained', date)}
                    />
                    {formik.touched.prObtained && formik.errors.prObtained ? (
                      <div className="text-red-500 text-sm">{formik.errors.prObtained}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Re-Entry Pass Expiration</label>
                    <DatePicker
                      value={formik.values.reEntryExpiration}
                      onChange={(date) => formik.setFieldValue('reEntryExpiration', date)}
                    />
                    {formik.touched.reEntryExpiration && formik.errors.reEntryExpiration ? (
                      <div className="text-red-500 text-sm">{formik.errors.reEntryExpiration}</div>
                    ) : null}
                  </div>
                </div>
              )}
              {formik.values.citizenship === 'Foreigner' && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Work Pass Type</label>
                    <Dropdown
                      value={workPassOptions.find((option) => option.value === formik.values.workPassType)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('workPassType', selectedValue.value);
                      }}
                      options={workPassOptions}
                      isClearable={false}
                      placeholder="Select Work Pass Type"
                      name="workPassType"
                      id="workPassType"
                    />
                    {formik.touched.workPassType && formik.errors.workPassType ? (
                      <div className="text-red-500 text-sm">{formik.errors.workPassType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
              )}
              {formik.values.citizenship === 'Foreigner' && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Work Pass Obtained Date</label>
                    <DatePicker
                      value={formik.values.workPassObtained}
                      onChange={(date) => formik.setFieldValue('workPassObtained', date)}
                    />
                    {formik.touched.workPassObtained && formik.errors.workPassObtained ? (
                      <div className="text-red-500 text-sm">{formik.errors.workPassObtained}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Work Pass Expiration Date</label>
                    <DatePicker
                      value={formik.values.workPassExpiration}
                      onChange={(date) => formik.setFieldValue('workPassExpiration', date)}
                    />
                    {formik.touched.workPassExpiration && formik.errors.workPassExpiration ? (
                      <div className="text-red-500 text-sm">{formik.errors.workPassExpiration}</div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="mb-4">
                <div className="w-full">
                  <label className="mb-2.5 block text-black font-bold">Address</label>
                  <input
                    type="text"
                    placeholder="Enter your address"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="address"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-red-500 text-sm">{formik.errors.address}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Country</label>
                  <Dropdown
                    value={countryOptions.find((option) => option.value === formik.values.country)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('country', selectedValue.value);
                    }}
                    options={countryOptions}
                    isClearable={false}
                    placeholder="Select Country"
                    name="country"
                    id="country"
                    menuPlacement="top"
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div className="text-red-500 text-sm">{formik.errors.country}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Postal Code</label>
                  <input
                    type="text"
                    placeholder="Enter your Postal Code"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="postal"
                    onChange={formik.handleChange}
                    value={formik.values.postal}
                  />
                  {formik.touched.postal && formik.errors.postal ? (
                    <div className="text-red-500 text-sm">{formik.errors.postal}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end w-full gap-x-2">
                <button
                  type="button"
                  onClick={() => setEdit(false)}
                  className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {editProfilePicModal && (
        <ProfileImageUploadModal
          closeModal={() => setEditProfilePicModal(false)}
          userRole="employee"
          employeeId={employee?._id}
          companyId={{ companyId: company?._id }}
        />
      )}
    </div>
  );
};

export default PersonalDetails;
