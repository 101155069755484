import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../layout/layout';
import InitialPayslip from './initialPayslip';
import PayslipResultList from './payslipResultList';
import PayslipSelectMonth from './payslipSelectMonth';
import { useEffect, useState } from 'react';
import { setClickPayslips, setSelectedPayslip } from '../../../actions/payrollAction';

const Payslip = () => {
  const dispatch = useDispatch();

  // const [clickedPayslips, setClickPayslips] = useState(false);
  const currentDate = new Date();
  const [year, setYear] = useState(currentDate.getFullYear());
  const [loader, setLoader] = useState(false);

  const payslips = useSelector((state) => state?.payroll?.payslips);
  const selectedPayslip = useSelector((state) => state?.payroll?.selectedPayslip || {});
  const clickedPayslips = useSelector((state) => state?.payroll?.clickedPayslips || false);

  // console.log('payslips: ', payslips);
  // console.log('selectedPayslip: ', selectedPayslip);
  // console.log('clickedPayslips: ', clickedPayslips);

  const handleSelectedPayslip = (data) => {
    dispatch(setSelectedPayslip(data));
  };
  const handleClickPayslip = (data) => {
    dispatch(setClickPayslips(data));
  };

  useEffect(() => {
    // dispatch(setClickPayslips(false))
    // dispatch(setSelectedPayslip({}))
  }, []);

  return (
    <Layout pageTitle={'Payslip'} page={'Payroll'} subPage={'Payslip'}>
      <div className="h-full">
        {clickedPayslips ? (
          Object.keys(selectedPayslip).length > 0 ? (
            <PayslipResultList selectedPayslip={selectedPayslip} setSelectedPayslip={handleSelectedPayslip} />
          ) : (
            <PayslipSelectMonth
              loader={loader}
              setClickPayslips={handleClickPayslip}
              setSelectedPayslip={handleSelectedPayslip}
              selectedYear={year}
            />
          )
        ) : (
          <InitialPayslip setClickPayslips={handleClickPayslip} year={year} setYear={setYear} setLoader={setLoader}/>
        )}
      </div>
    </Layout>
  );
};

export default Payslip;
