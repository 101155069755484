import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/dropdown';
import { applyLeaveSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import { ToastBar } from '../../../components/toastbar';
import { editLeave } from '../../../actions/employeeAction';
import { updateLeaveByUser } from '../../../actions/userAction';

const EditLeave = ({ leave, onCancel, employeeId, companyId, company }) => {
  console.log('edit leave: ', leave)
  const dispatch = useDispatch();
  const [numberOfDays, setNumberOfDays] = useState(0);

  const formik = useFormik({
    initialValues: {
      leaveType: leave?.leaveType || '',
      fromDate: leave?.fromDate || '',
      toDate: leave?.toDate || ''
    },
    validationSchema: applyLeaveSchema,
    onSubmit: (values) => {
      handleEditLeave(values);
    }
  });

  useEffect(() => {
    if (formik.values.fromDate && formik.values.toDate) {
      calculateNumberOfDays(formik.values.fromDate, formik.values.toDate);
    }
  }, [formik.values.fromDate, formik.values.toDate]);

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (!fromDate || !toDate) return;

    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let dayCount = 0;

    // Get the list of public holiday dates as an array of startDates
    const publicHolidayDates = company?.publicHolidays?.map(holiday => new Date(holiday.startDate).toDateString()) || [];

    // Loop through each date in the range
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      // Check if the current date is a weekend or a public holiday
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !publicHolidayDates.includes(currentDate.toDateString())) {
        dayCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setNumberOfDays(dayCount);
  };

  const handleEditLeave = async (values) => {
    const leaveApplication = {
      leaveType: values.leaveType,
      leaveCode: leave.leaveCode,
      fromDate: values.fromDate,
      toDate: values.toDate,
      numberOfDays,
      status: 'Pending',
      _id: leave._id,
      companyId: companyId,
      employeeId: employeeId
    };

    console.log(leaveApplication, employeeId)

    const result = await dispatch(updateLeaveByUser(leaveApplication));
    if (result.success) {
      onCancel(); // Close the edit form
    }
  };

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg">
      <h4 className="text-2xl font-bold mb-5 text-black">Edit Leave</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Select Leave Type</label>
            <Dropdown
              options={[{ label: leave.leaveType, value: leave.leaveCode }]}
              value={{ label: formik.values.leaveType, value: leave.leaveCode }}
              onChange={(option) => {
                formik.setFieldValue('leaveType', option.label);
                formik.setFieldTouched('leaveType', true, false);
              }}
              placeholder="Select Leave Type"
              name="leaveType"
              id="leaveType"
            />
            {formik.touched.leaveType && formik.errors.leaveType ? (
              <div className="text-red-500 text-sm">{formik.errors.leaveType}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="mb-7 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">From Date</label>
            <DatePicker
              value={formik.values.fromDate}
              onChange={(date) => {
                formik.setFieldValue('fromDate', date);
                formik.setFieldTouched('fromDate', true, false);
              }}
            />
            {formik.touched.fromDate && formik.errors.fromDate ? (
              <div className="text-red-500 text-sm">{formik.errors.fromDate}</div>
            ) : null}
          </div>

          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">To Date</label>
            <DatePicker
              value={formik.values.toDate}
              onChange={(date) => {
                formik.setFieldValue('toDate', date);
                formik.setFieldTouched('toDate', true, false);
              }}
            />
            {formik.touched.toDate && formik.errors.toDate ? (
              <div className="text-red-500 text-sm">{formik.errors.toDate}</div>
            ) : null}
          </div>
        </div>

        <div className="mb-6">
          <div className="block text-base text-black font-bold">
            Number of Days: <span className="font-normal">{numberOfDays}</span>
          </div>
          {/* <input
                    type="text"
                    value={numberOfDays}
                    readOnly
                    className="form-input w-full rounded border-gray-300 shadow-sm"
                  /> */}
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-700 text-white font-semibold rounded-md px-4 py-2 mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button type="submit" className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditLeave;

