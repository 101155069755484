import { FETCH_PENDING_CLAIMS } from '../actions/claimAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  pendingClaims: []
};

export const claimReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_CLAIMS:
      return {
        ...state,
        pendingClaims: action.payload.pendingClaims
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default claimReducer;
