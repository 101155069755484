import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Edit2 } from 'iconsax-react';
import CustomTimePicker from '../../../components/customTimePicker';
import Dropdown from '../../../components/dropdown';
import { workingHourTypeOptions } from '../../../constant/dropdownConstOptions';
import { updateEmployeeWorkingHourDetails } from '../../../actions/employeeAction';
import { convertTime } from '../../../constant/functions';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WorkingHourDetails = ({ employee }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const initializeDayDetails = () => {
    if (employee?.workingHourDetails?.workDays?.length > 0) {
      return {
        workHoursType: employee.workingHourDetails.workHoursType,
        workDays: [
          weekdays.reduce((acc, day) => {
            const workDay = employee.workingHourDetails.workDays?.find(d => d.day === day);
            acc[day] = workDay
              ? {
                  checked: true,
                  startTime: convertTime(workDay.startTime),
                  endTime: convertTime(workDay.endTime),
                  hours: {
                    hours: Math.floor(workDay.hours / 60),
                    minutes: workDay.hours % 60
                  }
                }
              : {
                  checked: false,
                  startTime: null,
                  endTime: null,
                  hours: null
                };
            return acc;
          }, {})
        ]
      };
    } else {
      return {
        workHoursType: 'Fixed',
        workDays: [
          weekdays.reduce((acc, day) => {
            acc[day] = {
              checked: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day),
              startTime: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day)
                ? { hours: '09', minutes: '00', period: 'AM' }
                : null,
              endTime: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day)
                ? { hours: '06', minutes: '00', period: 'PM' }
                : null,
              hours: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day)
                ? { hours: 9, minutes: 0 }
                : null
            };
            return acc;
          }, {})
        ]
      };
    }
  };

  const [dayDetails, setDayDetails] = useState(initializeDayDetails());

  // Handle checkbox change
  const handleCheckboxChange = (day) => {
    setDayDetails((prevDetails) => ({
      ...prevDetails,
      workDays: prevDetails.workDays.map((days) => ({
        ...days,
        [day]: {
          ...days[day],
          checked: !days[day].checked,
          startTime: !days[day].checked ? { hours: '09', minutes: '00', period: 'AM' } : null,
          endTime: !days[day].checked ? { hours: '06', minutes: '00', period: 'PM' } : null
        }
      }))
    }));
  };

  // Handle time change (assume CustomTimePicker provides a callback to set times)
  const handleTimeChange = (day, startTime, endTime, duration) => {
    setDayDetails((prevDetails) => ({
      ...prevDetails,
      workDays: prevDetails.workDays.map((days) => ({
        ...days,
        [day]: {
          ...days[day],
          startTime,
          endTime,
          hours: duration
        }
      }))
    }));
  };

  // Function to retrieve selected details
  const getSelectedDetails = () => {
    const selectedDays = dayDetails.workDays.flatMap((days) =>
      Object.entries(days)
        .filter(([day, details]) => details.checked)
        .map(([day, details]) => ({
          day,
          startTime: details.startTime,
          endTime: details.endTime,
          hours: details.hours
        }))
    );

    const inputValue = {
      workHoursType: dayDetails.workHoursType,
      workDays: selectedDays
    };

    inputValue && dispatch(updateEmployeeWorkingHourDetails(employee._id, employee.company._id, inputValue, setEdit));
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-start">
          <div className="font-bold text-black text-2xl">Working Days</div>
        </div>
        <div className="p-6 relative">
          <div className="w-full flex flex-col gap-y-5">
            {dayDetails.workDays.map((days) =>
              Object.keys(days).map((day) => (
                <>
                  {days[day].checked && (
                    <div key={day} className="flex flex-wrap w-full items-start gap-x-5">
                      <div className="text-lg text-black font-semibold mb-2 w-28">{day}</div>
                      <div className="mb-4">
                        <div className="text-sm text-black/40 font-bold">Start Time</div>
                        <div className="text-base text-black font-normal">
                          {days[day].startTime
                            ? `${days[day].startTime.hours}:${days[day].startTime.minutes} ${days[day].startTime.period}`
                            : 'N/A'}
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="text-sm text-black/40 font-bold">End Time</div>
                        <div className="text-base text-black font-normal">
                          {days[day].endTime
                            ? `${days[day].endTime.hours}:${days[day].endTime.minutes} ${days[day].endTime.period}`
                            : 'N/A'}
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="text-sm text-black/40 font-bold">Hours</div>
                        <div className="text-base text-black font-normal">
                          {days[day].hours
                            ? `${days[day].hours.hours} hours and ${days[day].hours.minutes} minutes`
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
            )}
          </div>
          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-4 top-0 hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#000000" variant="Bold" size={20} />
            </button>
          )}
        </div>
        {edit && (
          <div className="rounded-xl bg-white shadow-lg w-full p-5">
            <div className="font-bold text-black text-xl mb-5">Edit Working Days</div>
            <label className="mb-2 block text-black font-semibold">Working Hours Type</label>
            <div className="w-full lg:w-1/2 mb-5">
              <Dropdown
                value={workingHourTypeOptions.find((option) => option.value === dayDetails?.workHoursType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    setDayDetails((prevDetails) => ({
                      ...prevDetails,
                      workHoursType: selectedValue?.value
                    }));
                  }
                }}
                options={workingHourTypeOptions}
                isClearable={false}
                isSearchable={false}
                placeholder="Select Working Hours Type"
                name="workHoursType"
                id="workHoursType"
              />
            </div>
            {weekdays.map((day) => (
              <div
                key={day}
                className="bg-grayLight w-full rounded-lg flex flex-wrap justify-between items-center px-8 mb-4 py-4 gap-3 min-h-16"
              >
                <div className="flex gap-x-8 items-center">
                  <input
                    type="checkbox"
                    id={day.toLowerCase()}
                    className="form-checkbox"
                    checked={dayDetails.workDays[0][day]?.checked || false}
                    onChange={() => handleCheckboxChange(day)}
                  />
                  <span className="text-sm font-bold text-black">{day}</span>
                </div>
                {dayDetails.workDays[0][day]?.checked && (
                  <CustomTimePicker
                    startTime={dayDetails.workDays[0][day]?.startTime || {}}
                    endTime={dayDetails.workDays[0][day]?.endTime || {}}
                    onTimeChange={(startTime, endTime, duration) => handleTimeChange(day, startTime, endTime, duration)}
                  />
                )}
              </div>
            ))}
            <div className="flex justify-end w-full gap-x-2">
              <button
                type="button"
                onClick={() => setEdit(false)}
                className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={getSelectedDetails}
                className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
              >
                Save Changes
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkingHourDetails;
