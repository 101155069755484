import { useParams } from 'react-router-dom';
import PersonalDetails from './personalDetails';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useEffect, useState } from 'react';
import EmploymentDetails from './employmentDetails';
import PaySchemeDetails from './paySchemeDetails';
import CredentialDetails from './credentialDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeById } from '../../../actions/employeeAction';
import Layout from '../../../layout/layout';
import AllowanceDetails from './allowanceDetails';
import DeductionDetails from './deductionDetails';
import LeaveDetails from './leaveDetails';
import ClaimDetails from './claimDetails';
import WorkingHourDetails from './workingHourDetails';
import ApprovingDetails from './approvingDetails';
import MedicalLeaveDetails from './medicalLeaveDetails';
import LayoutWM from '../../../layout/layoutWM';

const EditEmployee = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company.company);
  const employeeState = useSelector((state) => state.employee);

  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeeById({ employeeId: id }, setEmployee));
      setLoading(false);
    }
  }, [employeeState]);

  console.log('employee: ', employee);

  return (
    <LayoutWM pageTitle={"Edit Employee"} page={"Employee"} subPage={"Edit"}>
      {loading || !employee ? (
        <SkeletonLoader length={8} />
      ) : (
        <div className="space-y-4">
          <PersonalDetails employee={employee} company={company} />
          <EmploymentDetails employee={employee} company={company} />
          <WorkingHourDetails employee={employee} company={company} />
          <PaySchemeDetails employee={employee} />
          <LeaveDetails employee={employee} company={company} />
          <MedicalLeaveDetails employee={employee} company={company} />
          <AllowanceDetails employee={employee} company={company} />
          <DeductionDetails employee={employee} company={company} />
          <ClaimDetails employee={employee} company={company} />
          <CredentialDetails employee={employee} />
          <ApprovingDetails employee={employee} />
        </div>
      )}
    </LayoutWM>
  );
};

export default EditEmployee;
