import empty from '../../../assets/images/empty.png';
import defaultPic from '../../../assets/images/Default_profilepic.png';

const NoLeavesTakenTable = ({ noLeavesTaken }) => {
  return (
    <div>
      <div className="text-2xl font-semibold text-black pb-5">No Leave Taken</div>
      {noLeavesTaken?.length > 0 ? (
        <table className="table-auto rounded-2xl w-full">
          <thead>
            <tr className="h-20 bg-grayLight text-black">
              <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
              <th>Full Name</th>
            </tr>
          </thead>
          <tbody>
            {noLeavesTaken?.map((employee, index) => {
              return (
                <tr className="h-20 text-sm" key={index}>
                  <td className="text-sm font-nunito font-semibold leading-normal text-black">
                    <div className="flex justify-center items-center h-20">
                      <img
                        src={employee?.employee?.avatar ? employee?.employee?.avatar : defaultPic}
                        alt="profile pic"
                        className="w-10 h-10 rounded-full object-cover"
                      />
                    </div>
                  </td>
                  <td className="h-20 text-center">{employee?.employee?.fullName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full min-h-60 flex flex-col justify-center items-center rounded-xl">
          <img src={empty} alt="empty data" className="w-56" />
          <div className="mt-5 text-purple1 font-bold text-xl">No Leave Taken Data</div>
        </div>
      )}
    </div>
  );
};

export default NoLeavesTakenTable;
