import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { claimSchema } from '../../../validations/validationSchemas';
import { addClaim, deleteClaim } from '../../../actions/companyAction';
import EditClaim from './editClaim';
import { Add, Edit2, Trash } from 'iconsax-react';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';

const ClaimDetails = ({ company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      claimType: '',
      claimCode: ''
    },
    enableReinitialize: true,
    validationSchema: claimSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addClaim(values, setEdit, resetForm));
    }
  });

  const handleModalDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleDelete = (claim, company) => {
    dispatch(
      deleteClaim(
        {
          companyId: company?._id,
          _id: claim?._id
        },
        setIsConfirmationModalOpen
      )
    );
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Claims</h3>
          {!edit && (
            <button
              className="w-16 h-8 rounded-lg flex items-center justify-center text-2xl font-bold bg-grayLight shadow-md"
              onClick={() => setEdit(true)}
            >
              <Add color="#3D54DD" variant="Linear" size={20} />
            </button>
          )}
        </div>
        {edit && (
          <div className="rounded-xl bg-white shadow-lg w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Claim Type</label>
                    <input
                      type="text"
                      placeholder="Claim Type"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="claimType"
                      onChange={formik.handleChange}
                      value={formik.values.claimType}
                    />
                    {formik.touched.claimType && formik.errors.claimType ? (
                      <div className="text-red-500 text-sm">{formik.errors.claimType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Claim Code</label>
                    <input
                      type="text"
                      placeholder="Claim Code"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="claimCode"
                      onChange={formik.handleChange}
                      value={formik.values.claimCode}
                    />
                    {formik.touched.claimCode && formik.errors.claimCode ? (
                      <div className="text-red-500 text-sm">{formik.errors.claimCode}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-end w-full gap-x-2">
                  <button
                    type="button"
                    onClick={() => setEdit(false)}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-28 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex flex-wrap gap-3 mt-5">
          {company?.claims?.map((claim, index) => {
            return (
              <div
                className={`p-6 relative bg-white shadow-lg flex-grow ${
                  editIndex === index ? 'w-full' : 'basis-[450px]'
                } rounded-2xl`}
                key={index}
              >
                {editIndex !== index && (
                  <div>
                    <div className="text-base text-black font-bold">{claim?.claimType}</div>
                    <div className="text-sm font-normal text-black/40">{claim?.claimCode}</div>
                  </div>
                )}

                {editIndex !== index && (
                  <div className="flex flex-row gap-x-1 absolute right-4 top-6">
                    <button
                      onClick={() => setEditIndex(index)}
                      type="button"
                      className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
                    >
                      <Edit2 color="#000000" variant="Bold" size={20} />
                    </button>
                    <button
                      onClick={() => handleModalDelete()}
                      type="button"
                      className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-600"
                    >
                      <Trash color="#C84040" variant="Bold" size={20} />
                    </button>
                    <DeleteConfirmationModal
                      isOpen={isConfirmationModalOpen}
                      onClose={() => setIsConfirmationModalOpen(false)}
                      handleDelete={() => handleDelete(claim, company)}
                      title={'claim'}
                    />
                  </div>
                )}
                {editIndex === index && <EditClaim company={company} claim={claim} onClose={() => setEditIndex(null)} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ClaimDetails;
