import { Calendar1, Document } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import { generatePayroll } from '../../../actions/payrollAction';

const InitialGeneratePayroll = ({ companyId }) => {
  const dispatch = useDispatch();

  const currentDate = new Date();
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();

  console.log("here, ", companyId)

  const handleGeneratePayroll = () => {
    const inputValue = {
      month: month,
      year: year,
      companyId: companyId,
    };
    console.log(inputValue)
    if (month && year) dispatch(generatePayroll(inputValue));
  };
  return (
    <div className="bg-white p-6 rounded-2xl">
      <div className="flex flex-wrap justify-between gap-3">
        <div className="flex gap-x-5 items-center">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <Calendar1 color="#3D54DD" variant="Broken" size={25} />
          </div>
          <div>
            <div className="text-black font-medium text-xs">Salary period</div>
            <div className="flex flex-wrap gap-x-5 text-sm font-bold">
              <div className="text-purple1">{month}</div>
              <div className="text-black">{year}</div>
            </div>
          </div>
        </div>
        {/* <div></div> */}
        <button
          type="button"
          disabled={!companyId}
          onClick={() => handleGeneratePayroll()}
          className="bg-purple1 hover:bg-purple1/90 disabled:bg-gray-500 text-white font-bold rounded-xl px-6 h-14 text-right flex items-center gap-x-3"
        >
          <Document color="#ffffff" variant="Outline" size={25} />
          <span>Generate Payroll</span>
        </button>
      </div>
    </div>
  );
};

export default InitialGeneratePayroll;
