import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import empty from '../../../assets/images/empty.png';
import { useNavigate } from 'react-router-dom';
import { Eye } from 'iconsax-react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import DefaultPic from '../../../assets/images/Default_profilepic.png';
// import { submitApproval } from '../../actions/payrollAction';
import * as XLSX from 'xlsx';
import { ToastBar } from '../../../components/toastbar';
import { format } from 'date-fns';
import PaginationHeader from '../../../components/paginationHeader';
import PaginationFooter from '../../../components/paginationFooter';
import HistoryPayrollTableHeader from './historyPayrollTableHeader';

const HistoryPayrollEmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'ascending'
  });
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const historyPayroll = useSelector((state) => state?.payroll?.historyPayroll);
  const company = useSelector((state) => state.company?.company);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(sortedEmployees);
    }
    setSelectAll(!selectAll);
  };

  const getFieldValue = (obj, path) => {
    switch (path) {
      case 'employee.salary[0].netSalary':
        return obj?.salary?.[0]?.netSalary || 0;
      case 'employee.cpf[0].CPFContribution':
        return obj?.cpf?.[0]?.CPFContribution || 0;
      case 'employee.allowances[0].totalAllowance':
        return obj?.allowances?.[0]?.totalAllowance || 0;
      case 'employee.claims[0].totalClaimAmount':
        return obj?.claims?.[0]?.totalClaimAmount || 0;
      case 'employee.deductions[0].totalDeductionAmount':
        return obj?.deductions?.[0]?.totalDeductionAmount || 0;
      default:
        return obj?.employee?.fullName?.toLowerCase() || '';
    }
  };

  const sortedEmployees = useMemo(() => {
    if (!historyPayroll?.payrollDetails) {
      return [];
    }

    let sortableGeneratedEmployees = [...historyPayroll?.payrollDetails];

    sortableGeneratedEmployees.sort((a, b) => {
      const fieldA = getFieldValue(a, sortConfig.key);
      const fieldB = getFieldValue(b, sortConfig.key);

      if (typeof fieldA === 'number' && typeof fieldB === 'number') {
        return sortConfig.direction === 'ascending' ? fieldA - fieldB : fieldB - fieldA;
      } else {
        return sortConfig.direction === 'ascending' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      }
    });

    return sortableGeneratedEmployees;
  }, [historyPayroll, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(employee)
        ? prevSelected.filter((selected) => selected !== employee)
        : [...prevSelected, employee]
    );
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedEmployees.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedEmployees.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleGeneratePayroll = () => {
    // dispatch(submitApproval());
  };

  const downloadExcel = () => {
    try {
      const formattedUsers = historyPayroll?.payrollDetails?.map((employee) => {
        const currencySymbol = employee?.salary[0]?.currency || '';

        const formatCurrency = (amount) => {
          if (amount == null) return `0.00`;
          return amount.toFixed(2);
        };

        console.log('employee: ', employee);
        return {
          [`Full Name`]: employee?.employee?.fullName || '',
          [`Designation`]: employee?.employee?.employmentDetails?.designationName || '',
          [`Department`]: employee?.employee?.employmentDetails?.departmentName || '',
          [`Basic Salary (${currencySymbol})`]: formatCurrency(employee?.salary[0]?.basicSalary || 0),
          [`Employee CPF Percentage (%)`]: employee?.salary[0]?.employeeCPFPercentage || 0,
          [`Employer CPF Percentage (%)`]: employee?.salary[0]?.employerCPFPercentage || 0,
          [`Employee CPF (${currencySymbol})`]: formatCurrency(employee?.salary[0]?.employeeCPF || 0),
          [`Employer CPF (${currencySymbol})`]: formatCurrency(employee?.salary[0]?.employerCPF || 0),
          [`CPF Total Employee (${currencySymbol})`]: formatCurrency(employee?.cpf[0]?.employeeCPF || 0),
          [`CPF Total Employer (${currencySymbol})`]: formatCurrency(employee?.cpf[0]?.employerCPF || 0),
          [`CPF Contribution (${currencySymbol})`]: formatCurrency(employee?.cpf[0]?.CPFContribution || 0),
          [`Net Salary (${currencySymbol})`]: formatCurrency(employee?.salary[0]?.netSalary || 0),
          [`Total Deductions (${currencySymbol})`]: formatCurrency(employee?.deductions[0]?.totalDeductionAmount || 0),
          [`Total Allowances (${currencySymbol})`]: formatCurrency(employee?.allowances[0]?.totalAllowance || 0),
          [`Net Allowances (${currencySymbol})`]: formatCurrency(employee?.allowances[0]?.netAllowanceAmount || 0),
          [`Total Claims (${currencySymbol})`]: formatCurrency(employee?.claims[0]?.totalClaimAmount || 0)
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(formattedUsers);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Payroll');

      const maxLength = {};
      const headerKeys = Object.keys(formattedUsers[0]);

      formattedUsers.forEach((row) => {
        headerKeys.forEach((key) => {
          const length = String(row[key]).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });

      headerKeys.forEach((key) => {
        const length = String(key).length;
        maxLength[key] = Math.max(maxLength[key] || 0, length);
      });

      const colWidths = headerKeys.map((key) => ({ wch: maxLength[key] || 10 }));
      worksheet['!cols'] = colWidths;

      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[address]) continue;
        if (!worksheet[address].s) worksheet[address].s = {};
        worksheet[address].s.font = { bold: true };
      }

      const filename = `${historyPayroll?.month}-${historyPayroll?.year}_generated_payroll_employees.xlsx`;
      XLSX.writeFile(workbook, filename);
      ToastBar.success('Excel Download Successful');
    } catch (error) {
      console.log(error);
      ToastBar.error('Error Downloading Excel');
    }
  };

  return (
    <div className="h-full">
      <HistoryPayrollTableHeader historyPayroll={historyPayroll} />
      <div className="bg-white min-h-full p-6 flex flex-col justify-between rounded-2xl">
        <div className="flex flex-col h-full">
          <div className="w-full flex justify-between mb-4 items-center">
            <div className="text-2xl font-bold text-black">Employee Payment Details</div>
            <div className="flex gap-x-3 items-center">
              <div onClick={downloadExcel} className="cursor-pointer" title="Download Payroll Employees">
                <FontAwesomeIcon icon={faFileExcel} size="2x" color="#3CA455" />
              </div>
            </div>
          </div>

          {currentEntries?.length > 0 ? (
            <div>
              <PaginationHeader
                entriesPerPage={entriesPerPage}
                handleEntriesPerPageChange={handleEntriesPerPageChange}
              />
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th>
                    <th className="h-20 text-center w-12">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name
                      <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('employee.salary[0].netSalary')} className="cursor-pointer">
                      Net Salary
                      <FontAwesomeIcon icon={getSortIcon('employee.salary[0].netSalary')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('employee.cpf[0].CPFContribution')} className="cursor-pointer">
                      CPF Contribution
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.cpf[0].CPFContribution')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.allowances[0].totalAllowance')} className="cursor-pointer">
                      Total Allowance
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.allowances[0].totalAllowance')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.claims[0].totalClaimAmount')} className="cursor-pointer">
                      Total Claims
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.claims[0].totalClaimAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th
                      onClick={() => handleSort('employee.deductions[0].totalDeductionAmount')}
                      className="cursor-pointer"
                    >
                      Total Deductions
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.deductions[0].totalDeductionAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th className="cursor-pointer px-4 rounded-e-xl w-24">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((employee, index) => {
                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedEmployees.includes(employee)}
                            onChange={() => handleCheckboxChange(employee)}
                          />
                        </td>
                        <td className="h-20 w-12 text-center">
                          <div className="flex justify-center items-center">
                            <img
                              src={employee?.employee?.avatar ? employee?.employee?.avatar : DefaultPic}
                              alt="profile pic"
                              className="w-8 h-8 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td title={employee?.employee?.fullName} className="h-20 text-sm text-black text-center">
                          <span>
                            {employee?.employee?.fullName}
                            {/* {employee?.employee?.fullName?.slice(0, 12)}
                            {employee?.employee?.fullName?.length > 12 ? '...' : ''} */}
                          </span>
                        </td>
                        <td className="h-20 text-center text-purple1 font-bold">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(employee?.salary?.[0]?.netSalary)}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.cpf[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.cpf[0]?.CPFContribution)
                            : 'N/A'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.allowances[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.allowances[0]?.totalAllowance)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.claims[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.claims[0]?.totalClaimAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.deductions[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.deductions[0]?.totalDeductionAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 w-24 flex justify-center items-center">
                          <button
                            className="hover:bg-blue-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                            onClick={() =>
                              navigate(`/payroll/history-payroll/view-employee`, {
                                state: { employee, historyPayroll }
                              })
                            }
                            title="View"
                          >
                            <Eye color="#000000" variant="Bold" size={20} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            company?._id && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Generated Payroll</div>
              </div>
            )
          )}
        </div>
        {!company?._id && (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Company Selected</div>
          </div>
        )}
        {currentEntries.length > 0 && (
          <PaginationFooter
            sortedList={sortedEmployees}
            pageNumbers={pageNumbers}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            indexOfFirstEntry={indexOfFirstEntry}
            indexOfLastEntry={indexOfLastEntry}
          />
        )}
      </div>
    </div>
  );
};

export default HistoryPayrollEmployeeList;
