import { useEffect, useState } from 'react';
import { getAllCompanies } from '../../actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import CompanyList from './companyList';
import SkeletonLoader from '../../components/skeletonLoader';
import { useNavigate } from 'react-router-dom';
import AddCompany from './addCompany';
import LayoutWM from '../../layout/layoutWM';

const Companies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addCompanyModal, setAddCompanyModal] = useState(false);

  const allCompanies = useSelector((state) => state?.company?.companies);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  console.log(allCompanies);

  return (
    <LayoutWM pageTitle={'Companies Details'} page={'Companies'} subPage={'Companies'}>
      <div className="bg-white p-6 rounded-2xl">
        <div className="pb-5 flex flex-row justify-between">
          <h3 className="text-2xl font-bold text-black pb-5">Company Details</h3>
          <div className="flex flex-wrap gap-x-3 items-center">
            <button
              onClick={() => setAddCompanyModal(true)}
              className="h-12 w-36 bg-purple1 hover:bg-purple1/90 text-whiten font-semibold rounded-xl text-sm"
            >
              + Add Company
            </button>
          </div>
        </div>
        {addCompanyModal && <AddCompany setAddCompanyModal={setAddCompanyModal} />}
        <div className="w-full bg-white">
          {allCompanies ? <CompanyList allCompanies={allCompanies} /> : <SkeletonLoader length={5} />}
        </div>
      </div>
    </LayoutWM>
  );
};

export default Companies;
