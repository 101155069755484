import { useDispatch } from 'react-redux';
import PayrollTableHeader from './payrollTableHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Edit2, Eye } from 'iconsax-react';
import Layout from '../../../layout/layout';
import { useState } from 'react';
import { useFormik } from 'formik';
import { updatePayrollEmployee } from '../../../actions/payrollAction';
import AllTimesheetViewModal from '../../../components/allTimesheetViewModal';
import Dropdown from '../../../components/dropdown';
import { bankOptions } from '../../../constant/dropdownConstOptions';
import LayoutWM from '../../../layout/layoutWM';

const PayrollEmployeeViewEdit = () => {
  const location = useLocation();
  const { employee, currentPayroll } = location.state || {};

  console.log('emp: ', employee);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [isTimesheetModalOpen, setTimeSheetModalOpen] = useState(null);

  const formik = useFormik({
    initialValues: {
      fullName: employee.employee?.fullName,
      designation: employee.employee?.employmentDetails?.designationName,
      department: employee.employee?.employmentDetails?.departmentName,
      bank: employee.salary[0]?.bank || '',
      bankAccountNum: employee.salary[0]?.bankAccountNum || '',
      currency: employee.salary[0]?.currency || '',
      basicSalary: employee.salary[0]?.basicSalary || 0,
      timeSheets: employee.salary[0]?.timeSheets || [],
      payMethod: employee.salary[0]?.payMethod || '',
      employeeCPFPercentage: employee.salary[0]?.employeeCPFPercentage || 20,
      employerCPFPercentage: employee.salary[0]?.employerCPFPercentage || 17,
      employeeCPF: employee.salary[0]?.employeeCPF || 0,
      employerCPF: employee.salary[0]?.employerCPF || 0,
      grossSalary: employee.salary[0]?.grossSalary || 0,
      totalCPFContribution: employee.salary[0]?.totalCPFContribution || 0,
      netSalary: employee.salary[0]?.netSalary || 0,
      deductions: employee.deductions || [],
      allowances: employee.allowances || [],
      claims: employee.claims || [],
      cpf: {
        employeeCPF: employee.cpf[0]?.employeeCPF,
        employerCPF: employee.cpf[0]?.employerCPF,
        CPFContribution: employee.cpf[0]?.CPFContribution
      }
    },
    onSubmit: (values) => {
      let updatedEmployee = {};

      if (!values?.cpf?.CPFContribution) {
        updatedEmployee = {
          ...employee,
          salary: [
            {
              bank: values.bank,
              bankAccountNum: values.bankAccountNum,
              currency: values.currency,
              timeSheets: values.timeSheets,
              basicSalary: parseFloat(values.basicSalary),
              grossSalary: values.grossSalary,
              netSalary: values.netSalary,
              payMethod: values.payMethod
            }
          ],
          cpf: [],
          allowances: values.allowances,
          deductions: values.deductions,
          claims: values.claims,
          currentPayrollId: currentPayroll?._id
        };
      } else {
        updatedEmployee = {
          ...employee,
          salary: [
            {
              bank: values.bank,
              bankAccountNum: values.bankAccountNum,
              currency: values.currency,
              timeSheets: values.timeSheets,
              basicSalary: parseFloat(values.basicSalary),
              employeeCPF: values.employeeCPF,
              employerCPF: values.employerCPF,
              employeeCPFPercentage: values.employeeCPFPercentage,
              employerCPFPercentage: values.employerCPFPercentage,
              grossSalary: values.grossSalary,
              totalCPFContribution: values.totalCPFContribution,
              netSalary: values.netSalary,
              payMethod: values.payMethod
            }
          ],
          cpf: [
            {
              employeeCPF: values.cpf.employeeCPF,
              employerCPF: values.cpf.employerCPF,
              CPFContribution: values.cpf.CPFContribution
            }
          ],
          allowances: values.allowances,
          deductions: values.deductions,
          claims: values.claims,
          currentPayrollId: currentPayroll?._id
        };
      }

      console.log('updatedEmployee: ', updatedEmployee);
      dispatch(updatePayrollEmployee(updatedEmployee, navigate));
    }
  });

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  const handleToggleEdit = () => {
    setIsEdit(!isEdit);

    if (isEdit) {
      formik.resetForm();
    }
  };

  const calculateSalariesAndCPF = (values) => {
    if (values?.cpf?.CPFContribution) {
      const basicSalary = parseFloat(values.basicSalary) || 0;

      const employeeCPF = (basicSalary * values.employeeCPFPercentage) / 100;
      const employerCPF = (basicSalary * values.employerCPFPercentage) / 100;

      // Calculate grossSalary (basicSalary - employeeCPF)
      const grossSalary = basicSalary - employeeCPF;

      // Calculate totalCPFContribution for salary
      const totalCPFContribution = employeeCPF + employerCPF;

      // Calculate netSalary
      const totalDeductionAmount = values.deductions[0]?.totalDeductionAmount;
      const netAllowanceAmount = values.allowances[0]?.netAllowanceAmount;
      const totalClaimAmount = values.claims[0]?.totalClaimAmount;

      const netSalary = basicSalary - employeeCPF - totalDeductionAmount + netAllowanceAmount + totalClaimAmount;

      // For cpf object (same calculations as salary, but stored separately)
      const cpfEmployeeCPF = employeeCPF + values?.allowances?.[0]?.allowanceEmployeeCPF;
      const cpfEmployerCPF = employerCPF + values?.allowances?.[0]?.allowanceEmployerCPF;
      const cpfContribution = cpfEmployeeCPF + cpfEmployerCPF;

      return {
        salary: {
          employeeCPF,
          employerCPF,
          grossSalary,
          totalCPFContribution,
          netSalary
        },
        cpf: {
          employeeCPF: cpfEmployeeCPF,
          employerCPF: cpfEmployerCPF,
          CPFContribution: cpfContribution
        }
      };
    } else {
      const basicSalary = parseFloat(values.basicSalary) || 0;

      const grossSalary = basicSalary;

      const totalDeductionAmount = values.deductions[0]?.totalDeductionAmount;
      const netAllowanceAmount = values.allowances[0]?.netAllowanceAmount;
      const totalClaimAmount = values.claims[0]?.totalClaimAmount;

      const netSalary = basicSalary - totalDeductionAmount + netAllowanceAmount + totalClaimAmount;

      return {
        salary: {
          grossSalary,
          netSalary
        }
      };
    }
  };

  const handleBasicSalaryChange = (e) => {
    formik.handleChange(e);
    const basicSalary = e.target.value === '' ? 0 : parseFloat(e.target.value);

    const updatedValues = {
      ...formik.values,
      basicSalary: basicSalary
    };

    const recalculatedValues = calculateSalariesAndCPF(updatedValues);

    if (!formik?.values?.cpf?.CPFContribution) {
      formik.setValues({
        ...formik.values,
        basicSalary: parseFloat(basicSalary),
        grossSalary: recalculatedValues.salary.grossSalary,
        netSalary: recalculatedValues.salary.netSalary
      });
    } else {
      formik.setValues({
        ...formik.values,
        basicSalary: parseFloat(basicSalary),
        employeeCPF: recalculatedValues.salary.employeeCPF,
        employerCPF: recalculatedValues.salary.employerCPF,
        grossSalary: recalculatedValues.salary.grossSalary,
        totalCPFContribution: recalculatedValues.salary.totalCPFContribution,
        netSalary: recalculatedValues.salary.netSalary,
        cpf: {
          ...formik.values.cpf,
          employeeCPF: recalculatedValues.cpf.employeeCPF,
          employerCPF: recalculatedValues.cpf.employerCPF,
          CPFContribution: recalculatedValues.cpf.CPFContribution
        }
      });
    }
  };

  return (
    <LayoutWM
      pageTitle={`${isEdit ? 'Edit' : 'View'} Employee`}
      page={'Payroll'}
      subPage={`${isEdit ? 'Edit' : 'View'} Employee`}
    >
      <div className="h-full">
        <PayrollTableHeader currentPayroll={currentPayroll} />
        <div className="bg-white min-h-full p-6 flex flex-col justify-start rounded-2xl">
          <div className="w-full flex flex-wrap justify-between mb-4 items-center gap-2">
            <div className="text-2xl font-bold text-black">Employee Payment Details</div>
            <div className="flex gap-x-2">
              {!isEdit && (
                <button
                  type="button"
                  onClick={() => navigate('/payroll/generate-payroll')}
                  className={`bg-red1 hover:bg-red1/90 text-white  font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3`}
                >
                  <span>Back</span>
                </button>
              )}
              {employee?.employee?.workingHourDetails?.workHoursType === 'Fixed' && (
                <button
                  type="button"
                  onClick={() => handleToggleEdit()}
                  className={`${isEdit ? 'bg-grayLight hover:bg-gray-300 text-purple1' : 'bg-purple1 hover:bg-purple1/90 text-white'}  font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3`}
                >
                  {!isEdit && <Edit2 color={isEdit ? '#3D54DD' : '#ffffff'} variant="Outline" size={25} />}
                  {isEdit && <Eye color={isEdit ? '#3D54DD' : '#ffffff'} variant="Outline" size={25} />}
                  <span>{isEdit ? 'View' : 'Edit'}</span>
                </button>
              )}
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="fullName"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                    disabled
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Designation</label>
                  <input
                    type="text"
                    placeholder="Designation"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="designation"
                    onChange={formik.handleChange}
                    value={formik.values.designation}
                    disabled
                  />
                  {formik.touched.designation && formik.errors.designation ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Department</label>
                  <input
                    type="text"
                    placeholder="Department"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="department"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                    disabled
                  />
                  {formik.touched.department && formik.errors.department ? (
                    <div className="text-red-500 text-sm">{formik.errors.department}</div>
                  ) : null}
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center pt-5">
                <span className="w-fit">Salary Details </span>
                <div className="border-b border-2 flex ml-5 flex-grow"></div>
              </div>
              <div className="flex flex-col gap-6 lg:flex-row py-5">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    {employee?.employee?.workingHourDetails?.workHoursType === 'Hourly'
                      ? 'Hourly Salary'
                      : 'Basic Salary'}{' '}
                    ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Basic Salary"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="basicSalary"
                    onChange={handleBasicSalaryChange}
                    onBlur={(e) => handleFloatChange(e.target.value, 'basicSalary')}
                    value={formik?.values?.basicSalary}
                    disabled={!isEdit}
                  />
                  {formik.touched.basicSalary && formik.errors.basicSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.basicSalary}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3"></div>
              </div>
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employee CPF Percentage (%)</label>
                    <input
                      type="number"
                      placeholder="Employee CPF Percentage"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employeeCPFPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.employeeCPFPercentage}
                      disabled
                    />
                    {formik.touched.employeeCPFPercentage && formik.errors.employeeCPFPercentage ? (
                      <div className="text-red-500 text-sm">{formik.errors.employeeCPFPercentage}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Employee CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Employee CPF"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employeeCPF"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.employeeCPF)}
                      disabled
                    />
                    {formik.touched.employeeCPF && formik.errors.employeeCPF ? (
                      <div className="text-red-500 text-sm">{formik.errors.employeeCPF}</div>
                    ) : null}
                  </div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employer CPF Percentage (%)</label>
                    <input
                      type="number"
                      placeholder="Employer CPF Percentage"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employerCPFPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.employerCPFPercentage}
                      disabled
                    />
                    {formik.touched.employerCPFPercentage && formik.errors.employerCPFPercentage ? (
                      <div className="text-red-500 text-sm">{formik.errors.employerCPFPercentage}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Employer CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Employer CPF"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employerCPF"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.employerCPF)}
                      disabled
                    />
                    {formik.touched.employerCPF && formik.errors.employerCPF ? (
                      <div className="text-red-500 text-sm">{formik.errors.employerCPF}</div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Gross Salary ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Gross Salary"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="grossSalary"
                    onChange={formik.handleChange}
                    value={new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(formik.values.grossSalary)}
                    disabled
                  />
                  {formik.touched.grossSalary && formik.errors.grossSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.grossSalary}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                  <label className="mb-2.5 block text-black font-bold">
                    Net Salary ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    onBlur={(e) => handleFloatChange(e.target.value, 'netSalary')}
                    value={new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(formik.values.netSalary)}
                    disabled
                  />
                  {formik.touched.netSalary && formik.errors.netSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.netSalary}</div>
                  ) : null}
                </div>
              </div>
              {employee?.employee?.workingHourDetails?.workHoursType === 'Hourly' && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5">
                  <div className="w-full lg:w-1/2">
                    <button
                      type="button"
                      onClick={() => setTimeSheetModalOpen(true)}
                      className="w-full flex font-bold justify-between items-center px-5 rounded-xl text-sm border-[1.5px] shadow-lg border-transparent bg-grayLight hover:bg-gray-200 h-12 text-black disabled:text-gray2 disabled:cursor-default disabled:bg-grayDark"
                    >
                      Timesheets
                      <button title="View">
                        <Eye color="#000000" variant="Bold" size={20} />
                      </button>
                    </button>
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="text-black font-bold text-xl flex items-center mt-10">
                  CPF <div className="border-b border-2 flex flex-grow ml-5"></div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row pt-5">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Employee CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Gross Salary"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="grossSalary"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.cpf.employeeCPF)}
                      disabled
                    />
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                    <label className="mb-2.5 block text-black font-bold">
                      Employer CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleFloatChange(e.target.value, 'netSalary')}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.cpf.employerCPF)}
                      disabled
                    />
                    {formik.touched.netSalary && formik.errors.netSalary ? (
                      <div className="text-red-500 text-sm">{formik.errors.netSalary}</div>
                    ) : null}
                  </div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      CPF Contribution ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Gross Salary"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="grossSalary"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.cpf.CPFContribution)}
                      disabled
                    />
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3"></div>
                </div>
              )}
              <div className="text-black font-bold text-xl flex items-center mt-10">
                Deductions <div className="border-b border-2 flex flex-grow ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-end">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.deductions[0]?.deductions?.map((deduction, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {deduction?.deductionType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Deduction Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`deductions[${index}].amount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `deductions[${index}].amount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(deduction.amount)}
                        disabled
                      />
                      {formik.touched.deductions?.[index]?.amount && formik.errors.deductions?.[index]?.amount ? (
                        <div className="text-red-500 text-sm">{formik.errors.deductions[index].amount}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Total Deductions ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    value={
                      employee?.deductions[0]
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(employee?.deductions[0]?.totalDeductionAmount)
                        : '0.00'
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                Allowances <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-start">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.allowances[0]?.allowances?.map((allowance, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {allowance?.allowanceType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Allowance Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`allowances[${index}].amount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `allowances[${index}].amount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(allowance.amount)}
                        disabled
                      />
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  <div>
                    <label className="mb-2.5 block text-black font-bold">
                      Total Allowance ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      value={
                        employee?.allowances[0]
                          ? new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(employee?.allowances[0]?.totalAllowance)
                          : '0.00'
                      }
                      disabled
                    />
                  </div>
                  {employee?.cpf?.length > 0 && (
                    <div>
                      <label className="mb-2.5 block text-black font-bold">
                        Allowance Employee CPF ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Net Pay"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name="netSalary"
                        onChange={formik.handleChange}
                        value={
                          employee?.allowances[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.allowances[0]?.allowanceEmployeeCPF)
                            : '0.00'
                        }
                        disabled
                      />
                    </div>
                  )}
                  {employee?.cpf?.length > 0 && (
                    <div>
                      <label className="mb-2.5 block text-black font-bold">
                        Allowance Employer CPF ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Net Pay"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name="netSalary"
                        onChange={formik.handleChange}
                        value={
                          employee?.allowances[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.allowances[0]?.allowanceEmployerCPF)
                            : '0.00'
                        }
                        disabled
                      />
                    </div>
                  )}
                  <div>
                    <label className="mb-2.5 block text-black font-bold">
                      Net Allowance ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      value={
                        employee?.allowances[0]
                          ? new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(employee?.allowances[0]?.netAllowanceAmount)
                          : '0.00'
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                Claims <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-end">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.claims[0]?.claims?.map((claim, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {claim?.claimType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Claim Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`claims[${index}].amount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `claims[${index}].amount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(claim.claimAmount)}
                        disabled
                      />
                      {formik.touched.claims?.[index]?.amount && formik.errors.claims?.[index]?.amount ? (
                        <div className="text-red-500 text-sm">{formik.errors.claims[index].amount}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Total Claims ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    value={
                      employee?.claims[0]
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(employee?.claims[0]?.totalClaimAmount)
                        : '0.00'
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                <span className="w-40">Bank Details </span>
                <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row pt-5">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Bank</label>
                  <Dropdown
                    value={bankOptions.find((option) => option.value === formik.values.bank)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('bank', selectedValue.value);
                    }}
                    options={bankOptions}
                    isClearable={false}
                    isSearchable={false}
                    menuPlacement="top"
                    placeholder="Select Bank"
                    name="bank"
                    id="bank"
                    isDisabled={true}
                  />
                  {formik.touched.bank && formik.errors.bank ? (
                    <div className="text-red-500 text-sm">{formik.errors.bank}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Account Number</label>
                  <input
                    type="number"
                    placeholder="Type Account Number"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="accountNumber"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountNum}
                    disabled
                  />
                  {formik.touched.bankAccountNum && formik.errors.bankAccountNum ? (
                    <div className="text-red-500 text-sm">{formik.errors.bankAccountNum}</div>
                  ) : null}
                </div>
              </div>
              {isEdit && (
                <div className="flex justify-end w-full gap-x-2 mt-10">
                  <button
                    type="button"
                    onClick={() => navigate('/payroll/generate-payroll')}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <AllTimesheetViewModal
        isOpen={isTimesheetModalOpen}
        timesheets={employee?.salary[0]?.timeSheets[0]?.timesheets}
        onClose={() => setTimeSheetModalOpen(false)}
        currency={employee?.salary[0]?.currency}
      />
    </LayoutWM>
  );
};

export default PayrollEmployeeViewEdit;
