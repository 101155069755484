import PayslipInitial from './payslipInitial';
import ManagerLayoutWM from '../../../layout/managerLayoutWM';

const ManagerPayslip = () => {
  return (
    <ManagerLayoutWM pageTitle={'Payslip'} page={'Payslip'} subPage={'Payslip'}>
      <div className="h-full">
        <PayslipInitial />
      </div>
    </ManagerLayoutWM>
  );
};

export default ManagerPayslip;
