import Layout from "../../../layout/layout";
import HistoryPayrollEmployeeList from "./historyPayrollEmployeeList";

const HistoryPayroll = () => {
  return (
    <Layout pageTitle={'History Payroll'} page={'Payroll'} subPage={'History Payroll'}>
      <div className='h-full'>
        <HistoryPayrollEmployeeList />
      </div>
    </Layout>
  );
};

export default HistoryPayroll;
