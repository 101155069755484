import { format } from 'date-fns';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import empty from '../../../assets/images/empty.png';

const UpcomingLeavesTable = ({ upcomingLeaves }) => {
  return (
    <div>
      <div className="text-2xl font-semibold text-black pb-5">Upcoming Leaves</div>
      {upcomingLeaves?.length > 0 ? (
        <table className="table-auto rounded-2xl w-full">
          <thead>
            <tr className="h-20 bg-grayLight text-black">
              <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
              <th>Full Name</th>
              <th>Leave Type</th>
              <th>From Date</th>
              <th className="rounded-e-xl">To Date</th>
            </tr>
          </thead>
          <tbody>
            {upcomingLeaves?.map((employee, index) => {
              const formattedFromDate = !Number.isNaN(new Date(employee?.upcomingLeave?.fromDate).getTime())
                ? format(new Date(employee?.upcomingLeave?.fromDate), 'dd-MM-yyyy')
                : 'Invalid Date';

              const formattedToDate = !Number.isNaN(new Date(employee?.upcomingLeave?.toDate).getTime())
                ? format(new Date(employee?.upcomingLeave?.toDate), 'dd-MM-yyyy')
                : 'Invalid Date';

              return (
                <tr className="h-20 text-sm" key={index}>
                  <td className="text-sm font-nunito font-semibold leading-normal text-black">
                    <div className="flex justify-center items-center h-20">
                      <img
                        src={employee?.employeeDetails?.avatar ? employee?.employeeDetails?.avatar : defaultPic}
                        alt="profile pic"
                        className="w-10 h-10 rounded-full object-cover"
                      />
                    </div>
                  </td>
                  <td className="h-20 text-center">{employee?.employeeDetails?.fullName}</td>
                  <td className="h-20 text-center">{employee?.upcomingLeave?.leaveType}</td>
                  <td className="h-20 text-center">{formattedFromDate}</td>
                  <td className="h-20 text-center">{formattedToDate}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full min-h-60 flex flex-col justify-center items-center rounded-xl">
          <img src={empty} alt="empty data" className="w-56" />
          <div className="mt-5 text-purple1 font-bold text-xl">No Upcoming Leave Data</div>
        </div>
      )}
    </div>
  );
};

export default UpcomingLeavesTable;
