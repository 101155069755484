import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { deductionSchema } from '../../../validations/validationSchemas';
import { addDeduction, deleteDeduction } from '../../../actions/companyAction';
import EditDeduction from './editDeduction';
import { Add, Edit2, Trash } from 'iconsax-react';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';

const DeductionDetails = ({ company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      deductionType: '',
      deductionCode: ''
    },
    enableReinitialize: true,
    validationSchema: deductionSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addDeduction(values, setEdit, resetForm));
    }
  });

  const handleModalDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleDelete = (claim, company) => {
    dispatch(
      deleteDeduction(
        {
          companyId: company?._id,
          _id: claim?._id
        },
        setIsConfirmationModalOpen
      )
    );
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Deduction</h3>
          {!edit && (
            <button
              className="w-16 h-8 rounded-lg flex items-center justify-center text-2xl font-bold bg-grayLight shadow-md"
              onClick={() => setEdit(true)}
            >
              <Add color="#3D54DD" variant="Linear" size={20} />
            </button>
          )}
        </div>
        {edit && (
          <div className="rounded-xl bg-white shadow-lg w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Deduction Type</label>
                    <input
                      type="text"
                      placeholder="Deduction Type"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="deductionType"
                      onChange={formik.handleChange}
                      value={formik.values.deductionType}
                    />
                    {formik.touched.deductionType && formik.errors.deductionType ? (
                      <div className="text-red-500 text-sm">{formik.errors.deductionType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Deduction Code</label>
                    <input
                      type="text"
                      placeholder="Deduction Code"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="deductionCode"
                      onChange={formik.handleChange}
                      value={formik.values.deductionCode}
                    />
                    {formik.touched.deductionCode && formik.errors.deductionCode ? (
                      <div className="text-red-500 text-sm">{formik.errors.deductionCode}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-end w-full gap-x-2">
                  <button
                    type="button"
                    onClick={() => setEdit(false)}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-28 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex flex-wrap gap-3 mt-5">
          {company?.deductions?.map((deduction, index) => {
            return (
              <div
                className={`p-6 relative bg-white shadow-lg flex-grow ${
                  editIndex === index ? 'w-full' : 'basis-[450px]'
                } rounded-2xl`}
                key={index}
              >
                {editIndex !== index && (
                  <div>
                    <div className="text-base text-black font-bold">{deduction?.deductionType}</div>
                    <div className="text-sm font-normal text-black/40">{deduction?.deductionCode}</div>
                  </div>
                )}

                {editIndex !== index && (
                  <div className="flex flex-row gap-x-1 absolute right-4 top-6">
                    <button
                      onClick={() => setEditIndex(index)}
                      type="button"
                      className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
                    >
                      <Edit2 color="#000000" variant="Bold" size={20} />
                    </button>
                    <button
                      onClick={() => handleModalDelete()}
                      type="button"
                      className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-600"
                    >
                      <Trash color="#C84040" variant="Bold" size={20} />
                    </button>
                    <DeleteConfirmationModal
                      isOpen={isConfirmationModalOpen}
                      onClose={() => setIsConfirmationModalOpen(false)}
                      handleDelete={() => handleDelete(deduction, company)}
                      title={'deduction'}
                    />
                  </div>
                )}
                {editIndex === index && <EditDeduction company={company} deduction={deduction} onClose={() => setEditIndex(null)} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DeductionDetails;
