import React, { useState } from 'react';
import Sidebar from '../components/sideBar/sideBar';
import Header from '../components/header';

const Layout = ({ children, page, subPage, pageTitle }) => {
  const storedSidebar = localStorage.getItem('sidebar') === 'true'; 
  const [sidebarOpen, setSidebarOpen] = useState(storedSidebar);

  return (
    <div className="flex">
      <div className={`fixed top-0 left-0 z-50 h-full ${sidebarOpen ? 'w-[310px]' : 'w-[75px]'} bg-gray-800`}>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </div>

      <div className={`flex-1 h-full ${sidebarOpen ? 'ml-[310px]' : 'ml-[75px]'}`}>
        <div className={`fixed top-0 left-0 z-40 w-full ${sidebarOpen ? 'pl-[310px]' : 'pl-[75px]'} bg-white`}>
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            page={page}
            subPage={subPage}
            pageTitle={pageTitle}
          />
        </div>

        <div className="mt-20 mx-auto p-4 md:p-6 2xl:p-10">
          <div className="min-w-[1200px] overflow-x-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
