import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { loginCandidateSchema } from '../../../validations/validationSchemas';
import { userLogin } from '../../../actions/userAction';
import { useDispatch } from 'react-redux';
import Banner from '../../../assets/images/loginbanner.jpeg';
import HRLogo from '../../../assets/logo/hrLogo.png';
import { useState } from 'react';
import { Eye, EyeSlash } from 'iconsax-react';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    // validationSchema: loginCandidateSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(userLogin(values, navigate));
    }
  });

  return (
    <div className="min-w-[320px] w-full h-screen flex flex-col lg:flex-row justify-center items-center bg-purple1 lg:bg-white overflow-y-hidden">
      <div className="w-full lg:w-1/2 flex items-center justify-end h-full relative">
        <div className="w-full flex flex-col items-center justify-center">
          <img src={HRLogo} alt="logo" className="lg:hidden flex w-28 mb-10" />

          <form onSubmit={formik.handleSubmit} className="max-w-[310px] md:max-w-[400px] w-full">
            <div className="text-4xl font-bold text-white lg:text-purple1 mb-5">Sign In</div>
            <div className="w-full">
              <div className="mb-4">
                <label className="mb-1 block text-white lg:text-black font-medium text-sm">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  className="w-full rounded-2xl border-[1px] placeholder:text-white/70 border-grayDark bg-transparent py-3 px-5 text-white lg:text-black outline-none transition lg:focus:border-primary lg:active:border-primary disabled:cursor-default disabled:bg-whiter"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500 text-sm">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="relative">
                <label className="mb-1 block text-white lg:text-black font-medium text-sm">Password</label>
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  className="w-full rounded-2xl border-[1px] placeholder:text-white/70 border-grayDark bg-transparent py-3 px-5 text-white lg:text-black outline-none transition lg:focus:border-primary lg:active:border-primary disabled:cursor-default disabled:bg-whiter"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-12 right-4 flex items-center cursor-pointer"
                >
                  {passwordVisible ? <EyeSlash size="24" color="gray" /> : <Eye size="24" color="gray" />}
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm">{formik.errors.password}</div>
                ) : null}
              </div>

              <div className="my-5 flex items-center justify-end">
                <Link to="/forgot-password" className="text-sm text-white lg:text-purple1 font-medium hover:underline">
                  Forget password?
                </Link>
              </div>

              <button
                type="submit"
                className="flex w-full justify-center rounded-2xl bg-white hover:bg-gray-100 lg:bg-purple1 p-3 font-bold text-purple1 lg:text-white lg:hover:bg-purple1/90"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4 text-xs text-center w-full text-white/80 lg:text-black/40 font-medium">
          © 2024 HRSimplify. All Rights Reserved.
        </div>
      </div>
      <div className="w-full lg:w-1/2 lg:h-full relative">
        <img
          src={Banner}
          alt="rightsidebanner"
          className="hidden lg:flex w-full h-full rounded-bl-[200px] object-cover object-left"
        />
        <img
          src={HRLogo}
          alt="logo"
          className="hidden lg:flex absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-48"
        />
        <div className="hidden lg:flex absolute z-50 bottom-0 right-5 mb-4 gap-x-6">
          <button type='button' className="text-sm font-medium text-white">License</button>
          <button type='button' className="text-sm font-medium text-white">Terms of Use</button>
        </div>
      </div>
    </div>
  );
};

export default Login;

// import { Link, useNavigate } from 'react-router-dom';
// import Logo from '../../../assets/logo/NewLogo.png';
// import { useFormik } from 'formik';
// import { loginCandidateSchema } from '../../../validations/validationSchemas';
// import { userLogin } from '../../../actions/userAction';
// import { useDispatch } from 'react-redux';

// const Login = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: ''
//     },
//     // validationSchema: loginCandidateSchema,
//     onSubmit: async (values) => {
//       console.log('value: ', values);
//       dispatch(userLogin(values, navigate));
//     }
//   });

//   return (
//     <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10 overflow-y-auto w-full h-screen flex flex-col justify-center items-center">
//       <div className="rounded-sm border border-stroke bg-white shadow-default max-w-[440px] w-full pb-5">
//         <div className="py-4 px-6">
//           <div className="w-full flex justify-center">
//             <img src={Logo} alt="logo" className="w-16 h-16 md:w-16 md:h-16" />
//           </div>
//           <h3 className="font-semibold text-black/80 text-center text-3xl">Welcome to Hexagonal HR Application</h3>
//         </div>
//         <form onSubmit={formik.handleSubmit}>
//           <div className="p-6">
//             <div className="mb-4">
//               <label className="mb-2.5 block text-black">Email</label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 placeholder="Enter your email address"
//                 className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
//                 onChange={formik.handleChange}
//                 value={formik.values.email}
//               />
//               {formik.touched.email && formik.errors.email ? (
//                 <div className="text-red-500 text-sm">{formik.errors.email}</div>
//               ) : null}
//             </div>

//             <div>
//               <label className="mb-2.5 block text-black">Password</label>
//               <input
//                 type="password"
//                 id="password"
//                 name="password"
//                 placeholder="Enter password"
//                 className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
//                 onChange={formik.handleChange}
//                 value={formik.values.password}
//               />
//               {formik.touched.password && formik.errors.password ? (
//                 <div className="text-red-500 text-sm">{formik.errors.password}</div>
//               ) : null}
//             </div>

//             <div className="my-5 flex items-center justify-end">
//               <Link to="#" className="text-sm text-primary hover:underline">
//                 Forget password?
//               </Link>
//             </div>

//             <button
//               type="submit"
//               className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray1 hover:bg-opacity-90"
//             >
//               Login
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Login;
