import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { employeeClaimSchema } from '../../../validations/validationSchemas';
import Dropdown from '../../../components/dropdown';
import { addClaim, deleteClaim } from '../../../actions/employeeAction';
import { Add, Edit2, Trash } from 'iconsax-react';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';
import EditClaim from './editClaim';

const ClaimDetails = ({ employee, company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const claimOptions =
    company?.claims?.map((claim) => ({
      label: `${claim?.claimType} - ${claim?.claimCode}`,
      value: claim?.claimType,
      claimType: claim?.claimType,
      claimCode: claim?.claimCode,
      claimId: claim?._id
    })) || [];

  const formik = useFormik({
    initialValues: {
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      claimId: '',
      claimType: '',
      claimCode: ''
    },
    validationSchema: employeeClaimSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addClaim(values, setEdit, resetForm));
    }
  });

  const handleModalDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleDelete = (claim) => {
    dispatch(
      deleteClaim(
        {
          _id: claim?._id,
          employeeId: employee?._id,
          companyId: employee?.company?._id,
        },
        setIsConfirmationModalOpen
      )
    );
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Claim Details</h3>
          {!edit && (
            <button
              className="w-16 h-8 rounded-lg flex items-center justify-center text-2xl font-bold bg-grayLight shadow-md"
              onClick={() => setEdit(true)}
            >
              <Add color="#3D54DD" variant="Linear" size={20} />
            </button>
          )}
        </div>
        {edit && (
          <div className="rounded-xl bg-white shadow-lg w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Claim Type</label>
                    <Dropdown
                      value={claimOptions.find((option) => option.claimType === formik.values.claimType)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) {
                          formik.setValues({
                            ...formik.values,
                            claimType: selectedValue.value,
                            claimCode: selectedValue.claimCode,
                            claimId: selectedValue.claimId
                          });
                        }
                      }}
                      options={claimOptions}
                      isClearable={false}
                      placeholder="Select Claim"
                      name="claimType"
                      id="claimType"
                    />
                    {formik.touched.claimType && formik.errors.claimType ? (
                      <div className="text-red-500 text-sm">{formik.errors.claimType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
                <div className="flex justify-end w-full gap-x-2">
                  <button
                    type="button"
                    onClick={() => setEdit(false)}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-28 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex flex-wrap gap-3 mt-5">
          {employee?.claimDetails?.claims?.map((claim, index) => {
            return (
              <div
                className={`p-6 relative bg-white shadow-lg flex-grow ${
                  editIndex === index ? 'w-full' : 'basis-x[450px]'
                } rounded-2xl`}
                key={index}
              >
                {editIndex !== index && (
                  <div>
                    <div className="text-base text-black font-bold">
                      {claim?.claimType} - {claim?.claimCode}
                    </div>
                  </div>
                )}

                {editIndex !== index && (
                  <div className="flex flex-row gap-x-1 absolute right-4 top-6">
                    <button
                      onClick={() => setEditIndex(index)}
                      type="button"
                      className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
                    >
                      <Edit2 color="#000000" variant="Bold" size={20} />
                    </button>
                    <button
                      onClick={() => handleModalDelete()}
                      type="button"
                      className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-600"
                    >
                      <Trash color="#C84040" variant="Bold" size={20} />
                    </button>
                    <DeleteConfirmationModal
                      isOpen={isConfirmationModalOpen}
                      onClose={() => setIsConfirmationModalOpen(false)}
                      handleDelete={() => handleDelete(claim)}
                      title={'claim'}
                    />
                  </div>
                )}
                {editIndex === index && (
                  <EditClaim
                    claim={claim}
                    onClose={() => setEditIndex(null)}
                    employee={employee}
                    claimOptions={claimOptions}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ClaimDetails;
