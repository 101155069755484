import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { getCurrentEmployee } from './employeeAction';
import { getCompanyById } from './companyAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_PAYSLIPS = 'FETCH_PAYSLIPS';
export const FETCH_LEAVES = 'FETCH_LEAVES';
export const FETCH_USER_LOGOUT = 'FETCH_USER_LOGOUT';

export const fetchPaySlips = (data) => ({
  type: FETCH_PAYSLIPS,
  payload: data
});

export const fetchLeaves = (data) => ({
  type: FETCH_PAYSLIPS,
  payload: data
});

export const fetchUserLogout = () => ({
  type: FETCH_USER_LOGOUT
})

export const userLogin = (inputData, navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/employee-login`, inputData);

      if (response.status === 200) {
        console.log('res: ', response);
        localStorage.setItem('token', response.data.token);
        await dispatch(getCurrentEmployee(response.data.employee.userId));
        ToastBar.success(response.data.successMsg[0]);
        
        const role = response.data.employee.role;

        if (role === "Admin") {
          navigate('/dashboard');
        }
        else if (role === "Manager") {
          await dispatch(getCompanyById({ companyId: response?.data?.employee?.companyId }));
          navigate('/manager/dashboard');
        }
        else if (role === "Employee") {
          await dispatch(getCompanyById({ companyId: response?.data?.employee?.companyId }));
          navigate('/user/dashboard');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const userLogout = (navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/employee-logout`, {}, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      );

      if (response.status === 200) {
        console.log('res: ', response);
        ToastBar.success(response.data.successMsg[0]);
        navigate('/login');
        setTimeout(() => {
          dispatch(fetchUserLogout());
        }, 500);
        localStorage.removeItem('token');
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getUserPayslips = (inputData, setLoader) => {
  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/get-payslips`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('payslips res: ', response.data);
        dispatch(fetchPaySlips(response.data));
        setLoader(false);
       // ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        dispatch(fetchPaySlips([]));
        setLoader(false);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        dispatch(fetchPaySlips([]));
        setLoader(false);
        ToastBar.error(error.message);
      }
    }
  };
};

export const applyLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/apply-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        ToastBar.success(response.data.successMsg[0]);
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/cancel-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const applyMedicalLeaveByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/apply-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editMedicalLeaveByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelMedicalLeaveByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/cancel-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const submitClaimByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/submit-claim`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editClaimByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/edit-claim`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelClaimByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/cancel-claim`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const addTimesheetByUser = (employeeId, companyId, inputData, toggleFormVisibility, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/add-timesheet`, { employeeId, companyId, inputData },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        toggleFormVisibility();
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateTimesheetByUser = (employeeId, companyId, inputData, onClose, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/update-timesheet`, { employeeId, companyId, inputData },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        onClose();
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteTimesheetByUser = (inputData, setIsConfirmationModalOpen) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/delete-timesheet`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputData?.employeeId));
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};