import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../assets/images/defaultCompany.png';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit2, PlayCircle, StopCircle } from 'iconsax-react';

const CompanyList = ({ allCompanies }) => {
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'ascending'
  });

  const sortedCompanies = useMemo(() => {
    if (!allCompanies) return [];

    let sortableCompanies = [...allCompanies];
    sortableCompanies.sort((a, b) => {
      if (sortConfig.key === 'fullName') {
        const nameA = `${a.fullName}`.toLowerCase();
        const nameB = `${b.fullName}`.toLowerCase();

        if (sortConfig.direction === 'ascending') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      } else {
        return 0;
      }
    });

    return sortableCompanies;
  }, [allCompanies, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  return (
    <table className="table-auto table-fixed rounded-2xl w-full">
      <thead>
        <tr className="h-20 bg-grayLight">
          <th className="w-20 rounded-s-xl "></th>
          <th onClick={() => handleSort('fullName')} className="cursor-pointer text-base font-bold text-black w-1/4">
            Company Name
            <FontAwesomeIcon icon={getSortIcon('fullName')} size="sm" className="ml-1" />
          </th>
          <th
            onClick={() => handleSort('jobHiringStage')}
            className="cursor-pointer text-base font-bold text-black w-1/4"
          >
            UEN
            <FontAwesomeIcon icon={getSortIcon('jobHiringStage')} size="sm" className="ml-1" />
          </th>
          <th className="text-base font-bold text-black w-1/4">Status</th>
          <th className="rounded-e-xl text-base font-bold text-black w-1/4">Actions</th>
        </tr>
      </thead>
      <tbody>
        {sortedCompanies?.map((company, index) => {
          return (
            <tr className="h-20" key={index}>
              <td className="h-20 text-sm text-start text-black flex items-center justify-center w-20">
                <img
                  src={company?.companyLogo ? company?.companyLogo : defaultPic}
                  alt="profile pic"
                  className="w-8 h-8 rounded-full object-cover"
                />
              </td>
              <td className="h-20 text-center text-sm text-black w-1/4">{company?.companyName}</td>
              <td className="h-20 text-center text-sm text-black w-1/4">{company?.uen}</td>
              <td className="h-20 w-1/4">
                <div className="flex justify-center">
                  <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                    <div className="w-2.5 h-2.5 rounded-full bg-green1"></div>
                    <span className="text-xs text-green1">Active</span>
                  </div>
                </div>
              </td>
              <td className="w-1/4">
                <div className="flex justify-center gap-x-2 items-center">
                  <div
                    className="hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                    onClick={() => navigate(`/settings/company/${company?._id}`)}
                    title="Edit"
                  >
                    <Edit2 color="#000000" variant="Bold" size={20} />
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CompanyList;
