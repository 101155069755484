import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { fetchSearchEmployees } from './employeeAction';
import { fetchDashboardDetails } from './companyAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const searchEmployeesByDepartment = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/manager/search-employees-by-dep`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('search-employees-by-dep: ', response.data);
        dispatch(fetchSearchEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const searchHourlyEmployeesByDepartment = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/manager/search-hourly-employees-by-dep`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('search-employees-by-dep: ', response.data);
        dispatch(fetchSearchEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getManagerDashboard = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/manager/get-manager-dashboard`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchDashboardDetails(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};