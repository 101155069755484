import { FETCH_COMPANY_SUCCESS, FETCH_DASHBOARD_DETAILS } from '../actions/companyAction';
import { FETCH_ALL_COMPANIES } from '../actions/companyAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  companies: [],
  company: {},
  dashboardDetails: {}
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload.company || {}
      };

    case FETCH_ALL_COMPANIES:
      return {
        ...state,
        companies: action.payload.companies
      };

    case FETCH_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardDetails: action.payload
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default companyReducer;
