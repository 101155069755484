import { useDispatch } from 'react-redux';
import { EnvelopeIcon, ChatBubbleBottomCenterIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo/hrLogoBlue.png';
import { emailCheck, getForgetPasswordOTP } from '../../../actions/accountAction';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [isExist, setIsExist] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [touch, setTouch] = useState(null);

  console.log('email: ', email);
  console.log('isExist: ', isExist);
  console.log('isValid: ', isValid);

  const validEmailCheck = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setIsValid(isValidEmail);
    const check = await dispatch(emailCheck(email));
    console.log('check: ', check);
    setTouch(true);
    setIsExist(true);
    setIsExist(check);
  };

  const onSubmit = () => {
    dispatch(getForgetPasswordOTP({ email: email }, navigate));
  };
  return (
    <div className="h-screen relative min-w-[320px]">
      <div
        className="absolute left-0 top-0 flex-col justify-center items-center p-10 cursor-pointer"
        onClick={() => navigate('/login')}
      >
        <img src={logo} alt="Logo" className="w-16 lg:w-24" />
      </div>
      <div className="flex flex-col h-full py-10 justify-center items-center px-5 md:px-0">
        <div className="bg-white shadow-xl w-full md:w-[500px] px-5 md:px-20 py-10 rounded-2xl">
          <div className="text-2xl md:text-3xl font-bold pb-8 text-purple1">Forgot Password</div>
          <div className="flex flex-col gap-y-5">
            <div className="relative">
              <input
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validEmailCheck}
                className="w-full rounded-xl pl-14 text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
              />
              <EnvelopeIcon className="w-7 h-7 absolute left-3 top-2.5" />
              {touch && isExist ? (
                <CheckIcon className="w-7 h-7 absolute right-3 top-3 stroke-green-400 stroke-2" />
              ) : (
                touch && <XMarkIcon className="w-7 h-7 absolute right-3 top-3 stroke-red-400 stroke-2" />
              )}
              {touch && !isValid && <div className="text-red-400 font-semibold text-xs">Email is not valid</div>}
              {touch && isValid && !isExist && (
                <div className="text-red-400 font-semibold text-xs">Email is not registered</div>
              )}
            </div>
            <button
              disabled={!isExist}
              type="button"
              onClick={onSubmit}
              className="bg-purple1 disabled:bg-gray-400 h-12 rounded-xl text-white font-bold hover:bg-purple1/90 shadow-xl flex justify-center items-center"
            >
              <ChatBubbleBottomCenterIcon className="w-5 h-5 mr-2 stroke-2" />
              Get OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
