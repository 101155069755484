import { FETCH_EMPLOYEE_SUCCESS, FETCH_PENDING_MEDICALS } from '../actions/employeeAction';
import { FETCH_ALL_EMPLOYEES } from '../actions/employeeAction';
import { FETCH_SEARCH_EMPLOYEES } from '../actions/employeeAction';
import { FETCH_PENDING_LEAVES } from '../actions/employeeAction';
import { FETCH_APPROVER_MANAGERS } from '../actions/employeeAction';
import { FETCH_CURRENT_EMPLOYEE } from '../actions/employeeAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  current: {},
  allEmployees: [],
  searchEmployees: [],
  pendingLeaves: [],
  pendingMedicals: [],
  approvers: [],
  user: [],
  frontEnd: [{ id: 'generalView', label: ['General View'], value: 0 }],
  backEnd: [
    { id: 'accessControlPermission', label: ['Allow User Access Control'], value: 1 },
    { id: 'addPropertyPermission', label: ['Add Property'], value: 2 },
    { id: 'createUserPermission', label: ['View/Edit Users'], value: 3 }
  ]
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_EMPLOYEE:
      return {
        ...state,
        user: action.payload.employee
      };

    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        current: action.payload.employee
      };

    case FETCH_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload.employees
      };

    case FETCH_SEARCH_EMPLOYEES:
      return {
        ...state,
        searchEmployees: action.payload.employees
      };

    case FETCH_PENDING_LEAVES:
      return {
        ...state,
        pendingLeaves: action.payload.pendingLeaves
      };
    case FETCH_APPROVER_MANAGERS:
      return {
        ...state,
        approvers: action.payload.approvers
      };
    case FETCH_PENDING_MEDICALS:
      return {
        ...state,
        pendingMedicals: action.payload.pendingMedicals
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default employeeReducer;
