import { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import DatePicker from '../../../components/DatePicker';
import { ToastBar } from '../../../components/toastbar';
import { useFormik } from 'formik';
import { submitClaimSchema } from '../../../validations/validationSchemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { format } from 'date-fns';
import { Edit2, MinusCirlce, Eye } from 'iconsax-react';
import { CancelClaimModal } from './cancelClaimModal';
import EditClaim from './editClaim';
import empty from '../../../assets/images/empty.png';
import { DocumentUpload } from 'iconsax-react';
import { ViewDocumentModal } from './viewDocumentModal';
import UserLayout from '../../../layout/userLayout';
import { cancelClaimByUser, submitClaimByUser } from '../../../actions/userAction';
import Compressor from 'compressorjs';

const UserSubmitClaim = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingClaim, setEditingClaim] = useState(null);
  const [claimToCancel, setClaimToCancel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [claimFile, setClaimFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToView, setFileToView] = useState(null);

  const user = useSelector((state) => state?.employee?.user);

  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'ascending'
  });

  console.log('user: ', user);

  const formik = useFormik({
    initialValues: {
      claimType: '',
      claimDate: '',
      claimAmount: '',
      claimRemark: '',
      claimFile: null
    },
    validationSchema: submitClaimSchema,
    onSubmit: (values) => {
      handleSubmitClaim(values);
    }
  });

  const handleUploadFile = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // Handle image file compression (JPEG, JPG, PNG)
    if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      new Compressor(file, {
        quality: 0.6, // Adjust quality to compress more or less
        success(compressedBlob) {
          console.log('Compressed image size:', compressedBlob.size);

          // Check if the compressed file exceeds 2MB after compression
          if (compressedBlob.size > 2 * 1024 * 1024) {
            ToastBar.warning("Compressed image size exceeds 2MB");
            return;
          }

          // If within limits, set the file
          setClaimFile(compressedBlob);
          formik.setFieldValue('claimFile', compressedBlob);
        },
        error(err) {
          console.error('Compression error:', err);
          ToastBar.error('Failed to compress the image.');
        },
      });
    }
    // Handle PDF files
    else if (file.type === 'application/pdf') {
      if (file.size > 2 * 1024 * 1024) {
        ToastBar.warning("PDF file size exceeds 2MB");
        return;
      }
      setClaimFile(file);
      formik.setFieldValue('claimFile', file);
    }
    // Unsupported file type
    else {
      ToastBar.error("Unsupported file type. Please upload a JPEG, JPG, PNG, or PDF file.");
    }
  };

  const handleClearFile = () => {
    setClaimFile(null);
    formik.setFieldValue('claimFile', null);
    fileInputRef.current.value = '';
  };

  const handleSubmitClaim = async (values) => {
    const { claimType, claimAmount, claimDate, claimFile, claimRemark } = values;

    const claim = user.claimDetails.claims.find((claim) => claim.claimType === claimType);

    if (!claim) {
      ToastBar.error('Please select a valid claim type.');
      return;
    }

    if (claim && numberOfDays > claim.balanceDays) {
      ToastBar.warning('The number of days exceeds the balance days available.');
      return;
    }

    const formData = new FormData();

    formData.append('claimType', claimType);
    formData.append('claimCode', claim.claimCode);
    formData.append('claimAmount', claimAmount);
    formData.append('claimDate', claimDate);
    formData.append('claimRemark', claimRemark);
    formData.append('status', 'Pending');
    formData.append('claimId', claim.claimId);
    formData.append('companyId', user?.company?._id);
    formData.append('employeeId', user?._id);

    if (claimFile) {
      formData.append('file', claimFile, `claimFile_${user?._id}`);
    }

    const result = await dispatch(submitClaimByUser(formData, user?._id));
    console.log(result);
    if (result.success) {
      setIsFormVisible(false);
      setClaimFile(null);
      formik.setFieldValue('claimFile', null);
      fileInputRef.current.value = '';
    }
  };

  const resetFormState = () => {
    formik.resetForm();
    setEditingClaim(null);
    setNumberOfDays(0);
  };

  const toggleFormVisibility = () => {
    resetFormState();
    setIsFormVisible(!isFormVisible);
    setClaimFile(null);
  };

  const handleEditClick = (claim) => {
    resetFormState();
    setEditingClaim(claim);
    setIsFormVisible(true);
  };

  const handleCancelClaim = (claim) => {
    setClaimToCancel(claim);
  };

  const confirmCancelClaim = () => {
    if (claimToCancel) {
      const updatedClaimToCancel = {
        ...claimToCancel,
        companyId: user?.company._id,
        employeeId: user._id
      };
      dispatch(cancelClaimByUser(updatedClaimToCancel));
      setClaimToCancel(null);
    }
  };

  const closeModal = () => {
    setClaimToCancel(null);
  };

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  const handleView = (file) => {
    setFileToView(file);
    setIsModalOpen(true);
  };

  const closeViewModal = () => {
    setIsModalOpen(false);
    setFileToView(null);
  };

  const claimOptions =
    user?.claimDetails?.claims.map((claim) => ({
      label: claim.claimType,
      value: claim.claimCode
    })) || [];

  const sortedClaimsHistory = useMemo(() => {
    if (!user || !user.claimDetails || !user.claimDetails.claimsHistory) {
      return [];
    }

    let sortableClaims = [...user.claimDetails.claimsHistory];
    sortableClaims.sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'claimType':
          aValue = a.claimType?.toLowerCase() || '';
          bValue = b.claimType?.toLowerCase() || '';
          break;
        case 'claimCode':
          aValue = a.claimCode?.toLowerCase() || '';
          bValue = b.claimCode?.toLowerCase() || '';
          break;
        case 'claimDate':
          aValue = !Number.isNaN(new Date(a.claimDate).getTime()) ? new Date(a.claimDate).getTime() : 0;
          bValue = !Number.isNaN(new Date(b.claimDate).getTime()) ? new Date(b.claimDate).getTime() : 0;
          break;
        case 'claimAmount':
          aValue = a.claimAmount || 0;
          bValue = b.claimAmount || 0;
          break;
        case 'createdDate':
          aValue = !Number.isNaN(new Date(a.createdDate).getTime()) ? new Date(a.createdDate).getTime() : 0;
          bValue = !Number.isNaN(new Date(b.createdDate).getTime()) ? new Date(b.createdDate).getTime() : 0;
          break;
        case 'status':
          aValue = a.status?.toLowerCase() || '';
          bValue = b.status?.toLowerCase() || '';
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    return sortableClaims;
  }, [user, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'Approved':
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 'Processing':
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 'Pending':
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 'Cancelled':
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedClaimsHistory.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedClaimsHistory.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <UserLayout pageTitle={'Submit Claims'} page={'Submit Claims'} subPage={'Submit Claims'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          {/* <h3 className="text-4xl font-semibold text-black pb-5">Employees</h3> */}
          <div className="flex justify-between">
            <div className="flex w-3/4 gap-x-4">
              <input
                type="text"
                placeholder="Department"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                name="department"
                value={user?.employmentDetails?.departmentCode}
                disabled
              />
              <input
                type="text"
                placeholder="Department"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                name="department"
                value={user?.fullName}
                disabled
              />
            </div>
            <button
              onClick={toggleFormVisibility}
              disabled={!user || !claimOptions?.length > 0}
              className={
                isFormVisible
                  ? 'h-12 w-36 font-bold rounded-xl border-2 bg-red1 text-white hover:bg-red1/90 disabled:bg-gray-400'
                  : 'h-12 w-36 bg-purple1 hover:bg-purple1/90 text-whiten font-bold rounded-xl disabled:bg-gray-400'
              }
            >
              {isFormVisible ? 'Cancel' : 'Submit Claim'}
            </button>
          </div>

          {/* Edit Leave Application Form */}
          {editingClaim && isFormVisible && (
            <EditClaim
              claim={editingClaim}
              onCancel={() => {
                resetFormState();
                setIsFormVisible(false);
              }}
              user={user}
            />
          )}

          {/* Leave Application Form */}
          {!editingClaim && isFormVisible && user && (
            <div className="bg-white p-6 rounded-2xl shadow-lg mt-5">
              <h4 className="text-2xl font-bold mb-5 text-black">Submit Claim</h4>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Select Claim Type</label>
                    <Dropdown
                      options={claimOptions}
                      value={claimOptions.find((option) => option.label === formik.values.claimType)}
                      onChange={(option) => {
                        formik.setFieldValue('claimType', option.label);
                        formik.setFieldTouched('claimType', true, false);
                      }}
                      placeholder="Select Claim Type"
                      name="claimType"
                      id="claimType"
                    />
                    {formik.touched.claimType && formik.errors.claimType ? (
                      <div className="text-red-500 text-sm">{formik.errors.claimType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
                <div className="mb-7 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Claim Date</label>
                    <DatePicker
                      value={formik.values.claimDate}
                      onChange={(date) => {
                        formik.setFieldValue('claimDate', date);
                        formik.setFieldTouched('claimDate', true, false);
                      }}
                    />
                    {formik.touched.claimDate && formik.errors.claimDate ? (
                      <div className="text-red-500 text-sm">{formik.errors.claimDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Amount ({user?.salaryDetails?.currency})</label>
                  <input
                    type="number"
                    placeholder="Amount"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="claimAmount"
                    onChange={formik.handleChange}
                    onBlur={(e) => handleFloatChange(e.target.value, 'claimAmount')}
                    value={formik.values.claimAmount}
                  />
                  {formik.touched.claimAmount && formik.errors.claimAmount ? (
                    <div className="text-red-500 text-sm">{formik.errors.claimAmount}</div>
                  ) : null}
                </div>

                <div className="w-full lg:w-1/2 mt-5">
                  <label className="mb-2.5 block text-black font-bold">Upload Document</label>
                  <div className="relative flex items-center">
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".jpeg, .jpg, .png, .pdf"
                      onChange={handleUploadFile}
                      style={{ display: 'none' }}
                    />
                    <input
                      type="text"
                      placeholder="No file chosen"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 pr-12 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      value={claimFile ? claimFile.name : ''}
                      readOnly
                      onClick={() => fileInputRef.current.click()}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      {claimFile ? (
                        <MinusCirlce
                          onClick={handleClearFile}
                          color="#C84040"
                          variant="Bold"
                          size={20}
                          className="cursor-pointer"
                          title="Clear"
                        />
                      ) : (
                        <DocumentUpload
                          onClick={() => fileInputRef.current.click()}
                          color="#555555"
                          variant="Bold"
                          size={20}
                          className="cursor-pointer"
                          title="Upload file"
                        />
                      )}
                    </div>
                  </div>
                  {formik.touched.claimFile && formik.errors.claimFile ? (
                    <div className="text-red-500 text-sm">{formik.errors.claimFile}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 mt-5">
                  <label className="mb-2.5 block text-black font-bold">Remark</label>
                  <textarea
                    type="text"
                    rows={6}
                    placeholder="Remark"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    name="claimRemark"
                    onChange={formik.handleChange}
                    value={formik.values.claimRemark}
                  />
                  {formik.touched.claimRemark && formik.errors.claimRemark ? (
                    <div className="text-red-500 text-sm">{formik.errors.claimRemark}</div>
                  ) : null}
                </div>

                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    className="bg-gray-500 hover:bg-gray-700 text-white font-semibold rounded-md px-4 py-2 mr-2"
                    onClick={toggleFormVisibility}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2"
                  >
                    Submit Claim
                  </button>
                </div>
              </form>
            </div>
          )}
          {currentEntries.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th>Full Name</th>
                    <th onClick={() => handleSort('claimType')} className="cursor-pointer">
                      Claim Type
                      <FontAwesomeIcon icon={getSortIcon('claimType')} size="sm" className="ml-1" />
                    </th>
                    {/* <th onClick={() => handleSort('claimCode')} className="cursor-pointer">
                      Claim Code
                      <FontAwesomeIcon icon={getSortIcon('claimCode')} size="sm" className="ml-1" />
                    </th> */}
                    <th onClick={() => handleSort('claimDate')} className="cursor-pointer">
                      Claim Date <FontAwesomeIcon icon={getSortIcon('claimDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimAmount')} className="cursor-pointer">
                      Claim Amount <FontAwesomeIcon icon={getSortIcon('claimAmount')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                      Created Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
                    </th>
                    <th>Remark</th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-e-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((claim, index) => {
                    const formattedClaimDate = !Number.isNaN(new Date(claim?.claimDate).getTime())
                      ? format(new Date(claim?.claimDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedCreatedDate = !Number.isNaN(new Date(claim?.createdDate).getTime())
                      ? format(new Date(claim?.createdDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(claim?.status);

                    return (
                      <tr className="h-20 text-sm" key={index}>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={user?.avatar ? user?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center">{user?.fullName}</td>
                        {/* <td className="h-20 text-center" title={user?.fullName}>
                          {user?.fullName?.slice(0, 12)}
                          {user?.fullName?.length > 12 ? '...' : ''}
                        </td> */}
                        <td className="h-20 text-center">{claim?.claimType}</td>
                        {/* <td className="h-20 text-center">{claim?.claimCode}</td> */}
                        <td className="h-20 text-center">{formattedClaimDate}</td>

                        <td className="h-20 text-center">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(claim?.claimAmount)}
                        </td>
                        <td className="h-20 text-center">{formattedCreatedDate}</td>
                        <td className="h-20 text-center cursor-pointer">
                          <div className="relative group">
                            <div className="w-24 truncate cursor-pointer">{claim?.claimRemark}</div>
                            <div className="absolute left-0 top-0 w-60 p-3 bg-grayLight shadow-xl text-black rounded hidden group-hover:block z-50">
                              {claim?.claimRemark}
                            </div>
                          </div>
                        </td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-end gap-x-2 items-center">
                            <div
                              className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                              onClick={() => handleView(claim?.claimFile)}
                              title="Edit"
                            >
                              <Eye color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                            </div>
                            {(claim?.status === 'Pending' || claim?.status === 'Rejected') && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-green-400 shadow-md"
                                onClick={() => handleEditClick(claim)}
                                title="Edit"
                              >
                                <Edit2 color="#000000" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                            {(claim?.status === 'Pending' || claim?.status === 'Approved') && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-100 shadow-md"
                                onClick={() => handleCancelClaim(claim)}
                                title="Cancel"
                              >
                                <MinusCirlce color="#C84040" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            user && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Claims History</div>
              </div>
            )
          )}
          {claimToCancel && <CancelClaimModal onCancel={closeModal} onConfirm={confirmCancelClaim} />}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedClaimsHistory.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && <ViewDocumentModal fileToView={fileToView} closeViewModal={closeViewModal} />}
    </UserLayout>
  );
};

export default UserSubmitClaim;
