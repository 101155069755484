import React, { useEffect, useState  } from 'react';
import { useSearchParams } from 'react-router-dom';

const IRASCallBackUrl = () => {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState('');
  const [state, setState] = useState('');
  
  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');


    if (code && state) {
      // Handle the retrieved code and state here
      console.log('Authorization Code:', code);
      console.log('State:', state);
      setCode(code);
      setState(state);

      // You can make a request to your backend to handle the authorization code
      // For example:
      // fetch('/api/handle-auth-code', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ code, state })
      // })
      // .then(response => response.json())
      // .then(data => {
      //   // Handle response data
      //   console.log(data);
      // })
      // .catch(error => {
      //   // Handle error
      //   console.error('Error:', error);
      // });
    } else {
      console.error('Missing code or state in callback URL');
    }
  }, [searchParams]);

  return (
    <div>
      <h1>IRAS Callback</h1>
      <p>Handling the callback from IRAS...</p>
      <p>Authorization Code: {code}</p>
      <p>State: {state}</p>
    </div>
  );
};

export default IRASCallBackUrl;
