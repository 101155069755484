const PaginationHeader = ({ entriesPerPage, handleEntriesPerPageChange }) => {
  return (
    <div className="flex justify-end items-center pb-3">
      <div className="flex items-center font-semibold text-black">
        <label htmlFor="entriesPerPage" className="mr-2">
          Show
        </label>
        <select
          id="entriesPerPage"
          value={entriesPerPage}
          onChange={handleEntriesPerPageChange}
          className="border border-gray-300 rounded-md p-1 pl-3"
        >
          {[5, 10, 20, 50].map((num) => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
        <span className="ml-2">Records</span>
      </div>
    </div>
  );
};

export default PaginationHeader;
