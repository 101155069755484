import React from 'react';

const TreeNodeComponent = ({ label, imageSrc }) => {
  return (
    <div className="flex flex-col items-center p-2">
      <img src={imageSrc} alt={label} className="w-16 h-16 rounded-full mb-2 object-cover" />
      <div className="text-center text-base font-medium text-black">{label}</div>
    </div>
  );
};

export default TreeNodeComponent;
