import { useEffect, useState } from 'react';
import Layout from '../../../layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import LeaveDetails from './leaveDetails';
import SkeletonLoader from '../../../components/skeletonLoader';
import ClaimDetails from './claimDetails';
import AllowanceDetails from './allowanceDetails';
import DeductionDetails from './deductionDetails';
import PayrollCutoffDateDetails from './payrollCutoffDateDetails';
import CompanyDropdown from '../../../components/companyDropdown';
import MedicalLeaveDetails from './medicalLeaveDetails';
import LayoutWM from '../../../layout/layoutWM';
import PublicHolidayDetails from './publicHolidayDetails';

const Miscellaneous = () => {
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company?.company);
  const companies = useSelector((state) => state.company?.companies || []);

  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(company?.companyName || '');

  useEffect(() => {
    dispatch(getAllCompanies());
    setLoading(false);
  }, [dispatch]);

  const companyOptions =
    companies?.map((company) => ({
      companyId: company?._id,
      label: company?.companyName,
      value: company?.companyName
    })) || [];

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';

    if (selectedValue) {
      setSelectedCompany(selectedValue);
      // dispatch(getMiscellaneousByCompanyId({ companyId: selectedOption?.companyId }));
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  console.log('com: ', company);

  return (
    <LayoutWM pageTitle={'Miscellaneous'} page={'Settings'} subPage={'Miscellaneous'}>
      <div className="flex flex-col justify-center items-start w-full">
        {loading ? (
          <SkeletonLoader length={8} />
        ) : (
          <div className="w-full space-y-4">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Select Company</label>
              {companyOptions.length > 0 && (
                <CompanyDropdown
                  value={companyOptions.find((option) => option.value === selectedCompany)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={false}
                  placeholder="Select Company"
                  name="allowanceType"
                  id="allowanceType"
                />
              )}
            </div>
            <LeaveDetails company={company} />
            <MedicalLeaveDetails company={company} />
            <ClaimDetails company={company} />
            <AllowanceDetails company={company} />
            <DeductionDetails company={company} />
            <PayrollCutoffDateDetails company={company} />
            <PublicHolidayDetails company={company} />
          </div>
        )}
      </div>
    </LayoutWM>
  );
};

export default Miscellaneous;
