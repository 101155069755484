import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const ViewDocumentModal = ({ fileToView, closeViewModal }) => {
    const [isImageError, setIsImageError] = useState(false);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
            <div className="bg-white rounded-lg p-6 relative max-w-3xl w-full">
                <button
                    onClick={closeViewModal}
                    className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>
                <h3 className="text-xl font-semibold mb-4">View Document</h3>
                {fileToView && (
                    <div className="flex justify-center">
                        {!isImageError ? (
                            <img
                                src={fileToView}
                                alt="Document"
                                className="max-h-96"
                                onError={() => setIsImageError(true)}
                            />
                        ) : (
                            <iframe
                                src={fileToView}
                                title="Document"
                                className="w-full h-96"
                            ></iframe>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};