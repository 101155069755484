import {
  FETCH_CURRENT_PAYROLL,
  FETCH_HISTORY_PAYROLL,
  FETCH_PAYSLIPS,
  SET_CLICKED_PAYSLIP,
  SET_SELECTED_PAYSLIP
} from '../actions/payrollAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  currentPayroll: {},
  historyPayroll: [],
  payslips: [],
  selectedPayslip: {},
  clickedPayslips: false
};

export const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_PAYROLL:
      return {
        ...state,
        currentPayroll: action.payload.payroll
      };
    case FETCH_HISTORY_PAYROLL:
      return {
        ...state,
        historyPayroll: action.payload.payroll
      };
    case FETCH_PAYSLIPS:
      return {
        ...state,
        payslips: action.payload.payslips
      };
    case SET_SELECTED_PAYSLIP:
      return {
        ...state,
        selectedPayslip: action.payload
      };
    case SET_CLICKED_PAYSLIP:
      return {
        ...state,
        clickedPayslips: action.payload
      };
    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default payrollReducer;
