import { useState } from 'react';
import EditPayrollCutoff from './editPayrollCutoff';
import { Edit2 } from 'iconsax-react';

const PayrollCutoffDateDetails = ({ company }) => {

  const [editIndex, setEditIndex] = useState(null);

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Payroll Cutoff Days</h3>
        </div>
        <div className="flex flex-wrap gap-3 mt-5">
          <div className={`p-6 relative bg-white shadow-lg flex-grow ${'basis-[450px]'} rounded-2xl`}>
            {!editIndex && (
              <div>
                <div className="text-base text-black font-bold">Day</div>
                <div className="text-sm font-normal text-black/40">{company?.payRollCutOff}</div>
              </div>
            )}

            <div className="flex flex-row gap-x-1 absolute right-4 top-6">
              <button
                onClick={() => setEditIndex(true)}
                type="button"
                className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
              >
                <Edit2 color="#000000" variant="Bold" size={20} />
              </button>
            </div>
            {editIndex && (
              <EditPayrollCutoff
                company={company}
                onClose={() => setEditIndex(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollCutoffDateDetails;
