import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit2, PlayCircle, StopCircle } from 'iconsax-react';

const EmployeeList = ({ allEmployees }) => {
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'ascending'
  });

  const sortedEmployees = useMemo(() => {
    if (!allEmployees) return [];

    let sortableEmployees = [...allEmployees];
    sortableEmployees.sort((a, b) => {
      let aValue, bValue;

      if (sortConfig.key === 'fullName') {
        aValue = `${a.fullName}`.toLowerCase();
        bValue = `${b.fullName}`.toLowerCase();
      } else if (sortConfig?.key === 'employmentDetails.designationName') {
        aValue = `${a.employmentDetails?.designationName || ''}`.toLowerCase();
        bValue = `${b.employmentDetails?.designationName || ''}`.toLowerCase();
      } else if (sortConfig?.key === 'employmentDetails.departmentName') {
        aValue = `${a.employmentDetails?.departmentName || ''}`.toLowerCase();
        bValue = `${b.employmentDetails?.departmentName || ''}`.toLowerCase();
      } else {
        return 0;
      }

      if (sortConfig.direction === 'ascending') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    return sortableEmployees;
  }, [allEmployees, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  return (
    <table className="table-auto rounded-2xl w-full">
      <thead>
        <tr className="h-20 bg-grayLight">
          <th className="rounded-s-xl text-base font-bold text-black">ID</th>
          <th className='text-base font-bold text-black'>Photo</th>
          <th onClick={() => handleSort('fullName')} className="cursor-pointer text-base font-bold text-black">
            Name
            <FontAwesomeIcon icon={getSortIcon('fullName')} size="sm" className="ml-1" />
          </th>
          <th
            onClick={() => handleSort('employmentDetails.designationName')}
            className="cursor-pointer text-base font-bold text-black"
          >
            Designation
            <FontAwesomeIcon icon={getSortIcon('employmentDetails.designationName')} size="sm" className="ml-1" />
          </th>
          <th onClick={() => handleSort('employmentDetails.departmentName')} className="text-base font-bold text-black cursor-pointer">
            Department <FontAwesomeIcon icon={getSortIcon('employmentDetails.departmentName')} />
          </th>
          <th className="text-base font-bold text-black">Status</th>
          <th className="rounded-e-xl text-base font-bold text-black">Control</th>
        </tr>
      </thead>
      <tbody>
        {sortedEmployees?.map((employee, index) => {
          return (
            <tr className={`h-20`} key={index}>
              <td className="text-sm text-center text-black">{employee?.empId}</td>
              <td className='h-20'>
                <div className='flex justify-center items-center'>
                <img
                  src={employee?.avatar ? employee?.avatar : defaultPic}
                  alt="profile pic"
                  className="w-8 h-8 rounded-full object-cover"
                />
                </div>
                
              </td>
              <td className="h-20 text-sm text-center text-black">
                <span>{employee?.fullName}</span>
              </td>
              <td className="h-20 text-center text-sm text-black">{employee?.employmentDetails?.designationName}</td>
              <td className="h-20 text-center text-sm text-black">{employee?.employmentDetails?.departmentName}</td>
              <td className="h-20 w-24">
                <div className="flex justify-center">
                  <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                    <div className="w-2.5 h-2.5 rounded-full bg-green1"></div>
                    <span className="text-xs text-green1">Active</span>
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center gap-x-2 items-center">
                  <div
                    className="hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                    onClick={() => navigate(`/employees/${employee?._id}`)}
                    title="Edit"
                  >
                    <Edit2 color="#000000" variant="Bold" size={20} />
                  </div>
                  {employee?.status === 1 ? (
                    <div
                      // onClick={() => handleStopJob(employee?._id)}
                      className="hover:bg-red-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                      title="Stop"
                    >
                      <StopCircle color="#000000" variant="Outline" size={20} />
                    </div>
                  ) : (
                    <div
                      // onClick={() => handleResumeJob(employee?._id)}
                      className="hover:bg-blue-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                      title="Resume"
                    >
                      <PlayCircle color="#000000" variant="Outline" size={20} />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default EmployeeList;
