import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import empty from '../../../assets/images/empty.png';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, DirectSend, DirectboxReceive, Eye } from 'iconsax-react';
import DefaultPic from '../../../assets/images/Default_profilepic.png';
// import { submitApproval } from '../../actions/payrollAction';
import * as XLSX from 'xlsx';
import { ToastBar } from '../../../components/toastbar';
import { format } from 'date-fns';
import PaginationFooter from '../../../components/paginationFooter';
import PaginationHeader from '../../../components/paginationHeader';

const PayslipResultList = ({ selectedPayslip, setSelectedPayslip }) => {
  // console.log('selectedPayslip: ', selectedPayslip)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'ascending'
  });
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(sortedEmployees);
    }
    setSelectAll(!selectAll);
  };

  const getFieldValue = (obj, path) => {
    switch (path) {
      case 'employee.salary[0].netSalary':
        return obj?.salary?.[0]?.netSalary || 0;
      case 'employee.cpf[0].CPFContribution':
        return obj?.cpf?.[0]?.CPFContribution || 0;
      case 'employee.allowances[0].totalAllowance':
        return obj?.allowances?.[0]?.totalAllowance || 0;
      case 'employee.claims[0].totalClaimAmount':
        return obj?.claims?.[0]?.totalClaimAmount || 0;
      case 'employee.deductions[0].totalDeductionAmount':
        return obj?.deductions?.[0]?.totalDeductionAmount || 0;
      default:
        return obj?.employee?.fullName?.toLowerCase() || '';
    }
  };

  const sortedEmployees = useMemo(() => {
    if (!selectedPayslip?.payrollDetails?.length) {
      return [];
    }

    let sortableGeneratedEmployees = [...selectedPayslip?.payrollDetails];

    sortableGeneratedEmployees.sort((a, b) => {
      const fieldA = getFieldValue(a, sortConfig.key);
      const fieldB = getFieldValue(b, sortConfig.key);

      if (typeof fieldA === 'number' && typeof fieldB === 'number') {
        return sortConfig.direction === 'ascending' ? fieldA - fieldB : fieldB - fieldA;
      } else {
        return sortConfig.direction === 'ascending' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      }
    });

    return sortableGeneratedEmployees;
  }, [selectedPayslip, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(employee)
        ? prevSelected.filter((selected) => selected !== employee)
        : [...prevSelected, employee]
    );
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedEmployees.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedEmployees.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const downloadExcel = () => {
    try {
      // const formattedUsers = generatedEmployeePaymentDetails.map((employee) => {
      //   const formatAmount = (amount) => (amount != null ? amount.toFixed(2) : '0.00');
      //   return {
      //     'Employee ID': employee._id || '',
      //     'Full Name': employee.name || '',
      //     'Paid Days': employee.paidDays || 0,
      //     'Gross Pay': formatAmount(employee.grossPay || 0),
      //     Deduction: formatAmount(employee.deductions || 0),
      //     Allowance: formatAmount(employee.allowance || 0),
      //     Claims: formatAmount(employee.claims || 0),
      //     'Net Pay': formatAmount(
      //       (employee.grossPay || 0) + (employee.allowance || 0) + (employee.claims || 0) - (employee.deductions || 0)
      //     )
      //   };
      // });
      // const worksheet = XLSX.utils.json_to_sheet(formattedUsers);
      // const workbook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(workbook, worksheet, 'Payroll');
      // // Adjust column width
      // const maxLength = {};
      // formattedUsers.forEach((row) => {
      //   Object.entries(row).forEach(([key, value]) => {
      //     const length = String(value).length;
      //     maxLength[key] = Math.max(maxLength[key] || 0, length);
      //   });
      // });
      // const colWidths = Object.values(maxLength).map((length) => ({ wch: length + 2 })); // +2 for padding
      // worksheet['!cols'] = colWidths;
      // const now = new Date();
      // const formattedDate = format(now, 'yyyy-MM-dd_HH-mm');
      // const filename = `generated_payroll_employees_${formattedDate}.xlsx`;
      // XLSX.writeFile(workbook, filename);
      // ToastBar.success('Excel Download Successful');
    } catch (error) {
      ToastBar.error('Error Downloading Excel');
    }
  };

  return (
    <div className="h-full">
      <div className="bg-white min-h-full p-6 flex flex-col justify-between rounded-2xl">
        <div className="flex flex-col h-full">
          <div className="w-full flex flex-wrap justify-between mb-4 items-center">
            <div className="flex flex-wrap items-center gap-x-3">
              <div className="text-2xl font-bold text-black">Payslips-{selectedPayslip?.month}</div>
            </div>
            <div className="flex flex-wrap gap-x-3 gap-y-1 items-center">
              <button
                type="button"
                onClick={() => setSelectedPayslip({})}
                className="text-black font-bold text-right flex items-center gap-x-3 text-sm"
              >
                <ArrowLeft color="#000000" variant="Outline" size={22} />
                <span>Back</span>
              </button>
              {/* <button
                type="button"
                // onClick={() => ()}
                className="bg-black/90 hover:bg-black/80 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3 text-sm"
              >
                <DirectSend color="#ffffff" variant="Outline" size={22} />
                <span>Send Salary Slip Via Email</span>
              </button>
              <button
                type="button"
                // onClick={() => }
                className="bg-black/90 hover:bg-black/80 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3 text-sm"
              >
                <DirectboxReceive color="#ffffff" variant="Outline" size={25} />
                <span>Download Salary Slip</span>
              </button> */}
            </div>
          </div>

          {currentEntries?.length > 0 ? (
            <div>
              <PaginationHeader
                entriesPerPage={entriesPerPage}
                handleEntriesPerPageChange={handleEntriesPerPageChange}
              />
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl overflow-hidden">
                    {/* <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th> */}
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name
                      <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('employee.salary[0].netSalary')} className="cursor-pointer">
                      Net Salary
                      <FontAwesomeIcon icon={getSortIcon('employee.salary[0].netSalary')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('employee.cpf[0].CPFContribution')} className="cursor-pointer">
                      CPF Contribution
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.cpf[0].CPFContribution')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.allowances[0].totalAllowance')} className="cursor-pointer">
                      Total Allowance
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.allowances[0].totalAllowance')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.claims[0].totalClaimAmount')} className="cursor-pointer">
                      Total Claims
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.claims[0].totalClaimAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th
                      onClick={() => handleSort('employee.deductions[0].totalDeductionAmount')}
                      className="cursor-pointer"
                    >
                      Total Deductions
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.deductions[0].totalDeductionAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th className="cursor-pointer px-4 rounded-e-xl w-24">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((employee, index) => {
                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        {/* <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedEmployees.includes(employee)}
                            onChange={() => handleCheckboxChange(employee)}
                          />
                        </td> */}
                        <td className="h-20 text-center flex-shrink-0">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={employee?.employee?.avatar ? employee?.employee?.avatar : DefaultPic}
                              alt="profile pic"
                              className="w-8 h-8 rounded-full object-cover ml-2"
                            />
                          </div>
                        </td>
                        <td title={employee?.employee?.fullName} className="h-20 text-sm text-black text-center">
                          <span>
                            {employee?.employee?.fullName}
                            {/* {employee?.employee?.fullName?.slice(0, 12)}
                            {employee?.employee?.fullName?.length > 12 ? '...' : ''} */}
                          </span>
                        </td>
                        <td className="h-20 text-center text-purple1 font-bold">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(employee?.salary?.[0]?.netSalary)}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.cpf[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.cpf[0]?.CPFContribution)
                            : 'N/A'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.allowances[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.allowances[0]?.totalAllowance)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.claims[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.claims[0]?.totalClaimAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.deductions[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.deductions[0]?.totalDeductionAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 w-24 flex justify-center items-center">
                          <button
                            className="hover:bg-blue-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                            onClick={() => navigate(`/payroll/payslip-view`, { state: { employee, selectedPayslip } })}
                            title="View"
                          >
                            <Eye color="#000000" variant="Bold" size={20} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Generated Employees</div>
            </div>
          )}
        </div>
        {currentEntries.length > 0 && (
          <PaginationFooter
            sortedList={sortedEmployees}
            pageNumbers={pageNumbers}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            indexOfFirstEntry={indexOfFirstEntry}
            indexOfLastEntry={indexOfLastEntry}
          />
        )}
      </div>
    </div>
  );
};

export default PayslipResultList;
