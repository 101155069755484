import { ArrowLeft, Eye } from 'iconsax-react';
import { useSelector } from 'react-redux';
import empty from '../../../assets/images/empty.png';
import SkeletonLoader from '../../../components/skeletonLoader';

const PayslipSelectMonth = ({ setSelectedPayslip, selectedYear, setClickPayslips, loader }) => {
  const payslips = useSelector((state) => state?.payroll?.payslips);

  return !loader ? (
    <div className="bg-white p-6 rounded-2xl">
      <div className="flex flex-wrap justify-between gap-3">
        <div className="text-2xl font-bold text-black">Pay Slips - {selectedYear}</div>
        <div className="text-black">
          <button
            type="button"
            onClick={() => setClickPayslips(false)}
            className="text-black font-bold text-right flex items-center gap-x-3 text-sm"
          >
            <ArrowLeft color="#000000" variant="Outline" size={22} />
            <span>Back</span>
          </button>
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-4">
        {payslips?.map((payslips, index) => {
          return (
            <div className="bg-grayLight shadow-sm flex justify-between items-center h-20 p-5 rounded-2xl" key={index}>
              <div className="text-base font-bold text-black">{payslips?.month}</div>
              <button
                className="hover:bg-gray-300 w-8 h-8 rounded-md flex justify-center items-center shadow-md text-xs"
                onClick={() => setSelectedPayslip(payslips)}
                title="View"
              >
                <Eye color="#000000" variant="Bold" size={20} />
              </button>
            </div>
          );
        })}
      </div>
      {!payslips?.length > 0 && (
        <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
          <img src={empty} alt="empty data" />
          <div className="mt-5 text-purple1 font-bold text-xl">No Payslip Records</div>
        </div>
      )}
    </div>
  ) : (
    <SkeletonLoader length={8} />
  );
};

export default PayslipSelectMonth;
