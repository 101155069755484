import UserLayoutWM from '../../../layout/userLayoutWM';
import UserPayslipInitial from './userPayslipInitial'

const UserPayslip = () => {
  return (
    <UserLayoutWM pageTitle={'Payslip'} page={'Payslip'} subPage={'Payslip'}>
      <div className="h-full">
        <UserPayslipInitial />
      </div>
    </UserLayoutWM>
  );
};

export default UserPayslip;
