import { useFormik } from 'formik';
import { employeeClaimSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateClaim } from '../../../actions/employeeAction';

const EditClaim = ({ claim, claimOptions, onClose, employee }) => {
  const dispatch = useDispatch();

  console.log(claim)

  const formik = useFormik({
    initialValues: {
      _id: claim?._id,
      claimId: claim?.claimId || '',
      claimType: claim?.claimType || '',
      claimCode: claim?.claimCode || '',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
    },
    validationSchema: employeeClaimSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateClaim(values, onClose));
    }
  });

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Claim Type</label>
              <Dropdown
                value={claimOptions.find((option) => option.claimType === formik.values.claimType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      claimType: selectedValue.value,
                      claimCode: selectedValue.claimCode,
                      claimId: selectedValue.claimId
                    });
                  }
                }}
                options={claimOptions}
                isClearable={false}
                placeholder="Select Claim"
                name="claimType"
                id="claimType"
              />
              {formik.touched.claimType && formik.errors.claimType ? (
                <div className="text-red-500 text-sm">{formik.errors.claimType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2"></div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-green1 text-sm font-bold text-white hover:bg-green1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditClaim;
