export const businessType = [
  {
    label: 'Sole Proprietorship/ Sole Trader',
    value: 'Sole Proprietorship/ Sole Trader'
  },
  {
    label: 'Ordinary Business Partnership',
    value: 'Ordinary Business Partnership'
  },
  {
    label: 'Limited Partnership (LP)',
    value: 'Limited Partnership (LP)'
  },
  {
    label: 'Limited Liability Partnership (LLP)',
    value: 'Limited Liability Partnership (LLP)'
  },
  {
    label: 'Private Company Limited By Shares',
    value: 'Private Company Limited By Shares'
  }
];

export const yesOrNoOption = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 }
];
