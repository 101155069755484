import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/solid';

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-full p-10">
      <div className="flex flex-col items-center mb-12">
        <XCircleIcon className="w-20 h-20 text-red-500 mb-4" />
        <p className="text-3xl font-bold mb-2">Access Denied</p>
        <p className="mb-1">You do not have permission to view this page.</p>
        <p>Please check your credentials and try again.</p>
      </div>

      {/* <button
        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
        onClick={() => navigate('/')}
      >
        Back to Home
      </button> */}
    </div>
  );
};

export default AccessDenied;
