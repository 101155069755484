import { useSelector } from 'react-redux';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import UserLayoutWM from '../../../layout/userLayoutWM';

const UserDashboard = () => {
  const user = useSelector((state) => state?.employee?.user);

  const maskValue = (value) => {
    if (value.length <= 5) return value;
    return value.slice(0, -4).replace(/./g, '*') + value.slice(-4);
  };

  return (
    <UserLayoutWM pageTitle={'Dashboard'} page={'Dashboard'} subPage={'Dashboard'}>
      <div className=" bg-white h-full w-full p-6 rounded-2xl flex flex-col gap-y-2 relative">
        <img
          src={user?.avatar ? user.avatar : defaultPic}
          alt="profile_pic"
          className="absolute h-60 w-60 right-14 top-6 object-cover rounded-full shadow-xl border-[10px] border-grayDark hidden xl:flex"
        />
        {user?.salutation && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Salutation:</span>
            <span className="font-normal">{user?.salutation}</span>
          </div>
        )}
        {user?.fullName && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Full Name:</span>
            <span className="font-normal">{user?.fullName}</span>
          </div>
        )}
        {user?.email && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Email:</span>
            <span className="font-normal">{user?.email}</span>
          </div>
        )}
        {user?.idType && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Identification Type:</span>
            <span className="font-normal">{user?.idType}</span>
          </div>
        )}
        {user?.idNum && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Identification Number:</span>
            <span className="font-normal">{maskValue(user?.idNum)}</span>
          </div>
        )}
        {user?.dob && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Date of Birth:</span>
            <span className="font-normal">{new Date(parseInt(user?.dob)).toLocaleDateString('en-GB')}</span>
          </div>
        )}
        {user?.personalEmail && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Personal Email:</span>
            <span className="font-normal">{user?.personalEmail}</span>
          </div>
        )}
        {user?.areaCode && user?.mobile && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Mobile:</span>
            <span className="font-normal">
              {user?.areaCode} {user?.mobile}
            </span>
          </div>
        )}
        {user?.areaCodeContact && user?.contact && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Home Telephone:</span>
            <span className="font-normal">
              {user?.areaCodeContact} {user?.contact}
            </span>
          </div>
        )}
        {user?.religion && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Religion:</span>
            <span className="font-normal">{user?.religion}</span>
          </div>
        )}
        {user?.race && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Race:</span>
            <span className="font-normal">{user?.race}</span>
          </div>
        )}
        {user?.nationality && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Nationality:</span>
            <span className="font-normal">{user?.nationality}</span>
          </div>
        )}
        {user?.gender && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Gender:</span>
            <span className="font-normal">{user?.gender}</span>
          </div>
        )}
        {user?.maritalStatus && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Marital Status:</span>
            <span className="font-normal">{user?.maritalStatus}</span>
          </div>
        )}
        {user?.citizenship && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Citizenship:</span>
            <span className="font-normal">{user?.citizenship}</span>
          </div>
        )}
        {user?.workPassType && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Work Pass Type:</span>
            <span className="font-normal">{user?.workPassType}</span>
          </div>
        )}
        {user?.workPassObtained && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Work Pass Obtained Date:</span>
            <span className="font-normal">{new Date(parseInt(user?.workPassObtained)).toLocaleDateString('en-GB')}</span>
          </div>
        )}
        {user?.workPassExpiration && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Work Pass Expiration Date:</span>
            <span className="font-normal">{new Date(parseInt(user?.workPassExpiration)).toLocaleDateString('en-GB')}</span>
          </div>
        )}
        {user?.prObtained && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">PR Obtain Date:</span>
            <span className="font-normal">{new Date(parseInt(user?.prObtained)).toLocaleDateString('en-GB')}</span>
          </div>
        )}
        {user?.reEntryExpiration && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Re-Entry Pass Expiration:</span>
            <span className="font-normal">{new Date(parseInt(user?.reEntryExpiration)).toLocaleDateString('en-GB')}</span>
          </div>
        )}
        {user?.address && user?.country && user?.postal && (
          <div className="flex flex-wrap text-xl font-semibold text-black">
            <span className="w-[265px]">Address:</span>
            <span className="font-normal">
              {user?.address}, {user?.country} {user?.postal}
            </span>
          </div>
        )}
      </div>
    </UserLayoutWM>
  );
};

export default UserDashboard;
