import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = () => {
    const user = useSelector((state) => state?.employee?.user);

    if (!user) {
        return <Navigate to="/login" />;
    }

    return user?.role == "Admin" ? <Outlet /> : <Navigate to="/access-denied" />;
};

export default AdminRoute;